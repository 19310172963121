import React from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { A_GetFilteredPatientsList } from '../../../actions'
import PropTypes from 'prop-types'
import {
  getFullName,
  formatFullYearDOB,
} from '../../../utilities/ReusableFunctions'
import {
  errorSpan,
  requiredSpan,
  listError,
} from '../../../containers/OnBoarding/FormErrorValidations'

class PatientSearchInput extends React.Component {
  constructor(props) {
    super(props)
    const { selectedPatient } = this.props
    this.patientCard = React.createRef()
    const patient = selectedPatient
    const name = selectedPatient ? getFullName(selectedPatient) : ''
    this.state = {
      selectedPatient: patient,
      patientName: name,
      showSelector: false,
      validPatient: false,
    }

    this.showPatientList = this.showPatientList.bind(this)
    this.setSelectedPatient = this.setSelectedPatient.bind(this)
    this.getLabelEl = this.getLabelEl.bind(this)

    this.timeout = 0
  }

  showPatientList(event) {
    const { validatePatient } = this.props
    const searchValue = event.currentTarget.value
    validatePatient(searchValue)

    if (this.timeout) clearTimeout(this.timeout)

    this.updateSearch(searchValue)
  }

  updateSearch = patient => {
    const { actions, clinicId, onPatientSelected } = this.props

    this.setState(() => ({
      patientName: patient,
      selectedPatient: null,
      validPatient: false,
      showSelector: true,
    }))
    actions.A_GetFilteredPatientsList({
      id: clinicId,
      patient: patient,
    })
    onPatientSelected(null)
  }

  setSelectedPatient(patient) {
    const { onPatientSelected } = this.props
    const patientName = getFullName(patient)
    this.setState(() => ({
      selectedPatient: patient,
      showSelector: false,
      patientName: patientName,
      validPatient: true,
    }))
    onPatientSelected(patient)
  }

  getLabelEl() {
    const { error, label, id } = this.props
    return label.length > 0 ? (
      <label className="form__label" htmlFor={id}>
        {!this.state.validPatient && error && error.length > 0
          ? error === 'Error'
            ? errorSpan()
            : error === 'Required'
            ? requiredSpan()
            : error === 'List'
            ? listError()
            : ''
          : ''}
        {label}
      </label>
    ) : (
      ''
    )
  }

  handlePatientCardClick = e => {
    if (this.state.showSelector)
      if (!this.patientCard.current.contains(e.target)) {
        e.preventDefault()
        this.setState(() => ({
          showSelector: false,
        }))
      }
  }

  onFocusOut(event) {
    let target = event.relatedTarget
    if (target === null) {
      target = document.activeElement
    }
    if (this.patientCard.current.contains(target)) {
      return
    }
    this.setState(() => ({
      showSelector: false,
    }))
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handlePatientCardClick, false)
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)

    document.removeEventListener(
      'mousedown',
      this.handlePatientCardClick,
      false,
    )
  }

  render() {
    const { error, disabled, patients, isFetchingPatient } = this.props
    return (
      <React.Fragment>
        <article className="form__group--halfWidth__panel">
          {this.getLabelEl()}
          <span className="form__fieldIcon icon-magnifying-glass" />
          <input
            className={`${
              !this.state.validPatient && error && error.length > 0
                ? 'form__textField--error'
                : ''
            } form__textField form__textField--left-icon`}
            type="text"
            placeholder="Search"
            id="patient"
            value={this.state.patientName}
            onChange={this.showPatientList}
            onFocus={e => {
              e.target.setAttribute('autocomplete', 'off')
            }}
            disabled={disabled}
          />
          <span
            className={`form__fieldIcon form__fieldIcon--right
            ${
              !this.state.validPatient && error && error.length > 0
                ? 'form__fieldIcon--invalid icon-alert '
                : this.state.selectedPatient && this.state.selectedPatient.id
                ? 'form__fieldIcon--valid icon-tick-circle'
                : ''
            }`}
          />
        </article>
        {disabled ? (
          ''
        ) : (
          <article
            className={`searchResult__selector searchResult__selector--patient ${
              this.state.showSelector ? '' : 'hidden'
            }`}
            ref={this.patientCard}
          >
            <div className="searchResults__scroll">
              <div className="searchResult__header">
                <div className="searchResult__row">
                  <span className="searchResult__header__title searchResult__header__text form__label">
                    NAME
                  </span>
                  <span className="searchResult__header__title searchResult__header__text form__label">
                    DOB
                  </span>
                  <span className="searchResult__header__title searchResult__header__text form__label">
                    POSTCODE
                  </span>
                  <span className="searchResult__header__title searchResult__header__text form__label">
                    PATIENT NO.
                  </span>
                </div>
                <div className="searchResult__header__spacer" />
              </div>
              {patients && patients.length ? (
                <ul className="searchResult__results">
                  {patients.map(patient => {
                    return (
                      <li key={patient.id} className="searchResult__result">
                        <button
                          type="button"
                          className="buttonTransparent searchResult__row searchResult__row--selectable"
                          onClick={() => {
                            this.setSelectedPatient(patient)
                          }}
                          onBlur={e => this.onFocusOut(e)}
                        >
                          <span className="searchResult__header__text">
                            {getFullName(patient)}
                          </span>
                          <span className="searchResult__header__text">
                            {formatFullYearDOB(patient)}
                          </span>
                          <span className="searchResult__header__text">
                            {patient.address_postcode}
                          </span>
                          <span className="searchResult__header__text">
                            {patient.id}
                          </span>
                        </button>
                      </li>
                    )
                  })}
                </ul>
              ) : (
                <article className="searchResult__row noResults_message noResults_message--centered">
                  <span className="multiline_wordbreak">
                    {isFetchingPatient
                      ? 'Loading...'
                      : 'There are no results matching this search value'}
                  </span>
                </article>
              )}
            </div>
          </article>
        )}
      </React.Fragment>
    )
  }
}

PatientSearchInput.defaultProps = {
  id: '',
  label: 'PATIENT',
  error: '',
  selectedPatient: null,
  onPatientSelected: () => {},
  disabled: '',
  validatePatient: () => {},
}

PatientSearchInput.propTypes = {
  clinicId: PropTypes.number.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  selectedPatient: PropTypes.object,
  onPatientSelected: PropTypes.func,
  disabled: PropTypes.string,
  actions: PropTypes.object.isRequired,
  validatePatient: PropTypes.func,
  patients: PropTypes.array.isRequired,
  isFetchingPatient: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  patients: state.clinicAppointment.clinicPatientList,
  isFetchingPatient: state.clinicAppointment.isFetchingPatient,
})

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetFilteredPatientsList,
      },
      dispatch,
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PatientSearchInput),
)
