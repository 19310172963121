import * as api from '../api'
import * as url from '../../api_urls'

export const getTreatmentList = clinicID => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + clinicID + '/treatments/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const postTreatmentDetails = (treatment, clinicID) => {
  return new Promise((resolve, reject) => {
    return api
      .post(url.clinics + clinicID + '/treatments/', treatment)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const PostTreatmentConsent = (ClinicID, TreatmentID, consentData) => {
  return new Promise((resolve, reject) => {
    return api
      .post(
        url.clinics + ClinicID + '/treatments/' + TreatmentID + '/consent/',
        consentData,
      )
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const PostTreatmentProductSheet = (
  ClinicID,
  TreatmentID,
  productsheetData,
) => {
  return new Promise((resolve, reject) => {
    return api
      .post(
        url.clinics +
          ClinicID +
          '/treatments/' +
          TreatmentID +
          '/product_sheet/',
        productsheetData,
      )
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const patchEditTreamentDetails = (
  ClinicID,
  TreatmentID,
  treatmentDetails,
) => {
  return new Promise((resolve, reject) => {
    return api
      .patch(
        url.clinics + ClinicID + '/treatments/' + TreatmentID + '/',
        treatmentDetails,
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const deleteTreamentDetails = (PrimaryClinicID, TreatmentID) => {
  return new Promise((resolve, reject) => {
    return api
      .remove(
        url.clinics + PrimaryClinicID + '/treatments/' + TreatmentID + '/',
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const deleteConsentTreamentDetails = (PrimaryClinicID, TreatmentID) => {
  return new Promise((resolve, reject) => {
    return api
      .remove(
        url.clinics +
          PrimaryClinicID +
          '/treatments/' +
          TreatmentID +
          '/consent/delete/',
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const deleteProductSheetTreamentDetails = (
  PrimaryClinicID,
  TreatmentID,
) => {
  return new Promise((resolve, reject) => {
    return api
      .remove(
        url.clinics +
          PrimaryClinicID +
          '/treatments/' +
          TreatmentID +
          '/product_sheet/delete/',
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const postTreamentOptionDetails = (
  clinicID,
  TreamentID,
  TreatmentDetails,
) => {
  return new Promise((resolve, reject) => {
    return api
      .post(
        url.clinics + clinicID + '/treatments/' + TreamentID + '/options/',
        TreatmentDetails,
      )
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const patchEditTreamentOptionDetails = (
  clinicID,
  TreamentID,
  TreatOptionID,
  TreatmentDetails,
) => {
  return new Promise((resolve, reject) => {
    return api
      .patch(
        url.clinics +
          clinicID +
          '/treatments/' +
          TreamentID +
          '/options/' +
          TreatOptionID +
          '/',
        TreatmentDetails,
      )
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const DeleteTreamentOptionDetails = (
  clinicID,
  TreamentID,
  TreatOptionID,
) => {
  return new Promise((resolve, reject) => {
    return api
      .remove(
        url.clinics +
          clinicID +
          '/treatments/' +
          TreamentID +
          '/options/' +
          TreatOptionID +
          '/',
      )
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const LoginEpharm = (clinicID, credentials) => {
  return new Promise((resolve, reject) => {
    return api
      .post(url.clinics + clinicID + '/epharm/login/', credentials)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getEpharmProdList = (clinicID, searchKey) => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + clinicID + '/epharm/treatments/?search=' + searchKey)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
