import React from 'react'
import PropTypes from 'prop-types'
import {
  errorSpan,
  requiredSpan,
  listError,
} from '../../../containers/OnBoarding/FormErrorValidations'
import CurrencyInputIcon from './CurrencyInputIcon'

const TextInput = ({
  type,
  id,
  label,
  placeholder,
  error,
  noMargin,
  isGreen,
  isRed,
  isGray,
  isStackable,
  isSmall,
  isAuto,
  rightIcon,
  hasAvatar,
  isRequired,
  labelButton,
  showAsterisk,
  onLabelButtonClick,
  errorMessage,
  tooltipClasses,
  handleOnChange,
  handleOnFocus,
  disabled,
  leftIcon,
  currencyIcon,
  ...props
}) => {
  const leftIconEl =
    leftIcon.length > 0 ? (
      <span
        className={`form__fieldIcon ${leftIcon.length > 0 ? leftIcon : ''} ${
          label.length > 0 ? '' : 'form__fieldIcon--no-label'
        }`}
      />
    ) : (
      ''
    )

  const rightIconEl =
    rightIcon.length > 0 ? (
      <span
        className={`form__fieldIcon form__fieldIcon--right ${
          rightIcon.length > 0 ? rightIcon : ''
        } ${label.length > 0 ? '' : 'form__fieldIcon--no-label'}`}
      />
    ) : (
      ''
    )
  const labelEl =
    label.length > 0 ? (
      <label
        className={`form__label ${
          labelButton.length > 0 ? 'form__label--hasButton' : ''
        }`}
        htmlFor={id}
      >
        {error && error.length > 0
          ? error === 'Error' || error === 'card'
            ? errorSpan()
            : error === 'Required'
            ? requiredSpan()
            : error === 'List'
            ? listError()
            : ''
          : ''}
        {label}{' '}
        {!isRequired && !showAsterisk ? (
          <span className="form__label--optional"> - optional</span>
        ) : isRequired && showAsterisk ? (
          <span className="patientImport__red">*</span>
        ) : (
          ''
        )}{' '}
        {labelButton.length > 0 ? (
          <button
            className="buttonTransparent form__label__button"
            type="button"
            onClick={onLabelButtonClick}
          >
            {labelButton}
          </button>
        ) : (
          ''
        )}
      </label>
    ) : (
      ''
    )

  const renderErrorCard = () => {
    return (
      <React.Fragment>
        <section className="tooltipWrapper">
          <aside className={`tooltip tooltip--error ${tooltipClasses}`}>
            <section className="tooltip__tip icon-alert">
              <span className="tooltip__tipText">{errorMessage}</span>
            </section>
            <span className="tooltip__pointerStretch">
              <span className="tooltip__pointer tooltip--error__pointer" />
            </span>
          </aside>
        </section>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {labelEl}
      {currencyIcon ? (
        <CurrencyInputIcon currencyIcon={currencyIcon} />
      ) : (
        leftIconEl
      )}
      <input
        className={`form__textField ${noMargin ? 'noMargin' : ''} ${
          isGreen ? 'form__textField--green' : ''
        }
                ${isGray ? 'form__textField--gray' : ''} ${
          isRed ? 'form__textField--red' : ''
        } ${isStackable ? 'form__textField--stackable' : ''}
                ${isSmall ? 'form__textField--inline-small' : ''}
                ${isAuto ? 'form__textField--auto' : ''}
                ${leftIcon || currencyIcon ? 'form__textField--left-icon' : ''}
                ${rightIcon.length > 0 ? 'form__textField--right-icon' : ''} ${
          hasAvatar ? 'tableCell__avatarText' : ''
        }
                ${error && error.length > 0 ? 'form__textField--error' : ''}`}
        type={type}
        id={id}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        placeholder={placeholder}
        name={props.name} //eslint-disable-line
        {...props}
        disabled={disabled}
        autoComplete={id}
        maxLength={props.maxLength}
      />
      {rightIconEl}
      {error === 'card' ? renderErrorCard() : ''}
    </React.Fragment>
  )
}

TextInput.defaultProps = {
  type: 'text',
  id: '',
  label: '',
  placeholder: '',
  error: '',
  noMargin: false,
  isStackable: false,
  isSmall: false,
  isAuto: false,
  rightIcon: '',
  hasAvatar: false,
  isGreen: false,
  isGray: false,
  isRed: false,
  isRequired: true,
  labelButton: '',
  showAsterisk: false,
  onLabelButtonClick: () => {},
  errorMessage: '',
  tooltipClasses: '',
  handleOnChange: null,
  handleOnFocus: null,
  disabled: null,
  maxLength: null,
  leftIcon: '',
  currencyIcon: '',
}

TextInput.propTypes = {
  type: PropTypes.oneOf(['text', 'number', 'password']),
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  noMargin: PropTypes.bool,
  isStackable: PropTypes.bool,
  isSmall: PropTypes.bool,
  isAuto: PropTypes.bool,
  rightIcon: PropTypes.string,
  hasAvatar: PropTypes.bool,
  isGreen: PropTypes.bool,
  isGray: PropTypes.bool,
  isRed: PropTypes.bool,
  isRequired: PropTypes.bool,
  labelButton: PropTypes.string,
  showAsterisk: PropTypes.bool,
  onLabelButtonClick: PropTypes.func,
  errorMessage: PropTypes.string,
  tooltipClasses: PropTypes.string,
  handleOnChange: PropTypes.func,
  handleOnFocus: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  leftIcon: PropTypes.string,
  currencyIcon: PropTypes.string,
}

export default TextInput
