import types from '../../actions/types'

var initialState = {
  clinicsList: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CLINICLIST.OK:
      return {
        ...state,
        clinicsList: action.data,
      }
    default:
      return state
  }
}
