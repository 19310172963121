import types from '../../actions/types'
import * as utils from '../../utilities/ReusableFunctions'

const initialState = {
  data: {
    patient: {
      first_name: '',
      last_name: '',
    },
  },
  alerts: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_BOOKED_APPOINTMENT_DETAILS.OK: {
      var alerts = []

      const patient = action.data.patient

      if (patient) {
        utils
          .getPatientAlertData(patient, 'allergies')
          .forEach(function(string) {
            alerts.push({ name: string, type: 0 })
          })
        utils
          .getPatientAlertData(patient, 'cosmetic_history')
          .forEach(function(string) {
            alerts.push({ name: string, type: 1 })
          })
      }

      return {
        ...state,
        data: {
          ...action.data,
          notes: action.data.notes.reverse(),
        },
        alerts: alerts,
      }
    }
    case types.FETCH_BOOKED_APPOINTMENT_DETAILS.FAIL:
      return {
        ...state,
      }
    case types.FETCH_BOOKED_APPOINTMENT_DETAILS.CLEAR:
      return {
        ...initialState,
      }
    case types.UPDATE_BOOKED_APPOINTMENT.OK:
      return {
        ...state,
        data: {
          ...action.data,
        },
      }
    case types.POST_NEW_CLINIC_NOTES.OK:
      return {
        ...state,
        data: {
          ...state.data,
          notes: [action.data, ...state.data.notes],
        },
      }
    default:
      return state
  }
}
