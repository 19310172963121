import PropTypes from 'prop-types'
import PlayButton from '../../../assets/play-button-smaller.svg?react'
import PauseButton from '../../../assets/pause-button.svg?react'

const TutorialItem = ({ video, setVideo, toggleVideoStatus }) => (
  <div
    role="menuitem"
    tabIndex={0}
    className="tutorialItem"
    key={video.title}
    onKeyDown={() => {
      setVideo(video.signed_video)
      toggleVideoStatus(video.title)
    }}
    onClick={() => {
      setVideo(video.signed_video)
      toggleVideoStatus(video.title)
    }}
  >
    <button className="tutorialBtn" style={{ position: 'relative' }}>
      {video.isPlaying ? <PauseButton /> : <PlayButton />}
    </button>
    <div className="tutorialItem--content-left">
      <p className="tutorialItem--header">{video.title}</p>
    </div>
    <div className="tutorialItem--content-right">
      <p className="tutorialItem--timestamp">{video.duration}</p>
    </div>
  </div>
)

TutorialItem.propTypes = {
  video: PropTypes.shape({
    title: PropTypes.string,
    signed_video: PropTypes.string,
    duration: PropTypes.string,
    isPlaying: PropTypes.bool,
  }).isRequired,
  setVideo: PropTypes.func.isRequired,
  toggleVideoStatus: PropTypes.func.isRequired,
}

export default TutorialItem
