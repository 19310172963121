import * as api from '../api'
import * as url from '../../api_urls'

export const postQuery = details => {
  return new Promise((resolve, reject) => {
    return api
      .post(url.postQuery, details)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
