import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './store'
// import * as Sentry from '@sentry/react'
import App from './App'
import './styles/main.css'
// import * as auth from './services/OnBoarding/auth'
// import types from './actions/types'
// import { SENTRY_ENABLED } from './api_urls'

// if (SENTRY_ENABLED) {
//   const DEBUG = process.env.REACT_APP_ENV !== 'PROD'
//   Sentry.init({
//     release: process.env.REACT_APP_SENTRY_VERSION,
//     dsn:
//       'https://276746209be4447abe18ad4573541118@o374851.ingest.sentry.io/5212171',
//     integrations: [new Sentry.BrowserTracing()],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: DEBUG ? 1.0 : 0.1,
//     environment: process.env.REACT_APP_ENV,
//     debug: DEBUG,
//     autoSessionTracking: true,
//   })
// }

// if (auth.isLoggedIn()) {
//   let user = auth.getUser()
//   if (user && user !== 'null' && user.token) {
//     Sentry.setUser(user.user)
//     store.dispatch({
//       type: types.LOGIN.OK,
//       token: user.token,
//       user: user.user,
//       local: true,
//     })
//   } else {
//     Sentry.configureScope(scope => scope.setUser(null))
//   }
// }

ReactDOM.createRoot(document.getElementById("root")).render(
    <Provider store={store}>
      <App />
    </Provider>
);
