import * as api from '../api'
import * as url from '../../api_urls'

export const getPatientConsultationsList = consultationData => {
  return new Promise((resolve, reject) => {
    const params = {
      locale: consultationData.locale,
    }

    return api
      .get(
        url.clinics +
          consultationData.clinicID +
          '/patients/' +
          consultationData.patientID +
          '/consultations/',
        { params },
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getPatientConsultationDetail = consultationData => {
  return new Promise((resolve, reject) => {
    return api
      .get(
        url.clinics +
          consultationData.clinicID +
          '/patients/' +
          consultationData.patientID +
          '/consultations/' +
          consultationData.consultationID +
          '/',
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getPatientBillList = patientBillingData => {
  return new Promise((resolve, reject) => {
    var fullUrl
    var params = {}
    var getFunction

    if (patientBillingData.nextUrl) {
      getFunction = api.getAuth
      fullUrl = patientBillingData.nextUrl
    } else {
      getFunction = api.get
      fullUrl =
        url.clinics +
        patientBillingData.clinicID +
        '/patients/' +
        patientBillingData.patientID +
        '/bills/'
      params = {
        paid: patientBillingData.paid,
        unpaid: patientBillingData.unpaid,
        refunded: patientBillingData.refunded,
        locale: patientBillingData.locale,
      }
    }

    return getFunction(fullUrl, { params })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const deletePatientInvoice = patientBillingData => {
  return new Promise((resolve, reject) => {
    return api
      .remove(
        url.clinics +
          patientBillingData.clinicID +
          '/patients/' +
          patientBillingData.patientID +
          '/bills/' +
          patientBillingData.billID +
          '/',
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const createNewInvoicePDF = invoiceDetails => {
  return new Promise((resolve, reject) => {
    return api
      .post(
        url.clinics +
          invoiceDetails.clinicID +
          '/patients/' +
          invoiceDetails.patientID +
          '/bills/',
        invoiceDetails.consultation,
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const createPayment = data =>
  new Promise(resolve =>
    api
      .post(
        `${url.clinics}${data.clinicId}/patients/${data.patientId}/bills/${data.billId}/payments/`,
        data.paymentDetails,
      )
      .then(res => resolve(res))
      .catch(err => resolve(err)),
  )

export const getPayments = data =>
  new Promise((resolve, reject) =>
    api
      .get(
        `${url.clinics}${data.clinicId}/patients/${data.patientId}/bills/${data.billId}/payments/`,
      )
      .then(res => resolve(res.data))
      .catch(err => reject(err.data)),
  )
