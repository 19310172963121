import { combineReducers } from "redux";
import authReducer from "./OnBoarding/authReducer";
import newAccountReducer from "./OnBoarding/newAccountReducer";
import ClinicSetupReducer from "./Clinic_Setup/ClinicSetupReducer";
import CalendarReducer from "./WeeklyCalendar/CalendarReducer";
import EditPractitionerProfileReducer from "./Clinic_Setup/ClinicSummaryPages/EditPractitionerProfileReducer";
import AccountSetup from "./AccountSetup/AccountSetupReducer";
import ClinicAppointmentReducer from "./ClinicAppointments/ClinicAppointmentReducer";
import SystemSupportReducer from "./SystemSupport/SystemSupportReducer";
import ViewAppointmentDetailReducer from "./ClinicAppointments/ViewAppointmentDetailReducer";
import ScheduleReducer from "./ScheduleReducer";
import PatientReducer from "./Patients/PatientReducer";
import NotificationReducer from "./Notifications/Notifications";
import PractitionerProfileReducer from "./PractitionerProfile/PractitionerProfileReducer";
import TreatmentReducer from "./TreatmentsLibrary/TreatmentsReducer";
import InvoiceTimelineReducer from "./PatientFinance/InvoiceTimelineReducer";
import StaffReducer from "./Staff/StaffReducer";
import ErrorHandler from "./Error/GlobalErrorReducer";
import AppointmentChartReducer from "./Analytics/AnalyticsChartReducer";
import InventoryReducer from "./Inventory/Inventory";
import ReportReducer from "./Report/Report";
import ReportAppointmentsReducer from "./Report/ReportAppointments";
import ReportRecallReducer from "./Report/ReportRecall";
import GlobalReducer from "./Global";
import types from "../actions/types";
import AppointmentModalReducer from "../features/AppointmentModal/AppointmentModalReducer";

const appReducer = combineReducers({
  global: GlobalReducer,
  auth: authReducer,
  newAccount: newAccountReducer,
  clinicSetup: ClinicSetupReducer,
  calendar: CalendarReducer,
  practitionerProfile: PractitionerProfileReducer,
  profile: EditPractitionerProfileReducer,
  accountSetup: AccountSetup,
  bookedAppointment: ViewAppointmentDetailReducer,
  clinicAppointment: ClinicAppointmentReducer,
  reportProblem: SystemSupportReducer,
  schedule: ScheduleReducer,
  patients: PatientReducer,
  notifications: NotificationReducer,
  treatments: TreatmentReducer,
  finance: InvoiceTimelineReducer,
  clinicians: StaffReducer,
  errorHandler: ErrorHandler,
  charts: AppointmentChartReducer,
  inventoryList: InventoryReducer,
  reportTable: ReportReducer,
  reportRecallTable: ReportRecallReducer,
  reportAppointmentsTable: ReportAppointmentsReducer,
  appointmentModal: AppointmentModalReducer,
});

//// RESET ALL STATE IF THE USER IS LOGGED OUT
const rootReducer = (state, action) => {
  if (action.type === types.CLEAR_TOKEN) {
    state = undefined;
    localStorage.clear();
    sessionStorage.clear();
  }

  return appReducer(state, action);
};

export default rootReducer;
