import * as api from '../api'
import * as url from '../../api_urls'

export const getReportTable = params => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.report + 'report_builder/', { params })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getReportAppointmentsTable = params => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.report + 'report_appointments_builder/', { params })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getReportRecallTable = params => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.report + 'report_recall_builder/', { params })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getDailyTransactions = data => {
  const params = {
    clinic: data.clinicId,
    clinician: data.clinician,
    taken_by: data.taken_by,
    associated_to: data.associated_to,
    start_date: data.startDate,
    end_date: data.endDate,
    payment_type: data.paymentType,
    card_type: data.cardType,
    invoice_status: data.invoiceStatuses.join(','),
    invoice_type: data.invoiceTypes.join(','),
    locale: data.locale,
  }
  return new Promise((resolve, reject) =>
    api
      .get(`${url.report}daily_transactions/`, { params })
      .then(res => resolve(res.data))
      .catch(err => reject(err.data)),
  )
}

export const getReportBuilderSliderValues = locale => {
  const params = {
    locale: locale,
  }
  return new Promise((resolve, reject) => {
    return api
      .get(`${url.report}report_builder/slider_values/`, { params })
      .then(res => resolve(res.data))
      .catch(err => reject(err.data))
  })
}
