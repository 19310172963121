import * as clinic from '../services/ClinicAppointments/Clinic_Setup/ClinicDashboard_01Service'
import types from './types'

export const A_GetClinicsList = () => dispatch =>
  clinic
    .getClinicList()
    .then(response => {
      dispatch({ type: types.FETCH_CLINICLIST.OK, data: response })
      dispatch({ type: types.SET_CLINICS_LIST, clinicsArray: response })
      dispatch({
        type: types.VIEWING_CLINIC,
        clinic: Number(sessionStorage.getItem('currentClinic')),
      })
      return response
    })
    .catch(error => {
      dispatch({ type: types.FETCH_CLINICLIST.FAIL, error })
      return Promise.reject(error)
    })
