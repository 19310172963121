import * as api from '../api'
import * as url from '../../api_urls'

export const getTreatmentSummaryData = params => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.treatmentSummary, { params })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getAppointmentTreatmentSummaryData = params => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.appointmentTreatmentSummary, { params })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
