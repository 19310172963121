import types from '../../actions/types'

var initialState = {
  searchValue: '',
  isLoading: true,
  patientList: [],
  patientPreviousQuery: null,
  patientNextQuery: null,
  patientInteractionsCount: 0,
  patientInteractions: [],
  patientInteractionsPreviousQuery: null,
  patientInteractionsNextQuery: null,
  patientInteractionSearchValue: '',
  patientCount: 0,
  selectedPatient: null,
  clinicId: null,
  patientDocumentList: [],
  patientFilteredDocumentList: [],
  patientDocumentPreviousQuery: null,
  patientDocumentNextQuery: null,
  selectedDocument: null,
  allMedicalHistorySections: [
    {
      id: 0,
      label: 'previous_medical_history',
      name: 'Previous medical history',
      placeholder: 'No pertinent past or persistent medical conditions',
      color: 'green',
      icon: 'icon-add',
    },
    {
      id: 1,
      label: 'allergies',
      name: 'Allergies and alerts',
      placeholder: 'No pertinent allergies or alerts',
      color: 'red',
      icon: 'icon-exclamation',
    },
    {
      id: 2,
      label: 'cosmetic_history',
      name: 'Cosmetic history',
      placeholder: 'No pertinent cosmetic history',
      color: 'yellow',
      icon: 'icon-cosmetics',
    },
    {
      id: 3,
      label: 'drug_history',
      name: 'Drug history',
      placeholder: 'No pertinent past or current prescriptions',
      color: 'blue',
      icon: 'icon-drug',
    },
    {
      id: 4,
      label: 'social_history',
      name: 'Social history',
      placeholder: 'No pertinent social history',
      color: 'orange',
      icon: 'icon-social',
    },
    {
      id: 5,
      label: 'family_history',
      name: 'Family history',
      placeholder: 'No pertinent family history/skin analysis',
      color: 'purple',
      icon: 'icon-family',
    },
    {
      id: 6,
      label: 'aesthetic_interest_questionnaire',
      name: 'Aesthetic interest',
      placeholder: 'No interests noted',
      color: 'medizen',
      icon: 'icon-questionnaire',
    },
  ],
  medicalHistorySections: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PATIENTS_LIST.FAIL:
      return { ...state }
    case types.GET_PATIENTS_LIST.OK:
      return {
        ...state,
        patientList: action.data.results,
        patientCount: action.data.count,
      }
    case types.PATIENT_SEARCH_VALUE:
      if (action.searchValue === state.searchValue) {
        return {
          ...state,
          isLoading: true,
        }
      }

      return {
        ...state,
        searchValue: action.searchValue,
        patientList: [],
        patientPreviousQuery: null,
        patientNextQuery: null,
        isLoading: true,
      }
    case types.GET_FILTERED_PATIENTS.FAIL:
      return { ...state }
    case types.GET_FILTERED_PATIENTS.OK: {
      if (action.searchValue !== state.searchValue) {
        return state
      }

      const prevPatientQuery = action.data.previous
        ? action.data.previous.substring(
            action.data.previous.indexOf('?'),
            action.data.previous.length,
          )
        : null
      const nextPatientQuery = action.data.next
        ? action.data.next.substring(
            action.data.next.indexOf('?') + 1,
            action.data.next.length,
          )
        : null
      let patients = action.data.results
      if (action.nextQuery) {
        patients = [...state.patientList, ...action.data.results]
      }

      let count = state.patientCount

      if (action.searchValue === '' || action.searchValue === undefined) {
        count = action.data.count
      }

      return {
        ...state,
        patientList: patients,
        patientPreviousQuery: prevPatientQuery,
        patientNextQuery: nextPatientQuery,
        isLoading: false,
        patientCount: count,
      }
    }
    case types.SET_SELECTED_PATIENT:
      return {
        ...state,
        selectedPatient: action.patient,
        clinicId: action.clinicId,
      }
    case types.RESET_SELECTED_PATIENT:
      return {
        ...state,
        selectedPatient: null,
        clinicId: null,
      }
    case types.GET_PATIENT_DETAIL.OK:
      return {
        ...state,
        selectedPatient: action.patient,
        clinicId: action.patient.clinic.id,
      }
    case types.GET_PATIENT_DETAIL.FAIL:
      return { ...state }
    case types.GET_PATIENT_MEDICAL_HISTORY_OPTIONS_LIST.OK: {
      const types = [...new Set(action.options.map(({ type }) => type))]

      return {
        ...state,
        medicalHistorySections: state.allMedicalHistorySections.filter(
          ({ id }) => id in types,
        ),
      }
    }
    case types.UPDATE_PATIENT_DETAIL.OK:
      return { ...state, selectedPatient: action.data }
    case types.UPDATE_PATIENT_DETAIL.FAIL:
      return { ...state }
    case types.GET_PATIENT_TIMELINE_INTERACTIONS.FAIL:
      return { ...state }
    case types.GET_PATIENT_TIMELINE_INTERACTIONS.OK: {
      const prevInteractionQuery = action.data.previous
        ? action.data.previous.substring(
            action.data.previous.indexOf('?'),
            action.data.previous.length,
          )
        : null
      const nextInteractionQuery = action.data.next
        ? action.data.next.substring(
            action.data.next.indexOf('?') + 1,
            action.data.next.length,
          )
        : null
      let interactions = action.data.results
      if (action.nextQuery) {
        interactions = [...state.patientInteractions, ...action.data.results]
      }
      return {
        ...state,
        patientInteractions: interactions,
        patientInteractionsCount: action.data.count,
        patientInteractionsPreviousQuery: prevInteractionQuery,
        patientInteractionsNextQuery: nextInteractionQuery,
      }
    }
    case types.SET_INTERACTION_SEARCH_VALUE:
      return {
        ...state,
        patientInteractionSearchValue: action.searchValue,
      }
    case types.ADD_CORRESPONDENCE.FAIL:
      return { ...state }
    case types.ADD_CORRESPONDENCE.OK:
      return { ...state }
    case types.GET_DOCUMENTS_LIST.FAIL:
      return { ...state }
    case types.GET_DOCUMENTS_LIST.OK: {
      const prevInitDocumentQuery = action.data.previous
        ? action.data.previous.substring(
            action.data.previous.indexOf('?'),
            action.data.previous.length,
          )
        : null
      const nextInitDocumentQuery = action.data.next
        ? action.data.next.substring(
            action.data.next.indexOf('?') + 1,
            action.data.next.length,
          )
        : null
      return {
        ...state,
        patientDocumentList: action.data.results,
        patientFilteredDocumentList: action.data.results,
        patientDocumentsPreviousQuery: prevInitDocumentQuery,
        patientDocumentsNextQuery: nextInitDocumentQuery,
      }
    }
    case types.GET_FILTERED_DOCUMENTS.FAIL:
      return { ...state }
    case types.GET_FILTERED_DOCUMENTS.OK: {
      const prevDocumentQuery = action.data.previous
        ? action.data.previous.substring(
            action.data.previous.indexOf('?'),
            action.data.previous.length,
          )
        : null
      const nextDocumentQuery = action.data.next
        ? action.data.next.substring(
            action.data.next.indexOf('?') + 1,
            action.data.next.length,
          )
        : null
      let documents = action.data.results
      if (action.nextQuery) {
        documents = [
          ...state.patientFilteredDocumentList,
          ...action.data.results,
        ]
      }
      return {
        ...state,
        patientFilteredDocumentList: documents,
        patientDocumentsPreviousQuery: prevDocumentQuery,
        patientDocumentsNextQuery: nextDocumentQuery,
      }
    }
    case types.SET_FILTERED_DOCUMENT_LIST:
      return { ...state, patientDocumentList: action.payload }
    case types.SET_SELECTED_DOCUMENT:
      return { ...state, selectedDocument: action.document }
    default:
      return state
  }
}
