import { Fragment } from 'react'
import Button from '../Buttons'
import PropTypes from 'prop-types'

const Bottombar = ({ bar, clickHandler, isDisabled, cancelHandler }) => (
  <Fragment>
    {bar === 'two' ? (
      <aside className="clinicControls__content">
        <Button
          styleType="tertiary"
          size="small"
          margin="right"
          label="Cancel import"
          clickHandler={cancelHandler}
        />
        <Button
          styleType="secondary"
          size="small"
          margin="right"
          label="Import"
          clickHandler={clickHandler}
        />
      </aside>
    ) : bar === 'empty' ? (
      <aside className="clinicControls__content" />
    ) : bar === 'one' ? (
      <aside className="clinicControls__content">
        <Button
          styleType="secondary"
          size="small"
          margin="right"
          label="Import"
        />
      </aside>
    ) : bar === 'inventory' ? (
      <aside className="clinicControls">
        <Button
          styleType="secondary"
          size="small"
          margin="right"
          label="Create new product"
          clickHandler={clickHandler}
          isDisabled={isDisabled}
        />
      </aside>
    ) : (
      ''
    )}
  </Fragment>
)

Bottombar.defaultProps = {
  clickHandler: () => {},
  cancelHandler: () => {},
  isDisabled: false,
}

Bottombar.propTypes = {
  bar: PropTypes.string.isRequired,
  clickHandler: PropTypes.func,
  cancelHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
}

export default Bottombar
