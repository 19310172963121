import React from 'react'
import PropTypes from 'prop-types'

import Section from './Section'

import SupportImageIcon from '../../../assets/support_attach_image.svg?react'
import SupportVideoIcon from '../../../assets/support_attach_video.svg?react'
import SupportPDFIcon from '../../../assets/support_attach_pdf.svg?react'

import IllustrationHelper from '../IllustrationHelper'
import SupportIllustration from '../../../assets/no_content_found_illustration.png'
import Loader from '../../../components/PatientData/Loader'

export default function SupportPage({
  isFetching,
  sections,
  toggleModal,
  setAttachment,
}) {
  return (
    <div className="settings--view--container" data-testid="support-container">
      {sections.length ? (
        sections.map(section => (
          <React.Fragment key={section.id}>
            <Section
              key={section.id}
              section={section}
              renderAttachment={renderAttachment}
              toggleModal={toggleModal}
              setAttachment={setAttachment}
            />
            <br />
          </React.Fragment>
        ))
      ) : isFetching ? (
        <Loader />
      ) : (
        <IllustrationHelper
          illustration={SupportIllustration}
          header="No content available"
          desc="Unfortunately there is no content currently available for this page. Please come back soon."
        />
      )}
    </div>
  )
}

export const renderAttachment = (attachment, toggleModal, setAttachment) =>
  ({
    [attachment.type]: (
      <div
        key={attachment.id}
        className="support-attachment--container"
        data-testid="attachment"
      >
        <button
          className="support-attachment--box"
          onClick={() => {
            toggleModal(attachment.type !== 'PDF' ? true : false)
            attachment.type === 'PDF'
              ? window.open(attachment.signed_file)
              : setAttachment(attachment)
          }}
        >
          {attachment.type === 'Image' ? (
            <SupportImageIcon className="support-attachment--svg" />
          ) : attachment.type === 'Video' ? (
            <SupportVideoIcon className="support-attachment--svg" />
          ) : attachment.type === 'PDF' ? (
            <SupportPDFIcon className="support-attachment--svg" />
          ) : null}
        </button>
        <div className="support-attachment--details">
          <small className="support-attachment--details-file">
            {attachment.type}
          </small>
          <span className="support-attachment--details-name">
            {attachment.title}
          </span>
        </div>
      </div>
    ),
  }[attachment.type])

SupportPage.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  sections: PropTypes.array.isRequired,
  toggleModal: PropTypes.func.isRequired,
  setAttachment: PropTypes.func.isRequired,
}
