import PropTypes from 'prop-types'
import { Route, useParams } from 'react-router-dom'

import { REDIRECT_TO_V2_APP } from './api_urls'
import { getToken } from './services/OnBoarding/auth'

const FeatureComponent = ({ Component = <></>, enabled, v2url, ...props }) => {
  const params = useParams()

  if (enabled) {
    const token = getToken()

    var url = v2url

    Object.keys(params).forEach(key => {
      url = url.replace(`:${key}`, params[key])
    })

    window.location.replace(
      `${REDIRECT_TO_V2_APP}/-/referral?token=${token}&url=/0/${url}`,
    )
    return
  }

  return <Component {...props} />
}

FeatureComponent.propTypes = {
  Component: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
  v2url: PropTypes.string.isRequired,
}

const FeatureRoute = ({ component: Component, enabled, v2url, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <FeatureComponent
          Component={Component}
          enabled={enabled}
          v2url={v2url}
          {...props}
        />
      )}
    />
  )
}

FeatureRoute.propTypes = {
  component: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
  v2url: PropTypes.string.isRequired,
}

export default FeatureRoute
