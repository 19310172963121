import * as api from '../api'
import * as url from '../../api_urls'

export const getPatientsList = id => {
  let patientUrl = url.clinics + id + '/patients/'
  return new Promise((resolve, reject) => {
    return api
      .get(patientUrl)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getFilteredPatients = data => {
  let patientUrl = url.clinics + data.id + '/patients/'
  if (data.nextQuery) {
    patientUrl = patientUrl + '?' + data.nextQuery
  } else {
    patientUrl = patientUrl + '?find=' + data.patient
  }
  return new Promise((resolve, reject) => {
    return api
      .get(patientUrl)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

// search documents ----------------------

export const getDocumentsList = (clinicId, patientId) => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + clinicId + '/patients/' + patientId + '/documents/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getFilteredDocuments = data => {
  let patientUrl =
    url.clinics + data.id + '/patients/' + data.patient + '/documents/'
  if (data.nextQuery) {
    patientUrl = patientUrl + '?' + data.nextQuery
  } else {
    patientUrl = patientUrl + '?find=' + data.document
  }
  return new Promise((resolve, reject) => {
    return api
      .get(patientUrl)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

//-----------------

export const postSinglePatientData = (id, patientData) => {
  return new Promise((resolve, reject) => {
    return api
      .post(url.clinics + id + '/patients/', patientData)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getPatientDetail = (clinicId, patientId) => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + clinicId + '/patients/' + patientId + '/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const updatePatientDetail = (clinicId, patientId, patientData) => {
  let data = {
    ...patientData,
    medical_history: null,
  }

  if (data.id === patientId) {
    return new Promise((resolve, reject) => {
      return api
        .patch(url.clinics + clinicId + '/patients/' + patientId + '/', data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export const getPatientMedicalHistoryOptionsList = clinicId => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + clinicId + '/patients/medical-history/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getTimelineInteractions = (
  clinicId,
  patientId,
  searchValue,
  filterValue,
  nextQuery,
) => {
  let timelineUrl =
    url.clinics + clinicId + '/patients/' + patientId + '/interactions/'
  if (searchValue && searchValue.trim().length > 0) {
    timelineUrl = timelineUrl + '?find=' + searchValue.trim()
  }
  if (nextQuery) {
    timelineUrl =
      timelineUrl + (searchValue.trim().length > 0 ? '&' : '?') + nextQuery
  } else {
    timelineUrl =
      timelineUrl +
      (searchValue.trim().length > 0 ? '&' : '?') +
      'type=' +
      filterValue
  }

  return new Promise((resolve, reject) => {
    return api
      .get(timelineUrl)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const addPatientDocument = (clinicId, patientId, documentData) => {
  return new Promise((resolve, reject) => {
    return api
      .post2(
        url.clinics + clinicId + '/patients/' + patientId + '/documents/',
        documentData,
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const addCorrespondence = (clinicId, patientId, correspondenceData) => {
  return new Promise((resolve, reject) => {
    return api
      .post(
        url.clinics +
          clinicId +
          '/patients/' +
          patientId +
          '/interactions/correspondences/',
        correspondenceData,
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getConsultationRecord = appointDetails => {
  return new Promise((resolve, reject) => {
    return api
      .get(
        url.clinics +
          appointDetails.clinicId +
          '/appointments/' +
          appointDetails.appointmentId +
          '/consultations/' +
          appointDetails.consultationId +
          '/wizard/',
      )
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getOutstandingActions = (clinicId, patientId) => {
  return new Promise((resolve, reject) => {
    return api
      .get(
        url.clinics +
          clinicId +
          '/patients/' +
          patientId +
          '/follow-ups/outstanding/',
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
