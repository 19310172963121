import moment from 'moment-timezone'
import { getCountryCallingCode } from 'libphonenumber-js'
import dateFns from 'date-fns'
import * as obj from './ReusableObjects'
import { getCurrency } from 'locale-currency'
import getSymbolFromCurrency from 'currency-symbol-map'
import currencyFormatter from 'currency-formatter'
import { getCountry } from 'country-from-iso2'

export const getCurrentMonthandYear = () => {
  let currentMonth = new Date()

  const dateFormat = 'MMM'
  const yearFormat = 'YYYY'

  return {
    month: dateFns.format(currentMonth, dateFormat),
    year: dateFns.format(currentMonth, yearFormat),
  }
}

export const formatAmount = amount => {
  return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const clinicHasHealthExchange = (clinicId, clinicList) => {
  let clinics = clinicList.filter(clinic => {
    return clinic.id === clinicId
  })
  return clinics.length > 0
}

export const getDateandWeek = date => {
  const startOfWeek = date.clone().startOf('isoWeek')
  const endOfWeek = date.clone().endOf('isoWeek')

  let day = startOfWeek

  let days = []

  while (endOfWeek.diff(day, 'seconds') > 0) {
    days.push(day.clone())
    day.add(1, 'day')
  }

  return days
}

export const getCurrentDateandWeek = () => {
  let date = moment(new Date(), 'UTC')
  return {
    currentDate: date,
    array: getDateandWeek(date),
  }
}

export const getDateForMonthStart = (month, year) => {
  const monthIndex = monthList.indexOf(month)
  let date = new Date(year, monthIndex)
  return date
}

export const getPrevMonth = month => {
  const monthIndex = monthList.indexOf(month)
  if (monthIndex === 0) {
    return monthList[monthList.length - 1]
  }
  return monthList[monthIndex - 1]
}

export const getUserObjectFromLS = () => {
  let userObj = JSON.parse(localStorage.getItem('user'))
  return userObj
}

export const getCurrentTimePositionValue = Type => {
  var appointmentDefaultLength = 15
  var hours = new Date().getHours()
  var time = new Date().getMinutes()
  var percentTime = time / 60
  var calcTime = hours + percentTime
  var now = calcTime * 60
  //var now = 9.6 * 60;
  var appointmentHeight = this.getPractitionerAppointmentDivisionLength(
    appointmentDefaultLength,
    Type,
  )
  var currentTimePos = (appointmentHeight / appointmentDefaultLength) * now
  var divisionPos = currentTimePos - 3

  return {
    divisionPos: divisionPos,
    currentTimePos: divisionPos,
  }
}

export const getPreviousWeekStartEndDays = () => {
  var _now = new Date()

  // get sunday by subtraction current week day from month day
  var endDate = new Date(_now.setDate(_now.getDate() - _now.getDay()))
  // get monday by subtraction 6 days from sunday
  var startDate = new Date(_now.setDate(_now.getDate() - 6))
  return { endDate: endDate, startDate: startDate }
}

//////  CHANGE 5 JUNE CALCULATE PRACTITIONER CALENDAR CURRENT TIME BASED ON APPOINTMENT LENGTH AS DIVISION IS NOT ALWAYS 15 MINS ///////
export const getPractitionerCurrentTimePositionValue = appointmentDefaultLength => {
  var hours = new Date().getHours()
  var time = new Date().getMinutes()
  var percentTime = time / 60
  var calcTime = hours + percentTime
  var now = calcTime * 60
  var currentTimePos = (140 / appointmentDefaultLength) * now
  var divisionPos = currentTimePos - 3

  return {
    divisionPos: divisionPos,
    currentTimePos: divisionPos,
  }
}

/////// CHANGE 5 JUNE CALCULATE OUT OF HOURS INDICATOR HEIGHT /////////
export const calculateOutOfHoursHeight = (
  type,
  time,
  appointmentDefaultLength,
  divisionHeight,
) => {
  const times = time.split('.')
  const totalMinutes = Number(times[0]) * 60 + Number(times[1])
  const height = type === 'open' ? totalMinutes : 1440 - totalMinutes

  const numDivisions = height / appointmentDefaultLength
  return numDivisions * divisionHeight
}

/**
 * Set the classes as per the column values of the practitioner
 */
export const setOddEvenClass = num => {
  if (num) {
    if (num % 2 === 0) {
      return 'even'
    } else {
      return 'odd'
    }
  }
  return 'even'
}

/**
 * initialize a single digit with a 0
 */
export const initializeSingleDigit = number => {
  return (number < 10 ? '0' : '') + number
}

export const getPractitionerAppointmentDivisionLength = mins => {
  var divisionHeight = 105
  return mins * (divisionHeight / 15)
}

export const getAppointmentStatus = code => {
  let appointmentType = { type: '', style: '' }
  switch (code) {
    case -2:
      appointmentType = { type: 'Cancelled', style: 'error' }
      break
    case -1:
      appointmentType = { type: 'No show', style: 'error' }
      break
    case 0:
      appointmentType = { type: 'Pending', style: 'pending' }
      break
    case 1:
      appointmentType = { type: 'Arrived', style: 'pending' }
      break
    case 2:
      appointmentType = { type: 'Ready', style: 'pending' }
      break
    case 3:
      appointmentType = { type: 'Active', style: 'active' }
      break
    case 4:
      appointmentType = { type: 'Complete', style: 'complete' }
      break
    default:
      break
  }
  return appointmentType
}

export const clinicianName = data => {
  if (data.clinician.needs_setup) {
    return data.email
  }
  return getFullName(data.clinician)
}

export const user_type = data => {
  let userRole = data.clinician.user_type
  let userRoleName = obj.userTypeNames
  return userRoleName[userRole]
}

export const job_type = data => {
  let jobRole = data.clinician.job_type
  let jobRoleName = obj.jobTypeNames
  return jobRoleName[jobRole]
}

export const is_admin = data => {
  let isAdmin = 'No'
  if (data.invite && data.invite.is_admin) {
    isAdmin = 'Yes'
  } else if (data.is_admin) {
    isAdmin = 'Yes'
  }
  return isAdmin
}

export const getClinicWebsite = data => {
  let companyWebsite = ''
  if (data) {
    data.map(function(clinician) {
      if (clinician.account) {
        companyWebsite = clinician.account.company_website
      }
      return companyWebsite
    })
  }
  return companyWebsite
}

export const getClinicName = data => {
  let companyName = ''
  if (data) {
    data.map(function(clinician) {
      if (clinician.account) {
        companyName = clinician.account.company_name
      }
      return companyName
    })
  }
  return companyName
}

export const getNewFormattedDate = date => {
  if (typeof date === 'object') {
    date = dateFns.format(date, 'YYYY-MM-DD')
  } else {
    date = date
      .split('/')
      .reverse()
      .join('-')
  }

  return date
}

export const getNextMonth = month => {
  const monthIndex = monthList.indexOf(month)
  if (monthIndex === monthList.length - 1) {
    return monthList[0]
  }
  return monthList[monthIndex + 1]
}

export const getFormattedDate = (date, type) => {
  if (type === 'time') {
    return moment.tz(date, 'utc').format('HH:mm')
  }
  return moment.tz(date, 'utc').format('DD.MM.YYYY')
}

export const getDateTimezoneFormat = (date, timezone) => {
  return moment(date)
    .tz(timezone)
    .format('HH:mm')
}

export const formatDateTimeObject = (date, time, timezone) => {
  const formattedDate = moment(date).format('YYYY-MM-DD')
  return moment(formattedDate + ' ' + time)
    .tz(timezone)
    .format('YYYY-MM-DDTHH:mm:ssZ')
}

export const mapOpeningTimesDays = week => {
  week = formatClinic_openingHoursList(week)

  return week.map(dayItem => {
    const id = getItems('daysFull').filter(dayId => dayId.id === dayItem.day)[0]

    let object = Object.assign({ dayName: id.title }, dayItem)

    if (object.is_open) {
      object.formattedOpens = object.opens.substr(0, 5)
      object.formattedCloses = object.closes.substr(0, 5)
    } else {
      object.formattedOpens = 'Closed'
      object.formattedCloses = 'Closed'
    }

    return object
  })
}

export const sortedMedicalHistory = history => {
  return history.sort((a, b) => {
    if (a.type < b.type) {
      return 1
    } else if (a.type > b.type) {
      return -1
    }
    if (a.custom < b.custom) {
      return 1
    } else if (a.custom > b.custom) {
      return -1
    }
    let descA = a.description ? a.description.toLowerCase() : ''
    let descB = b.description ? b.description.toLowerCase() : ''
    if (descA < descB) {
      return 1
    } else if (descA > descB) {
      return -1
    }
    return 0
  })
}

export const medicalHistory = (history, type) => {
  const sortedHistory = sortedMedicalHistory(history)

  return sortedHistory.filter(function(option) {
    return option.type === type
  })
}

export const getItemTitles = (ids, objects) =>
  Object.keys(ids).map(id => {
    return { id: ids[id], title: objects[id] }
  })

export const getItems = name => {
  switch (name) {
    case 'reasons':
      return getItemTitles(obj.scheduleOptions, obj.scheduleReasons)
    case 'days':
      return getItemTitles(obj.scheduleDaysShort, obj.scheduleDaysShort)
    case 'daysFull':
      return getItemTitles(obj.scheduleDayIds, obj.scheduleDays)
    case 'recurrences':
      return getItemTitles(obj.scheduleRecurrenceIds, obj.scheduleRecurrences)
    case 'gender':
      return getItemTitles(obj.genderIds, obj.genderTitles)
    case 'genderFilter':
      return getItemTitles(obj.genderFilterIds, obj.genderFilterTitles)
    case 'marital_status':
      return getItemTitles(obj.maritalStatusIds, obj.maritalStatusTitles)
    case 'title':
      return getItemTitles(obj.preferredTitleIds, obj.preferredTitleTitles)
    // case "preferred_contact":
    //   return getItemTitles(obj.contactMethodIds, obj.contactMethodTitles);
    case 'how_did_you_hear_about_us':
      return getItemTitles(
        obj.howDidYouHearAboutUsIds,
        obj.howDidYouHearAboutUsTitles,
      )
    case 'preferred_contact':
      return getItemTitles(
        obj.contactMethodGroupIds,
        obj.contactMethodGroupTitles,
      )
    case 'preferred_contact_marketing':
      return getItemTitles(
        obj.contactMethodGroupIds,
        obj.contactMethodGroupTitles,
      )
    case 'permission_to_contact_groups':
      return getItemTitles(
        obj.contactMethodGroupIds,
        obj.contactMethodGroupValues,
      )
    case 'purpose':
      return getItemTitles(
        obj.appointmentPurposeIds,
        obj.appointmentPurposeTitles,
      )
    case 'appointmentReasons':
      return getItemTitles(
        obj.appointmentReasonIds,
        obj.appointmentReasonTitles,
      )
    case 'appointmentStatus':
      return getItemTitles(
        obj.appointmentStatusIds,
        obj.appointmentStatusTitles,
      )
    case 'status':
      return getItemTitles(
        obj.consultationStatusIds,
        obj.consultationStatusTitles,
      )
    case 'gp_privately_insured':
      return [
        { id: 'No', title: 'No' },
        { id: 'Yes', title: 'Yes' },
      ]
    case 'permission_to_contact_given':
      return [
        { id: 'No', title: 'No' },
        { id: 'Yes', title: 'Yes' },
      ]
    case 'can_contact_for_marketing':
      return [
        { id: 'No', title: 'No' },
        { id: 'Yes', title: 'Yes' },
      ]
    case 'job_type':
      return getItemTitles(obj.jobTypeIds, obj.jobTypes)
    case 'user_type':
      return getItemTitles(obj.userTypeIds, obj.userTypes)
    default:
      return []
  }
}

export const getCheckboxItems = (property, data) => {
  let allCheckboxItems = getItems(property)
  let checkboxes = allCheckboxItems.map(item => {
    let checked = data[property] && data[property].indexOf(item.id) > -1
    if (!checked) {
      checked = false
    }
    let itemData = {
      id: item.id.toString(),
      label: item.title,
      name: item.title,
      checked: checked,
    }
    return itemData
  })
  return checkboxes
}

export const getCheckboxTitles = (property, data) => {
  let allCheckboxItems = getItems(property)
  let checkboxes = []
  if (data[property]) {
    checkboxes = allCheckboxItems.filter(item => {
      return data[property].indexOf(item.id) > -1
    })
  }
  return checkboxes
}

export const getAllItems = (property, data) => {
  return getItems(data)
}

export const getItemData = (property, data) => {
  let itemData = getItems(property)
  return itemData.filter(item => item.id === data[property])[0]
}

export const getItemValue = (data, property) => {
  const itemData = getItemData(property, data)
  if (itemData) {
    return itemData.title
  }
  if (Array.isArray(data[property])) {
    const items = getItems(property)
      .filter(item => data[property].indexOf(item.id) > -1)
      .map(value => value.title)
    return createStringFromValues(items)
  }
  return ''
}

export const getFullName = data => {
  if (!data || data === undefined) {
    return ''
  }
  let first = data.first_name ? data.first_name : ' '
  let last = data.last_name ? data.last_name : ' '
  return `${first} ${last}`.trim()
}

export const getPatientAlertData = (data, type) => {
  let medical = []
  if (data && data.medical_history) {
    data.medical_history.forEach(key => {
      let string = key.title

      if (key.details) {
        string = `${string} - ${key.details}`
      }

      if (type === 'allergies') {
        if (key.type === 1) {
          medical.push(string)
        }
      } else if (type === 'cosmetic_history') {
        if (key.type === 2) {
          medical.push(string)
        }
      }
    })
  }
  return medical
}

export const getInitials = data => {
  let first = data && data.first_name ? data.first_name.slice(0, 1) : ''
  let last = data && data.last_name ? data.last_name.slice(0, 1) : ''
  return first + last
}

export const displayUserName = data => {
  data = data.split(' ')
  let newData = []
  data.forEach(ele => {
    let value_0 = ele.charAt(0).toUpperCase()
    let value_1 = ele.slice(1).toLowerCase()
    newData.push(value_0 + value_1)
  })
  newData = newData.join(' ')
  return newData
}

export const formatLabels = data => {
  var regex = /^[^_\W]+|[^a-zA-Z0-9\s]$/
  data = regex.test(data) ? data.split(/[ @_]+/) : data
  let newLabel = []
  data.forEach(ele => {
    let value_0 = ele.charAt(0).toUpperCase()
    let value_1 = ele.slice(1).toLowerCase()
    newLabel.push(value_0 + value_1)
  })

  newLabel = newLabel.join(' ')
  return newLabel
}

export const getNestedFullName = (data, value) => {
  if (data[value]) {
    return getFullName(data[value])
  }
  return ''
}

export const formatDate = (data, format, age) => {
  if (!data.date_of_birth || data.date_of_birth.length === 0) {
    return ''
  }

  const dobObj = data.date_of_birth + ' 00:00:00'
  return `${moment(dobObj)
    .tz('Europe/London')
    .format(format)} ${age ? `(${data.age})` : ''}`
}

export const formatDOB = data => {
  return formatDate(data, 'DD.MM.YY', false)
}

export const formatFullYearDOB = data => {
  return formatDate(data, 'DD.MM.YYYY', true)
}

export const createStringFromValues = values => {
  return values.join(', ')
}

export const convertBoolean = (data, property) => {
  if (data[property] === true) {
    return 'Yes'
  }
  return 'No'
}

export const highlightSearchResult = (text, search) => {
  if (text && text.length > 0 && search.length > 0) {
    const items = text.toString().split(new RegExp(`(${search})`, 'gi'))
    return items.map((item, i) => {
      let indexVal = i
      return item.toLowerCase() === search.toLowerCase() ? (
        <span key={indexVal} className="searchHighlight">
          {item}
        </span>
      ) : (
        item
      )
    })
  }
  return text
}

export const getAddressObject = (keyData, valueData, fieldName) => {
  const addressKeys = [
    'line_one',
    'line_two',
    'town',
    'county',
    'postcode',
    'country',
  ]
  let itemObj = {}
  keyData.value.forEach((item, index) => {
    const key = addressKeys[index]
    let value = valueData[item]

    // Default value for country
    if (key === 'country' && value === '') {
      value = 'United Kingdom'
    }

    itemObj[fieldName + '_' + key] = value
  })

  return itemObj
}

export const monthList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const checkAppointmentInPast = appointmentData => {
  const appDate = new Date(appointmentData.start_date)
  if (dateFns.isPast(appDate)) {
    return true
  } else if (dateFns.isToday(appDate)) {
    if (appointmentData.status < 0 || appointmentData.status > 3) {
      return true
    }
  }
  return false
}

export const created_at = date => {
  if (date.created_at) {
    date = date.created_at
      .substring(2, 10)
      .split('-')
      .reverse()
      .join('.')
    return date
  } else {
    return ''
  }
}

export const file = data => {
  if (data.file) {
    return getFileName(data.file)
  } else {
    return ''
  }
}

export const created_by = data => {
  if (data.created_by) {
    const name = data.created_by.first_name + ' ' + data.created_by.last_name
    return name
  } else {
    return ''
  }
}

export const getCorrespondenceType = data => {
  if (data.file) {
    let path = require('path')
    const ext = path.extname(data.file)
    let type = 'document'
    switch (ext) {
      case '.jpg':
      case '.jpeg':
      case '.png':
        type = 'image'
        break
      default:
        type = 'document'
        break
    }
    return type
  } else {
    return ''
  }
}

export const getUniqueIdValues = arr => {
  let unique = []
  arr.forEach(ele => {
    let uArr = unique.filter(u => {
      return u.id === ele.id
    })
    if (uArr.length === 0) {
      unique.push(ele)
    }
  })
  return unique
}

export const bytesToSize = bytes => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (parseInt(bytes) === 0) return '0 Byte'
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
}

export const file_size = data => {
  if (data.file_size) {
    return bytesToSize(data.file_size)
  } else {
    return ''
  }
}

export const address = clinicInfo =>
  [
    'address_line_one',
    'address_line_two',
    'address_town',
    'address_county',
    'address_postcode',
    'address_country',
  ]
    .filter(key => clinicInfo[key])
    .map(key => clinicInfo[key])
    .join(', ')

export const portableEquipment = clinicInfo => {
  let portableEquipment = []
  clinicInfo.rooms.forEach(room => {
    room.equipment.forEach(equip => {
      if (equip.is_permanent === false) {
        portableEquipment.push(equip.is_permanent)
      }
    })
  })

  return portableEquipment
}

export const formatClinic_openingHoursList = list => {
  if (list && list.length > 0) {
    let formattedDay
    let clinicOpeningHours = []
    list.forEach(ele => {
      if (parseInt(ele.day) === 1) {
        formattedDay = ele
      } else {
        clinicOpeningHours.push(ele)
      }
    })

    if (formattedDay) {
      clinicOpeningHours.push(formattedDay)
    }

    return clinicOpeningHours
  }

  return obj.defaultClinicHours
}

/**
 *  This function is to get the file size from an event, as the other method works only for the response that provides property file_size
 */
export const getFileSizefromEvent = event => {
  if (event.target && event.target.files.length > 0) {
    event.target.files[0].file_size = event.target.files[0].size

    let size = file_size(event.target.files[0])

    return size
  }
}

/**
 * This method is used to return the file name where in you have file name like this ->  "consents/e6c23c9b-bc02-4ebf-97e3-f16334fa193d/mohammed_rafeeq_ansari_generated_resume.pdf"

 */
export function getFileName(filename) {
  if (filename) {
    if (filename.startsWith('http')) {
      const segments = new URL(filename).pathname.split('/')
      const last = segments.pop() || segments.pop()

      return last
    }

    const actual_name = filename.split('/')
    const lastPosition = actual_name.length - 1
    return actual_name[lastPosition]
  }
}

/**
 *  --- sorts the list based on the job Type
 *
 */

export const SortListWithJobTypes = List => {
  List.sort((a, b) => {
    if (a.clinician.job_type > b.clinician.job_type) {
      return 1
    } else if (a.clinician.job_type < b.clinician.job_type) {
      return -1
    } else {
      return 0
    }
  })

  return List
}

export function filteredClinicians(clinicians, currentUserID) {
  if (!clinicians) {
    clinicians = []
  }

  return sortRows(
    clinicians.filter(function(clinician) {
      // 1 == ACCEPTED
      // 1 == Clinician

      if (clinician.clinician === null) {
        return false
      }

      const needs_setup = clinician.clinician.needs_setup === false
      const status = clinician.clinician.invite.status === 1
      const user_type = clinician.clinician.user_type === 1

      return needs_setup && status && user_type
    }),
    currentUserID,
  )
}

export function sortClinicians(clinicians) {
  return clinicians
    .filter(clinician => {
      return clinician.invite !== null
    })
    .sort((a, b) => {
      if (a.invite.account_owner !== b.invite.account_owner) {
        if (a.invite.account_owner < b.invite.account_owner) {
          return 1
        } else if (a.invite.account_owner > b.invite.account_owner) {
          return -1
        }
      }

      if (a.invite.is_admin !== b.invite.is_admin) {
        if (a.invite.is_admin < b.invite.is_admin) {
          return 1
        } else if (a.invite.is_admin > b.invite.is_admin) {
          return -1
        }
      }

      if (a.invite.status !== b.invite.status) {
        if (a.invite.status < b.invite.status) {
          return 1
        } else if (a.invite.status > b.invite.status) {
          return -1
        }
      }

      const fullNameA = getFullName(a).toLowerCase()
      const fullNameB = getFullName(b).toLowerCase()

      if (fullNameA > fullNameB) {
        return 1
      }
      if (fullNameA < fullNameB) {
        return -1
      }

      return 0
    })
    .map(function(staff) {
      return {
        ...staff.invite,
        ...staff,
      }
    })
}

export function sortRows(data, currentUserID) {
  if (!currentUserID) {
    currentUserID = getUserObjectFromLS().user.id
  }

  let listData = data

  if (data.invites) {
    listData = data.invites
  }

  return listData
    .filter(staff => {
      return staff.clinician !== null && staff.clinician.invite !== null
    })
    .sort((a, b) => {
      if (a.clinician.id === currentUserID) {
        return -1
      } else if (b.clinician.id === currentUserID) {
        return 1
      }

      if (a.order !== b.order) {
        if (a.order > b.order) {
          return 1
        } else if (a.order < b.order) {
          return -1
        }
      }

      if (
        a.clinician.invite.account_owner !== b.clinician.invite.account_owner
      ) {
        if (
          a.clinician.invite.account_owner < b.clinician.invite.account_owner
        ) {
          return 1
        } else if (
          a.clinician.invite.account_owner > b.clinician.invite.account_owner
        ) {
          return -1
        }
      }

      if (a.clinician.invite.is_admin !== b.clinician.invite.is_admin) {
        if (a.clinician.invite.is_admin < b.clinician.invite.is_admin) {
          return 1
        } else if (a.clinician.invite.is_admin > b.clinician.invite.is_admin) {
          return -1
        }
      }

      if (a.clinician.invite.status !== b.clinician.invite.status) {
        if (a.clinician.invite.status < b.clinician.invite.status) {
          return 1
        } else if (a.clinician.invite.status > b.clinician.invite.status) {
          return -1
        }
      }

      if (a.clinician.invite.needs_setup !== b.clinician.invite.needs_setup) {
        if (a.clinician.needs_setup > b.clinician.needs_setup) {
          return -1
        } else if (a.clinician.needs_setup < b.clinician.needs_setup) {
          return 1
        }
      }

      if (a.clinician.job_type !== b.clinician.job_type) {
        if (a.clinician.job_type < b.clinician.job_type) {
          return -1
        } else if (a.clinician.job_type > b.clinician.job_type) {
          return 1
        }
      }

      if (a.clinician.role_type !== b.clinician.role_type) {
        if (a.clinician.role_type < b.clinician.role_type) {
          return -1
        } else if (a.clinician.role_type > b.clinician.role_type) {
          return 1
        }
      }

      const fullNameA = getFullName(a.clinician).toLowerCase()
      const fullNameB = getFullName(b.clinician).toLowerCase()

      if (fullNameA > fullNameB) {
        return 1
      }
      if (fullNameA < fullNameB) {
        return -1
      }

      return 0
    })
    .map(function(staff) {
      return {
        ...staff.clinician.invite,
        ...staff,
      }
    })
}

export const cliniciansFilterList = clinicians => {
  const list = [{ id: '', title: 'Show all', isDisabled: false }].concat(
    sortRows(
      clinicians.filter(function(clinician) {
        // 1 == ACCEPTED

        if (clinician.clinician === null) {
          return false
        }

        const needs_setup = clinician.clinician.needs_setup === false
        const status = clinician.clinician.invite.status === 1

        return needs_setup && status
      }),
    ).map(({ clinician }) => {
      return {
        id: clinician.id,
        title: getFullName(clinician).trim(),
        isDisabled: false,
      }
    }),
  )

  return list
}

export const isUrlValid = userInput => {
  let website = userInput
  let r = /^(ftp|ftps|http|https):\/\/[^ "]+$/
  let check = r.test(website)
  if (!check && website !== null && website.length !== 0) {
    website = 'https://' + website
  }

  return website
}

export const ignoreGlobalError = error => {
  return error.status && error.status === 400
}

export const getAppointmentFollowUpText = (
  purpose,
  appointmentDuration,
  timeFromToday,
  timePeriod,
  actionStartDate,
) => {
  const purposes = getItems('purpose').filter(item => {
    return item.id === purpose
  })
  const purposeTitle =
    purposes.length > 0 ? purposes[0].title.toLowerCase() : ''
  const timePeriods = getItems('recurrences').filter(item => {
    return item.id === timePeriod
  })
  const timePeriodTitle =
    timePeriods.length > 0
      ? timePeriods[0].title.slice(0, -3).toLowerCase()
      : ''
  return `A ${purposeTitle} appointment for ${appointmentDuration} minutes in ${timeFromToday} ${timePeriodTitle}${
    timeFromToday === 1 ? '' : 's'
  } from ${actionStartDate}`
}

export const getConsultationTreatmentNames = consultation => {
  let treatmentName = []
  // Loop through examination concerns
  consultation.examination_concerns.forEach(ele => {
    let examination_concern = ele
    // Only for concerns that were complete
    if (parseInt(examination_concern.status) === 0) {
      // Loop through levels
      examination_concern.treatments.forEach(concern => {
        let treatment = concern
        treatment.treatment_options.forEach(item => {
          let option = item
          if (option) {
            let title = option.title
            if (parseInt(treatmentName.indexOf(title)) === -1) {
              treatmentName.push(title)
            }
          }
        })
      })
    }
  })
  return treatmentName.join(', ')
}

export const getFollowUpDate = action => {
  let appDate = action.consultation.appointment.start_date
    ? dateFns.parse(action.consultation.appointment.start_date.slice(0, -1))
    : new Date()
  let arrangeDate = ''
  switch (action.arrange_interval) {
    case 0:
      arrangeDate =
        dateFns.format(
          dateFns.addDays(appDate, action.arrange),
          'YYYY-MM-DDTHH:mm:ss',
        ) + 'Z'
      break
    case 1:
      arrangeDate =
        dateFns.format(
          dateFns.addWeeks(appDate, action.arrange),
          'YYYY-MM-DDTHH:mm:ss',
        ) + 'Z'
      break
    case 2:
      arrangeDate =
        dateFns.format(
          dateFns.addMonths(appDate, action.arrange),
          'YYYY-MM-DDTHH:mm:ss',
        ) + 'Z'
      break
    case 3:
      arrangeDate =
        dateFns.format(
          dateFns.addHours(appDate, action.arrange),
          'YYYY-MM-DDTHH:mm:ss',
        ) + 'Z'
      break
    case 4:
      arrangeDate =
        dateFns.format(
          dateFns.addMinutes(appDate, action.arrange),
          'YYYY-MM-DDTHH:mm:ss',
        ) + 'Z'
      break
    default:
      arrangeDate = dateFns.format(appDate, 'YYYY-MM-DDTHH:mm:ss') + 'Z'
      break
  }
  return arrangeDate
}

export const billLineDiscReport = data => {
  return formatCurrency(
    data.billLine ? data.billLine.discount_value : 0,
    data.locale,
  )
}

export const billLineVATReport = data => {
  return formatCurrency(
    data.billLine ? data.billLine.vat_value : 0,
    data.locale,
  )
}

export const billLineSubTotalReport = data => {
  return formatCurrency(
    data.billLine ? data.billLine.subtotal_value : 0,
    data.locale,
  )
}

export const billLineTotalReport = data => {
  return formatCurrency(
    data.billLine ? data.billLine.price_value : 0,
    data.locale,
  )
}

// export const billLine
// export const finance
export const formatCredit = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/*
  tooltip card validators used for visually validating
  users input. they all return a pre-defined list of objects
  as these rules should be the same across the whole app.
  pass the field you wish to validate into the funcs below then
  pass it as a prop to the below component:
  e.g. <TooltipCardValidator validators={passwordValidators(field)} />
  component.
*/

export const passwordValidators = field => [
  {
    id: 1,
    check: field.length >= 6,
    message: 'Minimum 6 characters',
  },
  {
    id: 2,
    check: field.search(/[A-Z]/) >= 0,
    message: 'Uppercase',
  },
  {
    id: 3,
    check: field.search(/\d+/) >= 0,
    message: 'Numeral',
  },
  {
    id: 4,
    check: field.search(/[!@#$%^/&*;+=:"'-.<>_?,|(){}[]/) >= 0,
    message: 'Foreign',
  },
]

export const callerIdValidators = field => [
  {
    id: 2,
    check: /^[a-z0-9]+$/i.test(field),
    message: `Must contain only alphanumeric characters`,
  },
  {
    id: 3,
    check: /[a-zA-Z]/.test(field),
    message: `Must contain at least one alphabetic letter`,
  },
]

export const hasNumber = /\d/

export const checkAttachmentType = file => {
  const imageTypes = ['.png', '.jpg', 'jpeg']
  const videoTypes = [
    '.mp4',
    '.mov',
    '.avi',
    '.wmv',
    '.mkv',
    '.flv',
    '.mpeg',
    '.mpg',
  ]
  const docTypes = ['.doc', '.pdf']

  const matchFileType = (file, types) =>
    types.find(extension => file.toLowerCase().endsWith(extension))

  const isImage = matchFileType(file, imageTypes)

  const isVideo = matchFileType(file, videoTypes)

  const isDoc = matchFileType(file, docTypes)

  return isImage ? 'Image' : isVideo ? 'Video' : isDoc ? 'PDF' : ''
}

export const formatFinanceData = data =>
  data.map(
    ({
      bill,
      amount,
      date_of_payment,
      clinic: { name, locale },
      card_type,
      payment_type,
      notes,
      taken_by,
      associated_to,
    }) => {
      const currencyCode = getCurrency(locale)
      return {
        id: bill ? bill.id : '-',
        patient: bill && bill.patient ? bill.patient.full_name : '-',
        purpose:
          bill && bill.consultation
            ? getItems('purpose')[bill.consultation.purpose].title
            : '-',
        clinician:
          bill && bill.consultation
            ? bill.consultation.appointment.clinician.full_name
            : '-',
        taken_by: taken_by ? taken_by.full_name : '-',
        associated_to: associated_to ? associated_to.full_name : '-',
        taken: date_of_payment,
        clinic: name,
        status: bill ? obj.invoiceStatuses[bill.payment_status] : '-',
        category:
          bill && bill.items
            ? [
                ...new Set(
                  bill.items
                    .map(({ treatment_option }) => {
                      if (treatment_option) {
                        return treatment_option.treatment?.title
                      }
                      return undefined
                    })
                    .filter(t => t),
                ),
              ].join(', ')
            : '-',
        treatment:
          bill && bill.items
            ? [
                ...new Set(
                  bill.items
                    .filter(
                      ({ consultation_level, treatment_option }) =>
                        consultation_level !== null &&
                        treatment_option !== null,
                    )
                    .map(({ treatment_option: { title } }) => title),
                ),
              ].join(', ')
            : '-',
        inventory:
          bill && bill.items
            ? [
                ...new Set(
                  bill.items
                    .filter(
                      ({ consultation_level, treatment_option }) =>
                        consultation_level === null &&
                        treatment_option !== null,
                    )
                    .map(({ treatment_option: { title } }) => title),
                ),
              ].join(', ')
            : '-',
        totalTreatments: bill
          ? currencyFormatter.format(bill.total_cost_for_treatments, {
              code: currencyCode,
            })
          : '-',
        totalInventory: bill
          ? currencyFormatter.format(
              bill.total - bill.total_cost_for_treatments,
              {
                code: currencyCode,
              },
            )
          : '-',
        totalVAT: bill
          ? currencyFormatter.format(bill.vat_total, { code: currencyCode })
          : '-',
        total: bill
          ? currencyFormatter.format(bill.total, { code: currencyCode })
          : '-',
        paid: currencyFormatter.format(amount, { code: currencyCode }),
        payment: obj.paymentTypes.find(({ id }) => id === payment_type).title,
        card:
          card_type !== null
            ? obj.cardTypes.find(({ id }) => id === card_type).title
            : '-',
        remaining: bill
          ? currencyFormatter.format(bill.total - bill.paid, {
              code: currencyCode,
            })
          : '-',
        note: notes || '-',
      }
    },
  )

export const getCurrencySymbol = locale => {
  const currencyCode = getCurrency(locale)
  return getSymbolFromCurrency(currencyCode)
}

export const countryForCountryCode = code => {
  const country = getCountry(code)
  if (country) {
    return country.name.common
  }
  return code
}

export const countryForLocale = locale => {
  return countryForCountryCode(locale.split('_')[1])
}

export const phoneExtentionChoices = () => {
  try {
    return obj.supportedCountryCodes.map((code, index) => {
      return {
        id: index,
        title: `+${getCountryCallingCode(code)} ${countryForCountryCode(code)}`,
        code: code,
      }
    })
  } catch (error) {
    return []
  }
}

const tz_ignore = ['Etc']

export const _timezoneChoices = (() => {
  return moment.tz
    .names()
    .sort()
    .map(value => {
      const shortName = value.split('/')

      if (shortName.length === 2 && tz_ignore.indexOf(shortName[0]) === -1) {
        const offset = moment.tz(value).utcOffset()
        return {
          id: value,
          title: value.replace('/', ' - ').replace('_', ' '),
          offset: offset,
        }
      }

      return undefined
    })
    .filter(a => {
      return (
        a !== undefined &&
        obj.availableContinents.find(continent =>
          a.id.startsWith(continent),
        ) !== undefined
      )
    })
    .sort((a, b) => {
      if (a.offset > b.offset) {
        return 1
      } else if (a.offset < b.offset) {
        return -1
      } else {
        return 0
      }
    })
})()

export const timezoneChoices = () => _timezoneChoices

export const localeForClinic = (clinics, clinicID) => {
  const clinic = clinics.find(({ id }) => id === Number(clinicID))

  if (clinic) {
    return clinic.locale
  }

  return obj.ClinicLocaleTypes.en_GB
}

export const clinicsForLocale = (clinics, clinicID, locale) => {
  const locales = [...new Set(clinics.map(({ locale }) => locale))].map(
    locale => {
      return {
        id: locale,
        title: countryForLocale(locale),
        isDisabled: false,
      }
    },
  )

  let selectedLocale = locale

  if (locales.length === 1) {
    selectedLocale = locales[0].id
  }

  const clinicList = clinics.filter(({ locale }) => locale === selectedLocale)

  let clinicFilter = clinicList.map(({ id, name }) => {
    return { id: id, title: name, isDisabled: false }
  })
  clinicFilter.unshift({ id: '', title: 'Show All', isDisabled: false })

  const clinic = clinicList.find(({ id }) => id === Number(clinicID))

  if (clinic) {
    selectedLocale = clinic.locale
  }

  return {
    locales: locales,
    clinics: clinicFilter,
    clinic: clinic ? clinic.id : '',
    locale: selectedLocale,
  }
}

export const formatCurrency = (value, locale) => {
  const currencyCode = getCurrency(locale)
  return currencyFormatter.format(value, { code: currencyCode })
}

export const availableCountries = [
  'Guernsey',
  'Ireland',
  'Isle of Man',
  'Jersey',
  'Northern Ireland',
  'United Kingdom',
].map(title => ({ id: title, title }))

export const filterMultiSelect = options =>
  options
    .filter(({ checked }) => checked === 'checked')
    .map(({ id }) => ({ id }))

export const getAppointmentStatusForModal = status =>
  ({
    CANCELLED: 'Appointment cancelled',
    NO_SHOW: 'Patient was a no show',
    PATIENT_ARRIVED: 'Patient has Arrived',
    READY_FOR_PATIENT: 'Ready for patient',
    ACTIVE: 'Consultation started',
    COMPLETE: 'Consultation finished',
  }[status])
