export const currentView = {
  CREATE: "isCreate",
  EDIT: "isEdit",
  VIEW: "isView",
};

export const types = {
  OPEN_APPOINTMENT_MODAL: "Open appointment modal",
  CLOSE_APPOINTMENT_MODAL: "Close appointment modal",
  CREATED_APPOINTMENT: "Created appointment",
  UPDATED_APPOINTMENT: "Updated appointment",
  DELETED_APPOINTMENT: "Deleted appointment",
  DATE_CHANGE: "Date change in appointment modal",
};
