import { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import dateFns from 'date-fns'
import { A_CalendarHeaderActions, A_ToggleDatePicker } from '../../../actions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

const dateFormat = 'MMM'
const yearFormat = 'YYYY'

class DatePickerHHL extends Component {
  constructor(props) {
    super(props)
    const { datePickerInputDate, selectedDate } = this.props

    let selectedDateVal
    let currentMonth

    if (datePickerInputDate) {
      //TODO: This is going to be needed to be fixed
      selectedDateVal = dateFns.parse(
        datePickerInputDate
          .split('/')
          .reverse()
          .join('/'),
        'YYYY/MM/DD',
      )
      currentMonth = dateFns.startOfMonth(selectedDateVal)
    } else {
      selectedDateVal = selectedDate.toDate()
      currentMonth = selectedDate
        .clone()
        .startOf('month')
        .add('14', 'days')
        .toDate()
    }

    this.state = {
      currentMonth: currentMonth,
      selectedDate: selectedDateVal,
      position: { top: '0px', left: '0px' },
    }

    this.handleClick = this.handleClick.bind(this)
    this.header = createRef()
  }

  getDPStyle() {
    const { dpInput, position } = this.props
    const rect = dpInput.getBoundingClientRect()
    const boundRight = rect.right - rect.left
    let dpStyles = {
      top: rect.top - 12 + 'px',
      left: rect.left + boundRight + 20 + 'px',
    }
    if (window.innerWidth < 600) {
      dpStyles = {
        top: rect.top + 55 + 'px',
        left: rect.left - 20 + 'px',
      }
      if (position === 'leftBottom') {
        dpStyles = {
          bottom: rect.bottom + 55 + 'px',
          left: rect.left - 20 + 'px',
        }
      }
    }
    if (position === 'leftBottom') {
      dpStyles = {
        top: rect.top - this.refs.dp.clientHeight + 55 + 'px', //eslint-disable-line
        left: rect.left + boundRight + 20 + 'px',
      }
    }
    this.setState({
      position: dpStyles,
    })
  }

  componentDidMount() {
    const { calendarType } = this.props
    document.addEventListener('mousedown', this.handleClick, false)
    if (calendarType === 'appointment_calendar') {
      this.getDPStyle()
      window.addEventListener('resize', this.getDPStyle.bind(this))
    }
  }

  componentWillUnmount() {
    const { calendarType } = this.props
    document.removeEventListener('mousedown', this.handleClick, false)
    if (calendarType === 'appointment_calendar') {
      window.removeEventListener('resize', this.getDPStyle.bind(this))
    }
  }

  handleClick = e => {
    const { actions, calendarType } = this.props
    if (this.header.current.contains(e.target)) {
      // do nothing
    } else {
      e.preventDefault()
      if (calendarType !== 'appointment_calendar') {
        actions.A_ToggleDatePicker()
      }
    }
  }

  renderHeader() {
    const { currentMonth } = this.state
    const { timezone } = this.props
    const momentMonth = moment.tz(currentMonth, timezone)

    return (
      <div className="datePicker_header">
        <button
          className="buttonCircle--35 icon-chevron-left"
          onClick={e => {
            this.prevMonth(e)
          }}
        />
        <div className="datePicker_header_text">
          <span className="datePicker_header--primaryText">
            {momentMonth.format(dateFormat)}{' '}
          </span>
          <span className="datePicker_header--secondaryText">
            {momentMonth.format(yearFormat)}
          </span>
        </div>
        <button
          className="buttonCircle--35 icon-chevron-right"
          onClick={e => {
            this.nextMonth(e)
          }}
        />
      </div>
    )
  }

  renderCells() {
    const { currentMonth, selectedDate } = this.state
    const { timezone } = this.props

    const momentMonth = moment.tz(currentMonth, timezone)
    const startDate = momentMonth
      .clone()
      .startOf('month')
      .startOf('isoWeek')
    const endDate = momentMonth
      .clone()
      .endOf('month')
      .endOf('isoWeek')

    const rows = []

    let days = []
    let day = startDate.clone()

    let tableData = []

    // Weekday headers
    for (let i = 0; i < 7; i++) {
      const cloneDay = day.clone()
      days.push(
        <th key={i}>
          <span className="datePicker_day">{cloneDay.format(' dd ')}</span>
        </th>,
      )

      day.add(1, 'day')
    }

    tableData.push(
      <thead key="header" className="datePicker_body">
        <tr className="datePicker_week_wrapper">{days}</tr>
      </thead>,
    )

    days = []
    day = startDate.clone()

    // Individual days
    while (endDate.diff(day, 'seconds') > 0) {
      for (let i = 0; i < 7; i++) {
        const cloneDay = day.clone()

        days.push(
          <td
            key={cloneDay}
            role="presentation"
            onClick={e => {
              this.onDateClick(cloneDay, e)
            }}
            onKeyDown={e => {
              this.onDateClick(cloneDay, e)
            }}
          >
            <button
              className={`datePicker_number ${
                !momentMonth.isSame(cloneDay, 'month')
                  ? 'datePicker_number--fade'
                  : moment(selectedDate).isSame(cloneDay, 'day')
                  ? 'datePicker_number--selected'
                  : moment.tz(new Date(), timezone).isSame(cloneDay, 'day')
                  ? 'datePicker_number--today'
                  : ''
              }`}
            >
              {cloneDay.format('D')}
            </button>
          </td>,
        )
        day.add(1, 'day')
      }
      rows.push(
        <tr className="datePicker_week_wrapper" key={day.toDate()}>
          {days}
        </tr>,
      )
      days = []
    }
    tableData.push(<tbody key="days">{rows}</tbody>)

    return tableData
  }

  onDateClick = day => {
    const { calendarType, changeDate, actions } = this.props

    this.setState({
      selectedDate: day,
    })

    if (
      calendarType === 'weekly_calendar' ||
      calendarType === 'appointment_calendar' ||
      calendarType === 'practitionerCalendar'
    ) {
      // Only used in appointment modal
      changeDate(day.format('DD/MM/YYYY'))
      if (
        calendarType === 'weekly_calendar' ||
        calendarType === 'practitionerCalendar'
      ) {
        actions.A_CalendarHeaderActions(day)
        if (calendarType !== 'appointment_calendar') {
          actions.A_ToggleDatePicker()
        }
      }
    }
  }

  nextMonth = e => {
    const { timezone } = this.props
    e.preventDefault()
    this.setState({
      currentMonth: moment(this.state.currentMonth, timezone)
        .add(1, 'months')
        .toDate(),
    })
  }

  prevMonth = e => {
    const { timezone } = this.props
    e.preventDefault()
    this.setState({
      currentMonth: moment(this.state.currentMonth, timezone)
        .subtract(1, 'months')
        .toDate(),
    })
  }

  render() {
    const { calendarType, position } = this.props
    let dpClasses = 'datePicker--weekly fadeInUnravelAnimate'
    let pos = {}
    if (calendarType === 'appointment_calendar') {
      pos = this.state.position
      dpClasses = 'fadeInAnimateFull datePicker--appointment datePicker--fixed'
      switch (position) {
        case 'leftTop':
          dpClasses += ' datePicker--leftTop'
          break
        case 'leftBottom':
          dpClasses += ' datePicker--leftBottom'
          break
        default:
          break
      }
    }

    if (calendarType === 'practitionerCalendar') {
      pos = this.state.position
      dpClasses =
        'datePicker--weekly datePicker--practitioner fadeInUnravelAnimate'
      switch (position) {
        case 'leftTop':
          dpClasses += ' datePicker--leftTop'
          break
        case 'leftBottom':
          dpClasses += ' datePicker--leftBottom'
          break
        default:
          break
      }
    }

    return (
      <div className="" ref={this.header}>
        <div
          className={`datePicker datePicker--open datePicker--popup ${dpClasses}`}
          style={pos}
        >
          {this.renderHeader()}
          <table className="datePicker_body">{this.renderCells()}</table>
        </div>
      </div>
    )
  }
}

DatePickerHHL.defaultProps = {
  calendarType: 'appointment_calendar',
  dpInput: null,
  position: 'leftTop',
  changeDate: () => {},
  datePickerInputDate: '',
}

DatePickerHHL.propTypes = {
  calendarType: PropTypes.string,
  dpInput: PropTypes.object,
  position: PropTypes.string,
  changeDate: PropTypes.func,
  datePickerInputDate: PropTypes.string,
  selectedDate: PropTypes.instanceOf(moment).isRequired,
  actions: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_ToggleDatePicker,
        A_CalendarHeaderActions,
      },
      dispatch,
    ),
  }
}

function mapState(state) {
  return {
    datepickerIsVisible: state.calendar.toggleDatePicker,
    selectedDate: state.calendar.selectedDate.clone().startOf('day'),
    timezone: state.global.currentClinic.timezone,
  }
}

export default withRouter(connect(mapState, mapDispatchToProps)(DatePickerHHL))
