import PropTypes from 'prop-types'

import Notes from '../Notes'

import Button from '../../../../../Buttons'
import TextArea from '../../../../../forms/Textarea'

export default function Meeting({ notes, setNote, timezone, note, addNote }) {
  return (
    <section className="form__group--topStackable">
      <label className="form__label" htmlFor="notes">
        Notes
      </label>
      <Notes notes={notes} timezone={timezone} />
      <fieldset className="form__group--modal-last">
        <TextArea
          label="New note"
          placeholder="What would you like to post?"
          value={note}
          onChange={({ target }) => setNote(target.value)}
        />
        <Button
          styleType="secondary"
          size="small"
          label="Post"
          isDisabled={!note.length}
          clickHandler={() => addNote(note)}
        />
      </fieldset>
    </section>
  )
}

Meeting.propTypes = {
  notes: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  setNote: PropTypes.func.isRequired,
  addNote: PropTypes.func.isRequired,
}
