import { Component } from 'react'
import dateFns from 'date-fns'
import PropTypes from 'prop-types'
import {
  A_CalendarHeaderMonthActions,
  A_ToggleDatePicker,
} from '../../../actions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as utils from '../../../utilities/ReusableFunctions'

const dateFormat = 'MMM'
const yearFormat = 'YYYY'

class MonthlyDatePickerHHL extends Component {
  constructor(props) {
    super(props)
    const { scheduleCalendarDateHeader } = this.props
    this.state = {
      currentMonth: utils.getDateForMonthStart(
        scheduleCalendarDateHeader.month,
        scheduleCalendarDateHeader.year,
      ),
    }
    this.prevYear = this.prevYear.bind(this)
    this.nextYear = this.nextYear.bind(this)
  }

  renderHeader() {
    return (
      <div className="datePicker_header">
        <button
          className="buttonCircle--35 icon-chevron-left"
          onClick={this.prevYear}
        />
        <div className="datePicker_header_text">
          <span className="datePicker_header--secondaryText">
            {dateFns.format(this.state.currentMonth, yearFormat)}
          </span>
        </div>
        <button
          className="buttonCircle--35 icon-chevron-right"
          onClick={this.nextYear}
        />
      </div>
    )
  }

  renderCells() {
    const { scheduleCalendarDateHeader } = this.props
    let rows = []
    let months = []
    let month = dateFns.startOfYear(this.state.currentMonth)
    let formattedMonth = ''
    let formattedYear = ''
    for (let i = 0; i < 4; i++) {
      for (let i = 0; i < 3; i++) {
        formattedMonth = dateFns.format(month, dateFormat)
        formattedYear = dateFns.format(month, yearFormat)
        const cloneMonth = month
        months.push(
          <td
            role="presentation"
            key={month}
            onClick={e => {
              this.onMonthClick(dateFns.parse(cloneMonth), e)
            }}
          >
            <button
              className={`datePicker_number datePicker_month ${
                formattedMonth === scheduleCalendarDateHeader.month &&
                scheduleCalendarDateHeader.year === formattedYear
                  ? 'datePicker_number--selected'
                  : dateFns.format(new Date(), yearFormat) === formattedYear &&
                    dateFns.format(new Date(), dateFormat) === formattedMonth &&
                    scheduleCalendarDateHeader.month !== formattedMonth
                  ? 'datePicker_number--today'
                  : ''
              }`}
            >
              {formattedMonth}
            </button>
          </td>,
        )
        month = dateFns.addMonths(month, 1)
      }
      rows.push(
        <tr className="datePicker_month_wrapper" key={month}>
          {months}
        </tr>,
      )
      months = []
    }
    return <tbody>{rows}</tbody>
  }

  onMonthClick = month => {
    const { A_ToggleDatePicker, A_CalendarHeaderMonthActions } = this.props
    const date = {
      month: dateFns.format(month, dateFormat),
      year: dateFns.format(this.state.currentMonth, yearFormat),
    }
    this.setState({
      currentMonth: month,
    })
    A_CalendarHeaderMonthActions(date, this.state.currentMonth)
    A_ToggleDatePicker()
  }

  nextYear = () => {
    this.setState({
      currentMonth: dateFns.addYears(this.state.currentMonth, 1),
    })
  }

  prevYear = () => {
    this.setState({
      currentMonth: dateFns.subYears(this.state.currentMonth, 1),
    })
  }

  render() {
    return (
      <div className="datePicker datePicker--open datePicker--weekly datePicker--popup fadeInUnravelAnimate">
        {this.renderHeader()}
        <table className="datePicker_body">{this.renderCells()}</table>
      </div>
    )
  }
}

MonthlyDatePickerHHL.propTypes = {
  scheduleCalendarDateHeader: PropTypes.isRequired,
  A_ToggleDatePicker: PropTypes.isRequired,
  A_CalendarHeaderMonthActions: PropTypes.isRequired,
}

function mapState(state) {
  return {
    datepickerIsVisible: state.calendar.toggleDatePicker,
    scheduleCalendarDateHeader: state.schedule.scheduleCalendarDateHeader,
    currentMonth: state.schedule.calendarDate,
  }
}

export default withRouter(
  connect(mapState, { A_ToggleDatePicker, A_CalendarHeaderMonthActions })(
    MonthlyDatePickerHHL,
  ),
)
