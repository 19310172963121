import * as api from '../../api'
import * as url from '../../../api_urls'

export const getClinicList = () => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getclinicDetails = id => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + id + '/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}