import { useState } from 'react'
import PropTypes from 'prop-types'

import Bundle from '../Bundle'
import { AdyenPaymentDropin } from '@clever-clinic/clever-components'
import '@adyen/adyen-web/dist/adyen.css'
import Axios from 'axios'
import { V2_SERVER } from '../../../../../api_urls'

export default function Bundles({
  activeBundle,
  setBundle,
  bundles,
  formatCredit,
  clinicGroupName,
  token,
}) {
  const [isTokenNoticeOpen, setIsTokenNoticeOpen] = useState(false)

  const adyenConfig = {
    adyen: {
      environment:  'test',
      clientKey:
        
        'test_5SYFLNEACFFKFDHJ2V4M4EFIXQ5F2RR4',
    },
    url:
      activeBundle &&
      `${V2_SERVER}/notifications/bundle/${activeBundle.id}/session/`,
  }

  const axiosInstance = Axios.create({
    withCredentials: true,
    responseType: 'json',
    headers: {
      Authorization: `Bearer ${token?.access}`,
    },
  })

  return (
    <div className="settings--view settings--view-lower">
      <h2>Purchase a new SMS bundle</h2>
      <p className="settings--view-desc">
        {`Bundles give you the ability to purchase credits at a fixed pay as you
        go price. Purchasing a bundle increases ${clinicGroupName}'s credits, if you
        want to buy more bundles simply select a bundle that you prefer and click the 'Confirm payment' button.`}
      </p>
      <div className="bundle--wrapper">
        {bundles.map(bundle => (
          <Bundle
            key={bundle.id}
            credits={bundle.amount}
            price={bundle.price}
            formatCredit={formatCredit}
            isActive={activeBundle === bundle}
            onClick={() => setBundle(activeBundle !== bundle ? bundle : null)}
          />
        ))}
      </div>
      {isTokenNoticeOpen && (
        <div className="payment-complete-modal-container">
          <div className="payment-complete-modal">
            <img src="../../libs/assets/thank-you.svg" alt="thank you icon" />
            <p className="payment-complete-title">
              Thank you for your purchase
            </p>
            <p className="payment-complete-notice">
              <span className="payment-complete-modal-red">
                Please note: &nbsp;
              </span>
              It may take a short while for the SMS credits to appear in your
              accounnt.
            </p>
            <button
              className="payment-complete-modal-close-button"
              onClick={() => {
                setIsTokenNoticeOpen(!isTokenNoticeOpen)
                window.location.reload()
              }}
            >
              Okay
            </button>
          </div>
        </div>
      )}
      {activeBundle && (
        <div style={{ padding: '2rem 4rem' }}>
          <AdyenPaymentDropin
            config={adyenConfig}
            onError={() => {}}
            axiosInstance={axiosInstance}
            onPaymentComplete={response => {
              if (response.resultCode === 'Authorised') {
                setIsTokenNoticeOpen(true)
                window.scrollTo(0, 0)
              }
            }}
          />
        </div>
      )}
    </div>
  )
}

Bundles.defaultProps = {
  activeBundle: null,
  token: {},
}

Bundles.propTypes = {
  activeBundle: PropTypes.object,
  setBundle: PropTypes.func.isRequired,
  bundles: PropTypes.array.isRequired,
  formatCredit: PropTypes.func.isRequired,
  clinicGroupName: PropTypes.string.isRequired,
  token: PropTypes.shape({ access: PropTypes.any }),
}
