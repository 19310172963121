import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import RenderCalendarAppointments from './RenderCalendarAppointments'
import { A_SetAddEditAppointmentScrollPos } from '../../actions'

let scrollarea = ''

class TimeDivider extends Component {
  constructor(props) {
    super(props)
    const { zoomed } = this.props
    this.state = {
      appointmentDefaultLength: props.defaultAppointmentLength,
      openingTimePos: Number(props.calendar.defaultOpeningTime) * 60,
      zoomed: zoomed,
    }
    this.getPractitionerAppointmentDivisionLength = this.getPractitionerAppointmentDivisionLength.bind(
      this,
    )
    this.setScrollPosition = this.setScrollPosition.bind(this)
  }

  componentDidMount() {
    const { calendarType } = this.props
    scrollarea =
      calendarType === 'practitionerCalendar'
        ? document.getElementsByClassName('calendar_body--practitioner')[0]
        : document.getElementsByClassName('appointment_selector')[0]
    ////// SCROLL LISTENER UPDATES HEADER AND TIME DIVISIONS ON SCROLL /////
    this.setScrollListener()
    this.setScrollPosition(this.state.openingTimePos)
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { calendar, actions } = this.props
    if (
      (props.calendar.defaultOpeningTime !== calendar.defaultOpeningTime ||
      props.calendar.selectedDate !== calendar.selectedDate) && props.calendar.addEditAppointmentScroll === ''
    ) {
      console.log(props.calendar.addEditAppointmentScroll)
      const timeposition = parseInt(props.calendar.defaultOpeningTime) * 60
      this.setState({ openingTimePos: timeposition }, () => {
        this.setScrollListener()
        this.setScrollPosition(timeposition)
      })
    }

    if (props.calendar.addEditAppointmentScroll) {
      const timeposition = parseInt(props.calendar.addEditAppointmentScroll) * 60
      this.setScrollPosition(timeposition)
      actions.A_SetAddEditAppointmentScrollPos(null)
    }
  }

  /**
   * Set the scroll of Practitioner Header and Time Selector components
   */
  setScrollListener() {
    scrollarea.onscroll = function(e) {
      document.getElementsByClassName('practitioner_header')[0].scrollLeft =
        e.target.scrollLeft
      document.getElementsByClassName('time_selector')[0].scrollTop =
        e.target.scrollTop
    }
  }

  getPractitionerAppointmentDivisionLength(mins) {
    const { calendarType } = this.props
    if (calendarType === 'practitionerCalendar') {
      return 140
    } else {
      let divisionHeight = this.state.zoomed === 'zoomedout' ? 52.5 : 105
      return mins * (divisionHeight / 15)
    }
  }

  /**
   * Appointment scroll positioning.
   */
  
  setScrollPosition(position) {
    var appointmentHeight = this.getPractitionerAppointmentDivisionLength(
      this.state.appointmentDefaultLength,
    )
    scrollarea.scrollTop =
      (appointmentHeight / this.state.appointmentDefaultLength) *
        position -
      10
    document.getElementsByClassName('time_selector')[0].scrollTop =
      (appointmentHeight / this.state.appointmentDefaultLength) *
        position -
      10
  }

  componentWillUnmount() {
    scrollarea.onscroll = null
  }

  render() {
    const {
      calendarType,
      oddEvenKey,
      bookedAppointments,
      defaultAppointmentLength,
      clinician,
      load,
    } = this.props
    return (
      <React.Fragment>
        <RenderCalendarAppointments
          oddEvenKey={oddEvenKey}
          defaultAppointmentLength={defaultAppointmentLength}
          clinician={clinician}
          bookedAppointments={bookedAppointments}
          calendarType={calendarType}
          load={load}
        />
      </React.Fragment>
    )
  }
}
//

TimeDivider.defaultProps = {
  calendarType: 'weeklyCalendar',
  oddEvenKey: 0,
  bookedAppointments: [],
  defaultAppointmentLength: 20,
  zoomed: '',
}

TimeDivider.propTypes = {
  actions: PropTypes.object.isRequired,
  calendarType: PropTypes.string,
  oddEvenKey: PropTypes.number,
  bookedAppointments: PropTypes.array,
  defaultAppointmentLength: PropTypes.number,
  zoomed: PropTypes.string,
  clinician: PropTypes.object.isRequired,
  calendar: PropTypes.object.isRequired,
  load: PropTypes.func.isRequired,
}

/** Need to change this as per need */
const mapStateToProps = state => {
  return {
    calendar: state.calendar,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_SetAddEditAppointmentScrollPos,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TimeDivider),
)
