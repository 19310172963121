import { currentView, types } from "./types";

const initialState = {
  isOpen: false,
  handleClose: () => {},
  currentView: currentView["CREATE"],
  clinicId: undefined,
  appointmentId: undefined,
  initialValues: {},
};

const AppointmentModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_APPOINTMENT_MODAL:
      return {
        ...initialState,
        isOpen: true,
        ...action.payload,
      };
    case types.CLOSE_APPOINTMENT_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default AppointmentModalReducer;
