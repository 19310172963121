import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getFullName, getInitials } from '../../utilities/ReusableFunctions'
import Avatar from '../../components/Avatar'
import {
  appointmentPurposeIds,
  appointmentPurposeTitles,
  appointmentReasonIds,
  appointmentReasonTitles,
  jobTypeNames,
} from '../../utilities/ReusableObjects'
import ReactToPrint from 'react-to-print'

class OverviewClinician extends Component {
  render() {
    const {
      clinician,
      index,
      appointments,
      appointmentReasonsList,
      visibleClinician,
      handleClinician,
    } = this.props

    const clinicianAppointments = appointments.filter(
      ({ clinician: { id } }) => id === clinician.id,
    )

    if (clinicianAppointments.length === 0) {
      return ''
    }

    let orderedAppointments = []

    clinicianAppointments.forEach(app => {
      const momentDate = moment.tz(app.start_date, 'UTC')
      let date = momentDate.format('YYYY.MM.DD')
      let title = momentDate.format('DD MMMM')

      let order = orderedAppointments.find(({ key }) => key === date)

      if (order === undefined) {
        order = {
          key: date,
          title: title,
          appointments: [app],
        }
        orderedAppointments.push(order)
      } else {
        order.appointments.push(app)
      }
    })

    return (
      <div ref={el => (this.printSchedule = el)}>
        <div className="page-break"></div>
        <article
          key={clinician.id}
          className="form overviewAppointments__clinician print"
        >
          <div
            className="practitioner_details"
            style={{ outline: 'none', cursor: 'pointer' }}
            role="button"
            tabIndex={index}
            onClick={() => handleClinician(clinician.id)}
            onKeyDown={() => {}}
          >
            <div className="practitioner_detail practitioner_detail_avatar no-print">
              <Avatar
                src={clinician.signed_avatar}
                type="practitionerProfileHeaderCalendar"
                initials={getInitials(clinician)}
              />
            </div>
            <div className="practitioner_detail practitioner_detail_info">
              <h2 className="practitioner_name singleLine_truncatedText">
                {getFullName(clinician)}
              </h2>
              <div className="practitioner_role singleLine_truncatedText">
                {jobTypeNames[clinician.job_type]}
              </div>
            </div>
            <div
              className="practitioner_detail practitioner_detail_info no-print"
              style={{ position: 'absolute', left: '50%' }}
            >
              <h2 className="practitioner_name singleLine_truncatedText">
                {clinicianAppointments.length}
              </h2>
              <div className="practitioner_role singleLine_truncatedText">
                Upcoming appointments
              </div>
            </div>
            <div className="practitioner_detail practitioner_detail_add no-print">
              <div className="weeklyOverviewBar--wrapper weeklyOverviewBar--wrapperHeader">
                <span
                  style={{ marginLeft: '16px', lineHeight: '24px' }}
                  className={
                    visibleClinician === clinician.id
                      ? 'icon-chevron-up'
                      : 'icon-chevron-down'
                  }
                />
              </div>
              <div className="weeklyOverviewBar--container">
                {appointmentReasonsList.map(key => {
                  const amount = clinicianAppointments.filter(
                    ({ appointment_type }) =>
                      appointment_type === appointmentReasonIds[key],
                  ).length
                  if (amount > 0) {
                    return (
                      <div
                        key={key}
                        className="weeklyOverviewBar--wrapper weeklyOverviewBar--wrapperHeader"
                      >
                        <span
                          className={`p weeklyOverviewBar--${key} weeklyOverviewLabel--${key}`}
                        >
                          {amount}
                        </span>
                      </div>
                    )
                  }
                  return ''
                })}
                {Object.keys(appointmentPurposeIds)
                  .reverse()
                  .map(key => {
                    const amount = clinicianAppointments.filter(
                      ({ purpose, appointment_type }) =>
                        purpose === appointmentPurposeIds[key] &&
                        appointment_type === appointmentReasonIds.APPOINTMENT,
                    ).length
                    if (amount > 0) {
                      return (
                        <div
                          key={key}
                          className="weeklyOverviewBar--wrapper weeklyOverviewBar--wrapperHeader"
                        >
                          <span
                            className={`p weeklyOverviewBar--${key} weeklyOverviewLabel--${key}`}
                          >
                            {amount}
                          </span>
                        </div>
                      )
                    }
                    return ''
                  })}
              </div>
            </div>
          </div>
          <div
            style={{
              display: visibleClinician === clinician.id ? 'block' : 'none',
            }}
            className="print print--nopadding"
          >
            <hr className="legacy" />
            <div>
              {orderedAppointments.map(({ title, appointments }) => {
                return (
                  <div key={title}>
                    <div className="page-break"></div>
                    <div className="clinicTables__container">
                      <div
                        style={{ width: '162px' }}
                        className="print print--nopadding"
                      >
                        <div className="practitioner_detail practitioner_detail_info">
                          <div className="practitioner_name singleLine_truncatedText">
                            {title}
                          </div>
                          <div className="practitioner_role singleLine_truncatedText">
                            {appointments.length} appointments
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ width: 'calc(100% - 162px)' }}
                        className="print print--nopadding"
                      >
                        {appointments.map(app => {
                          const colorClass = Object.keys(appointmentReasonIds)
                            .filter(
                              key =>
                                appointmentReasonIds[key] ===
                                app.appointment_type,
                            )
                            .map(key => {
                              switch (appointmentReasonIds[key]) {
                                case appointmentReasonIds.APPOINTMENT:
                                  return `weeklyOverviewBar--${Object.keys(
                                    appointmentPurposeIds,
                                  ).find(
                                    key =>
                                      appointmentPurposeIds[key] ===
                                      app.purpose,
                                  )}`
                                default:
                                  return `weeklyOverviewBar--${key}`
                              }
                            })

                          const appointmentType = Object.keys(
                            appointmentReasonIds,
                          )
                            .filter(
                              key =>
                                appointmentReasonIds[key] ===
                                app.appointment_type,
                            )
                            .map(key => {
                              switch (appointmentReasonIds[key]) {
                                case appointmentReasonIds.APPOINTMENT:
                                  return appointmentPurposeTitles[
                                    Object.keys(appointmentPurposeIds).find(
                                      key =>
                                        appointmentPurposeIds[key] ===
                                        app.purpose,
                                    )
                                  ]
                                default:
                                  return appointmentReasonTitles[key]
                              }
                            })

                          let title

                          switch (app.appointment_type) {
                            case appointmentReasonIds.APPOINTMENT:
                              title = getFullName(app.patient)
                              break
                            default:
                              title = app.title
                          }

                          return (
                            <div key={app.id} className="avoid-break">
                              <div className="page-break"></div>
                              <div
                                className={`${colorClass}`}
                                style={{
                                  borderRadius: '8px',
                                  padding: '16px',
                                  display: 'flex',
                                  alignContent: 'space-between',
                                  flexWrap: 'wrap',
                                  marginBottom: '16px',
                                  position: 'relative',
                                }}
                              >
                                <div style={{ width: 'calc(100% * (3/12))' }}>
                                  <div className="practitioner_name singleLine_truncatedText">
                                    {moment
                                      .tz(app.start_date, 'UTC')
                                      .format('HH:mm')}
                                    {moment
                                      .tz(app.end_date, 'UTC')
                                      .format(' - HH:mm')}
                                  </div>
                                  {app.room ? (
                                    <div className="practitioner_role singleLine_truncatedText">
                                      {app.room.name}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div className="weeklyOverviewBar--wrapper weeklyOverviewBar--background">
                                    <span className={`p ${colorClass}`}>
                                      {appointmentType}
                                    </span>
                                  </div>
                                </div>
                                <div style={{ width: 'calc(100% * (4/12))' }}>
                                  <div className="practitioner_name singleLine_truncatedText">
                                    {title}
                                  </div>
                                  {app.patient ? (
                                    <>
                                      <div className="practitioner_role singleLine_truncatedText">
                                        {app.patient.date_of_birth
                                          ? `${moment
                                              .tz(
                                                app.patient.date_of_birth,
                                                'UTC',
                                              )
                                              .format('DD.MM.YYYY')} (${
                                              app.patient.age
                                            })`
                                          : 'No date of birth registered'}
                                      </div>
                                      <br />
                                      <div className="practitioner_role singleLine_truncatedText">
                                        {app.patient.mobile_phone}
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                {app.appointment_type ===
                                appointmentReasonIds.APPOINTMENT ? (
                                  <>
                                    <div
                                      style={{ width: 'calc(100% * (4/12))' }}
                                    >
                                      {app.treatments.length > 0 ? (
                                        <>
                                          <div className="practitioner_name singleLine_truncatedText">
                                            {app.treatment_type.title}
                                          </div>
                                          <div className="practitioner_role singleLine_truncatedText">
                                            {app.treatments
                                              .map(({ title }) => title)
                                              .join(', ')}
                                          </div>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        width: 'calc(100% * (1/12))',
                                        textAlign: 'right',
                                      }}
                                    >
                                      {app.patient ? (
                                        <div className="practitioner_name singleLine_truncatedText appointmentDetail_cell--lowlight">
                                          {app.patient.id}
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <hr className="no-print legacy" />
            <ReactToPrint
              trigger={() => {
                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return (
                  <div
                    className="no-print"
                    style={{ paddingBottom: '16px', textAlign: 'right' }}
                  >
                    <button
                      type="button"
                      className="buttonTertiary button--small"
                    >
                      <span className="icon-printer" /> Print schedule
                    </button>
                  </div>
                )
              }}
              content={() => this.printSchedule}
            />
          </div>
        </article>
      </div>
    )
  }
}

OverviewClinician.defaultProps = {}

OverviewClinician.propTypes = {
  index: PropTypes.number.isRequired,
  appointments: PropTypes.array.isRequired,
  visibleClinician: PropTypes.number.isRequired,
  clinician: PropTypes.object.isRequired,
  handleClinician: PropTypes.func.isRequired,
  appointmentReasonsList: PropTypes.array.isRequired,
}

export default OverviewClinician
