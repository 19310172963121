import PropTypes from 'prop-types'
import moment from 'moment'

import { Link } from 'react-router-dom'

import {
  getItems,
  getFullName,
} from '../../../../../utilities/ReusableFunctions'
import { Fragment } from 'react'

export default function View({
  patient,
  closeModal,
  createdBy,
  bookingSent,
  reminderSent,
}) {
  const extras = patient
    ? [
        {
          id: 'booking_sent',
          title: 'Confirmation Sent',
          value: bookingSent ? 'Yes' : 'No',
        },
        {
          id: 'reminder_sent',
          title: 'Reminder Sent',
          value: reminderSent ? 'Yes' : 'No',
        },
      ]
    : []

  return (
    <section className="form__group--topStackable modal__item--flex-shrink">
      {patient ? (
        <>
          {[
            {
              id: 'gender',
              title: 'Patient gender',
              value: getItems('gender')[patient.gender]
                ? getItems('gender')[patient.gender].title
                : '-',
            },
            {
              id: 'dob',
              title: 'Date of birth',
              value: patient.date_of_birth
                ? `${moment(patient.date_of_birth).format('DD.MM.YY')} (${
                    patient.age
                  })`
                : '-',
            },
            {
              id: 'home_phone',
              title: 'Home phone',
              value: patient.home_phone || '-',
            },
            {
              id: 'mobile',
              title: 'Mobile',
              value: patient.mobile_phone || '-',
            },
            {
              id: 'work_phone',
              title: 'Work phone',
              value: patient.work_phone || '-',
            },
          ].map(row => {
            return (
              <Fragment key={row.id}>
                <label className="form__label" htmlFor={row.id}>
                  {row.title}
                </label>
                <p className="form__value">{row.value}</p>
              </Fragment>
            )
          })}
          <Link
            to={`/clinics/patients/${patient.id}/treatments`}
            onClick={() => closeModal()}
          >
            <p className="textLink form__value">
              <span className="icon-nav-staff" />
              View full record
            </p>
          </Link>
        </>
      ) : (
        ''
      )}

      {[
        {
          id: 'booked_by',
          title: 'Booked by',
          value: getFullName(createdBy) || '-',
        },
      ]
        .concat(extras)
        .map(row => {
          return (
            <Fragment key={row.id}>
              <label className="form__label" htmlFor={row.id}>
                {row.title}
              </label>
              <p className="form__value">{row.value}</p>
            </Fragment>
          )
        })}
    </section>
  )
}

View.propTypes = {
  patient: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  createdBy: PropTypes.object.isRequired,
  bookingSent: PropTypes.bool.isRequired,
  reminderSent: PropTypes.bool.isRequired,
}
