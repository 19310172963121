import * as obj from '../../utilities/ReusableObjects'

import types from '../../actions/types'
let Show
let type
let initialState = {
  Show: false,
  type: 'password',
}
export default (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PASSWORD_VIEW:
      if (state) {
        Show = !state.Show
        type = state.type === 'password' ? 'input' : 'password'
      }
      return {
        ...state,
        Show: Show,
        type: type,
      }
    default:
      return state
  }
}
