import * as clinic from '../services/WeeklyCalendar/CalendarWeeklyService'
import types from './types'

export const A_ToggleDatePicker = payload => ({
  type: types.TOGGLE_DATE_PICKER,
  payload: payload,
})

export const A_CalendarHeaderActions = currentday => ({
  type: types.CALENDAR_HEADER_RENDER_DATE,
  currentday: currentday,
})

export const A_GetZoomStatus = data => ({ type: types.GET_ZOOM_STATUS, data })

export const A_ShowPicker = () => ({ type: types.SHOW_PICKER })

export const A_SetClinicList = clinicsArray => ({
  type: types.SET_CLINICS_LIST,
  clinicsArray,
})

export const A_SetViewingClinic = clinic => ({
  type: types.VIEWING_CLINIC,
  clinic,
})

export const A_SetAppointmentOpeningTime = openingTime => ({
  type: types.SET_APPOINTMENT_OPENING_TIME,
  openingTime,
})

export const A_CloseDatePicker = () => ({ type: types.CLOSE_DATE_PICKER })

export const A_IsAppointmentChange = value => ({
  type: types.APPOINTMENT_UPDATED,
  value,
})

export const A_SetAddEditAppointmentScrollPos = value => ({
  type: types.SET_APPOINTMENT_SCROLL_POS,
  value,
})

export const A_SetClickedCellData = value => ({
  type: types.SET_CLICKED_CELL_DATA,
  value,
})

export const A_GetPractitionerAppointmentDetails = (
  date,
  clinicianID,
  clinicID,
) => dispatch =>
  clinic
    .getPractitionerAppointmentList(date, clinicianID, clinicID)
    .then(response => {
      dispatch({ type: types.FETCH_PRACTITIONER_APPOINTMENT.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.FETCH_PRACTITIONER_APPOINTMENT.FAIL, error })
      return Promise.reject(error)
    })
