import * as patient from '../services/Patients/PatientService'
import * as finance from '../services/PatientFinance/InvoiceTimeLineService'
import { ignoreGlobalError } from '../utilities/ReusableFunctions'
import types from './types'

export const A_GetFilteredPatients = data => dispatch => {
  dispatch({ type: types.PATIENT_SEARCH_VALUE, searchValue: data.patient })

  return patient
    .getFilteredPatients(data)
    .then(response => {
      dispatch({
        type: types.GET_FILTERED_PATIENTS.OK,
        data: response,
        nextQuery: data.nextQuery,
        searchValue: data.patient,
      })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_FILTERED_PATIENTS.FAIL, error })
      return Promise.reject(error)
    })
}

export const A_GetDocumentsList = data => dispatch =>
  patient
    .getDocumentsList(data.clinic.id, data.id)
    .then(response => {
      dispatch({
        type: types.GET_DOCUMENTS_LIST.OK,
        data: response,
        nextQuery: data.nextQuery,
      })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_DOCUMENTS_LIST.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetFilteredDocuments = data => dispatch =>
  patient
    .getFilteredDocuments(data)
    .then(response => {
      dispatch({
        type: types.GET_FILTERED_DOCUMENTS.OK,
        data: response,
        nextQuery: data.nextQuery,
      })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_FILTERED_DOCUMENTS.FAIL, error })
      return Promise.reject(error)
    })

export const A_SetFilteredDocumentList = payload => ({
  type: types.SET_FILTERED_DOCUMENT_LIST,
  payload,
})

export const A_SetSelectedDocument = documentData => ({
  type: types.SET_SELECTED_DOCUMENT,
  document: documentData,
})

export const A_GetPatientDetail = (clinicId, patientId) => dispatch =>
  patient
    .getPatientDetail(clinicId, patientId)
    .then(response => {
      dispatch({ type: types.GET_PATIENT_DETAIL.OK, patient: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_PATIENT_DETAIL.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetPatientMedicalHistoryOptionsList = clinicId => dispatch =>
  patient
    .getPatientMedicalHistoryOptionsList(clinicId)
    .then(response => {
      dispatch({
        type: types.GET_PATIENT_MEDICAL_HISTORY_OPTIONS_LIST.OK,
        options: response,
      })
      return response
    })
    .catch(error => {
      dispatch({
        type: types.GET_PATIENT_MEDICAL_HISTORY_OPTIONS_LIST.FAIL,
        error,
      })
      return Promise.reject(error)
    })

export const A_SetSelectedPatient = patientData => ({
  type: types.SET_SELECTED_PATIENT,
  patient: patientData,
})

export const A_ResetSelectedPatient = () => ({
  type: types.RESET_SELECTED_PATIENT,
})

export const A_UpdatePatientDetail = (
  clinicId,
  patientId,
  patientData,
) => dispatch =>
  patient
    .updatePatientDetail(clinicId, patientId, patientData)
    .then(response => {
      dispatch({ type: types.UPDATE_PATIENT_DETAIL.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({
        type: types.UPDATE_PATIENT_DETAIL.FAIL,
        error,
        ignoreGlobalError: ignoreGlobalError(error),
      })
      return error
    })

export const A_GetTimelineInteractions = (
  clinicId,
  patientId,
  searchValue,
  filterValue,
  nextQuery,
) => dispatch =>
  patient
    .getTimelineInteractions(
      clinicId,
      patientId,
      searchValue,
      filterValue,
      nextQuery,
    )
    .then(response => {
      dispatch({
        type: types.GET_PATIENT_TIMELINE_INTERACTIONS.OK,
        data: response,
        nextQuery: nextQuery,
      })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_PATIENT_TIMELINE_INTERACTIONS.FAIL, error })
      return Promise.reject(error)
    })

export const A_SetInteractionSearchValue = searchValue => ({
  type: types.SET_INTERACTION_SEARCH_VALUE,
  searchValue,
})

export const A_AddPatientDocument = (
  clinicId,
  patientId,
  correspondenceData,
) => dispatch =>
  patient
    .addPatientDocument(clinicId, patientId, correspondenceData)
    .then(response => {
      dispatch({ type: types.ADD_PATIENT_DOCUMENT.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.ADD_PATIENT_DOCUMENT.FAIL, error })
      return Promise.reject(error)
    })

export const A_AddCorrespondence = (
  clinicId,
  patientId,
  correspondenceData,
) => dispatch =>
  patient
    .addCorrespondence(clinicId, patientId, correspondenceData)
    .then(response => {
      dispatch({ type: types.ADD_CORRESPONDENCE.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.ADD_CORRESPONDENCE.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetOutstandingActions = (clinicId, patientId) => dispatch =>
  patient
    .getOutstandingActions(clinicId, patientId)
    .then(response => {
      dispatch({ type: types.GET_OUTSTANDING_ACTIONS.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_OUTSTANDING_ACTIONS.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetConsultationRecord = appointmentDetails => dispatch =>
  patient
    .getConsultationRecord(appointmentDetails)
    .then(response => {
      dispatch({ type: types.GET_CONSULTATION_RECORD.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_CONSULTATION_RECORD.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetPatientConsultationsList = consultationData => dispatch =>
  finance
    .getPatientConsultationsList(consultationData)
    .then(response => {
      dispatch({ type: types.GET_PATIENT_CONSULTATION_LIST.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_PATIENT_CONSULTATION_LIST.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetPatientConsultationDetail = consultationData => dispatch =>
  finance
    .getPatientConsultationDetail(consultationData)
    .then(response => {
      dispatch({
        type: types.GET_PATIENT_CONSULTATION_DETAIL.OK,
        data: response,
      })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_PATIENT_CONSULTATION_DETAIL.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetPatientBillList = patientBillingData => dispatch =>
  finance
    .getPatientBillList(patientBillingData)
    .then(response => {
      dispatch({ type: types.GET_PATIENT_BILLING_DATA.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_PATIENT_BILLING_DATA.FAIL, error })
      return Promise.reject(error)
    })

export const A_DeletePatientInvoice = patientBillingData => () =>
  finance
    .deletePatientInvoice(patientBillingData)
    .then(response => {
      return response
    })
    .catch(error => {
      return Promise.reject(error)
    })

export const A_StoreData = data => ({ type: types.STORE_DATA, data })

export const A_CreateNewInvoicePDF = invoiceDetails => dispatch =>
  finance
    .createNewInvoicePDF(invoiceDetails)
    .then(response => {
      dispatch({ type: types.CREATE_NEW_INVOICE_PDF.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.CREATE_NEW_INVOICE_PDF.FAIL, error })
      return Promise.reject(error)
    })

export const A_SetToDefaultValues = () => ({
  type: types.SET_TO_DEFAULT_VALUES,
})
