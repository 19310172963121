import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import TextInput from '../../../forms/Input'
import Button from '../../../Buttons'
import ModalHeader from '../../../Modal/Header'
import ModalFooter from '../../../Modal/Footer'
import VDefaultOpt from '../../../Library/Treatment/ViewTreatment/viewDefaultOption'
import ToolTip from '../../../Library/Treatment/ViewTreatment/ToolTip'
import FeedbackNotification from '../../../Feedback/FeedbackNotification'
import { A_SetProductSheet, A_GetEpharmProdList } from '../../../../actions'
import TreatmentOptionSearchResult from '../TreatmentOptionSearchResult/index'
import Loader from '../../../PatientData/Loader'

let currentPricingIDval = 0
let currentMetricIDval = 0

class CreateCustomTreatOption extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      treatmentName: this.props.viewingOption.title || '',
      nameValid: true,
      isCustom: this.props.isCustom,
      pricingArray: props.pricingArray,
      matricsArray: props.matricsArray,
      productsArray: props.productsArray,
      EpharmLinked: [],
      tobeLinkedProducts: [],
      searchProductResults: [],
      notificationMsg: false,
    }
    this.handleSaveTreatOption = this.handleSaveTreatOption.bind(this)
    this.handleAddPricing = this.handleAddPricing.bind(this)
    this.handleAddMatics = this.handleAddMatics.bind(this)
    this.deletePricing = this.deletePricing.bind(this)
    this.deleteMatrics = this.deleteMatrics.bind(this)
    this.deleteLinkedProd = this.deleteLinkedProd.bind(this)
    this.savePricingOption = this.savePricingOption.bind(this)
    this.saveMatricsOption = this.saveMatricsOption.bind(this)
    this.handleSaveDefaultPricing = this.handleSaveDefaultPricing.bind(this)
    this.handleEpharmaSearch = this.handleEpharmaSearch.bind(this)
    this.handleClickLinkEpharm = this.handleClickLinkEpharm.bind(this)
    this.handleSearchEpharm = this.handleSearchEpharm.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.pricingArray !== prevProps.pricingArray ||
      this.props.matricsArray !== prevProps.matricsArray
    ) {
      this.setState({
        pricingArray: this.props.pricingArray,
        matricsArray: this.props.matricsArray,
      })
    }

    if (
      !this.props.newTreat &&
      this.props.viewingOption !== prevProps.viewingOption
    ) {
      if (this.props.viewingOption.title !== prevProps.viewingOption.title) {
        this.setState({ treatmentName: this.props.viewingOption.title })
      }
      if (this.props.viewingOption.products !== this.state.tobeLinkedProducts) {
        this.setState({ tobeLinkedProducts: this.props.viewingOption.products })
      }
      this.setState({ searchProductResults: [] })
    }
  }

  componentDidMount() {
    if (
      this.props.viewingOption &&
      this.props.viewingOption.products &&
      this.props.viewingOption.products.length > 0 &&
      !this.props.newTreat
    ) {
      this.setState({ tobeLinkedProducts: this.props.viewingOption.products })
    }
  }

  handleClick = e => {
    if (e.target.innerText === 'Cancel') {
      this.handleModalClose()
    } else {
      this.handleSaveTreatOption(e)
    }
  }

  onNameChange = event => {
    const name = event.target.value
    this.setState({ treatmentName: name })
  }

  handleSaveTreatOption(e) {
    e.preventDefault()
    if (!this.props.isDefaultTreatment) {
      let OptionName = document.getElementById('treatmentOptionName')
      if (!OptionName.value) {
        this.setState({ nameValid: false })
        return
      }
      this.setState({ nameValid: true })
      /**
       *  boolean below represents the default treatment option
       */

      if (this.props.newTreat) {
        this.props.saveTreatmentOptions(
          e,
          OptionName.value,
          this.state.pricingArray,
          this.state.matricsArray,
          false,
          this.state.tobeLinkedProducts,
          true,
        )
      } else {
        this.props.saveTreatmentOptions(
          e,
          OptionName.value,
          this.state.pricingArray,
          this.state.matricsArray,
          false,
          this.state.tobeLinkedProducts,
          false,
        )
      }
    } else {
      this.props.saveTreatmentOptions(e, '', this.state.pricingArray, '', true)
    }

    //** clear up searched results and linked products

    this.setState({ tobeLinkedProducts: [], searchProductResults: [] })
  }

  handleAddPricing() {
    if (this.props.newTreat) {
      currentPricingIDval = currentPricingIDval + 1
      this.setState({
        pricingArray: [
          ...this.state.pricingArray,
          { id: currentPricingIDval, price: '' },
        ],
      })
    } else {
      let length
      if (this.state.pricingArray.length === 0) {
        const newPriceArray = [{ id: 0, price: '' }]
        length = newPriceArray.length - 1
        currentPricingIDval = newPriceArray[length].id + 1
      } else {
        length = this.state.pricingArray.length - 1
        currentPricingIDval = this.state.pricingArray[length].id + 1
      }
      this.setState({
        pricingArray: [
          ...this.state.pricingArray,
          { id: currentPricingIDval, price: '' },
        ],
      })
    }
  }

  handleSearchEpharm = () => {
    let search_value = document.getElementsByName('EpharmProdName')[0].value
    this.handleEpharmaSearch(search_value)
  }

  deleteLinkedProd = id => {
    let prodList = this.state.tobeLinkedProducts
    let filteredProdList = []
    if (this.props.newTreat) {
      filteredProdList = prodList.filter(ele => {
        return ele.id !== id
      })
    } else {
      filteredProdList = prodList.filter(ele => {
        return ele.product_id !== id
      })
    }

    this.setState({
      tobeLinkedProducts: filteredProdList,
      searchProductResults: [],
    })
  }

  handleClickLinkEpharm = product => {
    let products = this.state.tobeLinkedProducts
    let productsFound = products.filter(item => {
      return this.props.newTreat
        ? item.id === product.id
        : item.product_id === product.id
    })
    if (productsFound.length === 0) {
      product.isAlreadyLinked = true
      products = [...this.state.tobeLinkedProducts, product]
      this.setState({
        tobeLinkedProducts: products,
        notificationMsg: 'Product Linked Successfully',
      })
      setTimeout(() => {
        this.setState({ notificationMsg: false })
      }, 2000)
    }
  }

  handleEpharmaSearch = search_value => {
    if (search_value === '' || search_value === undefined) {
      return
    }

    this.setState({ loader: true, searchProductResults: [] })

    const { actions, global } = this.props

    let clinic = global.currentClinicID

    actions.A_GetEpharmProdList(clinic, search_value).then(response => {
      if (this.state.tobeLinkedProducts.length > 0) {
        this.state.tobeLinkedProducts.forEach(linkedProd => {
          let isLinked = response.products.filter(prod => {
            return this.props.newTreat
              ? linkedProd.id === prod.id
              : linkedProd.product_id === prod.id
          })
          if (isLinked.length > 0) {
            isLinked[0].isAlreadyLinked = true
          }
        })
      }
      this.setState({ loader: false })
      this.setState({ searchProductResults: response.products })
    })
  }

  handleAddMatics() {
    if (this.props.newTreat) {
      currentMetricIDval = currentMetricIDval + 1
      this.setState({
        matricsArray: [
          ...this.state.matricsArray,
          { id: currentMetricIDval, title: '', placeholder: '' },
        ],
      })
    } else {
      let length
      if (this.state.matricsArray.length === 0) {
        const newMatArray = [{ id: 0, title: '', placeholder: '' }]
        length = newMatArray.length - 1
        currentMetricIDval = newMatArray[length].id + 1
      } else {
        length = this.state.matricsArray.length - 1
        currentMetricIDval = this.state.matricsArray[length].id + 1
      }

      this.setState({
        matricsArray: [
          ...this.state.matricsArray,
          { id: currentMetricIDval, title: '', placeholder: '' },
        ],
      })
    }
  }

  resetValues = () => {
    let OptionName = document.getElementById('treatmentOptionName')
    let linkedOptionName = document.getElementById('treatmentName')
    if (OptionName) {
      OptionName.value = ''
    }
    if (linkedOptionName) {
      linkedOptionName.value = ''
    }
  }

  handleModalClose = () => {
    this.props.handleClose()
  }

  savePricingOption(e, index) {
    let result = [...this.state.pricingArray]
    let length = this.state.pricingArray.length
    if (e.target.name.indexOf('Name') > -1) {
      for (var i = 0; i < length; i++) {
        if (result[i].id === index) {
          result[i].title = e.target.value
        }
        this.setState({ pricingArray: result })
      }
    } else {
      for (var j = 0; j < length; j++) {
        if (result[j].id === index) {
          result[j].price = e.target.value
        }
        this.setState({ pricingArray: result })
      }
    }
  }

  saveMatricsOption(e, index) {
    let result = [...this.state.matricsArray]
    let length = this.state.matricsArray.length
    if (e.target.name.indexOf('Name') > -1) {
      for (var i = 0; i < length; i++) {
        if (result[i].id === index) {
          result[i].title = e.target.value
        }
        this.setState({ matricsArray: result })
      }
    } else {
      for (var j = 0; j < length; j++) {
        if (result[j].id === index) {
          result[j].placeholder = e.target.value
        }
        this.setState({ matricsArray: result })
      }
    }
  }

  deletePricing(id, element) {
    let pricingArray = this.state.pricingArray
    let filteredArray = pricingArray.filter(ele => {
      return ele.id !== element.id
    })
    currentPricingIDval = currentPricingIDval - 1
    this.setState({ pricingArray: filteredArray })
  }
  handleSaveDefaultPricing(pricingArray) {
    this.setState({ pricingArray: pricingArray })
  }

  deleteMatrics(index, element) {
    let matrics = this.state.matricsArray
    let filteredMatArray = matrics.filter(elem => {
      return elem.id !== element.id
    })
    currentMetricIDval = currentMetricIDval - 1
    this.setState({ matricsArray: filteredMatArray })
  }

  render() {
    const title = this.props.newTreat
      ? 'New treatment option'
      : 'Edit treatment option'
    const nameError = !this.state.nameValid ? 'Required' : ''
    const {
      global: { currentClinic },
    } = this.props

    return (
      <React.Fragment>
        <article
          id="treatmentOptionModal"
          className="modal modal--overlay col__12-7 fadeInFlyAnimate"
          style={{ display: 'flex' }}
        >
          <form
            method="post"
            noValidate
            className="modal_flex_content"
            key={
              this.props.newTreat
                ? 'newTreatmentOption'
                : this.props.viewingOption.id
            }
          >
            <ModalHeader
              title={title}
              allowEdit={this.props.isDefaultTreatment}
              closeHandler={this.handleModalClose}
            />
            {!this.props.isDefaultTreatment || this.props.newTreat ? (
              <section className="modal__body modal__body--form">
                <section className="form__group--topStackable">
                  <section className="tableColumn-2Icon-1 tableCell">
                    <TextInput
                      label="Treatment option name"
                      value={this.state.treatmentName}
                      error={nameError}
                      placeholder="E.g. Botox 50"
                      noMargin={true}
                      type="text"
                      id="treatmentOptionName"
                      handleOnChange={this.onNameChange}
                    />
                  </section>
                </section>
                <section className="form__group--topStackable">
                  <h4 className="h3">Pricing</h4>
                  <p className="p">
                    The different amounts a patient could be charged for this
                    treatment option in your clinic
                  </p>
                  <div className="form__group--halfWidth__panel">
                    <div className="form__group--halfWidth">
                      <article className="table--withMargin">
                        <div className="tableRow tableHeader">
                          <section className="tableColumn-2Icon-1 tableCell tableHeadCell">
                            Price
                          </section>
                        </div>
                        {this.props.newTreat
                          ? this.state.pricingArray.map((price, index) => {
                              return (
                                <div
                                  className="tableRow"
                                  key={`price_${price.id}`}
                                >
                                  <section className="tableColumn-1Icon-1 tableCell">
                                    <TextInput
                                      type="number"
                                      id="pricingInput"
                                      name={'priceUnit' + index}
                                      value={price.price}
                                      noMargin={true}
                                      currencyIcon={
                                        currentClinic.currencySymbol
                                      }
                                      onChange={e =>
                                        this.savePricingOption(e, index)
                                      }
                                    />
                                  </section>
                                  <section className="tableColumn-6Icon-1 tableCell">
                                    <button
                                      className="icon-cross buttonCircle buttonCircle--card modal__closeButton modal__closeAction"
                                      onClick={() =>
                                        this.deletePricing(index, price)
                                      }
                                    />
                                  </section>
                                </div>
                              )
                            })
                          : this.state.pricingArray.map(ele => {
                              return (
                                <div
                                  className="tableRow"
                                  id={ele.id}
                                  key={ele.id}
                                >
                                  <section className="tableColumn-1Icon-1 tableCell">
                                    <TextInput
                                      type="number"
                                      id="pricingInput"
                                      name={'priceUnit' + ele.id}
                                      defaultValue={ele.price}
                                      noMargin={true}
                                      onChange={e =>
                                        this.savePricingOption(e, ele.id)
                                      }
                                    />
                                  </section>
                                  <section className="tableColumn-6Icon-1 tableCell">
                                    <button
                                      className="icon-cross buttonCircle buttonCircle--card modal__closeButton modal__closeAction"
                                      onClick={() =>
                                        this.deletePricing(ele.id, ele)
                                      }
                                    />
                                  </section>
                                </div>
                              )
                            })}
                        <div className="tableRow tableFooter">
                          <button
                            className="textLink textLink--button"
                            type="button"
                            onClick={this.handleAddPricing}
                          >
                            <span className="icon-add" />
                            New pricing option
                          </button>
                        </div>
                      </article>
                    </div>
                  </div>
                </section>
                <section className="form__group--stackable">
                  <h4 className="h3">Treatment metrics</h4>
                  <p className="p">
                    A scale that shows how much treatment was given. E.g. Botox
                    would be measured in units
                  </p>
                  <article className="table--withMargin">
                    <div className="tableRow tableHeader">
                      <section className="tableColumn-2Icon-1 tableCell tableHeadCell">
                        METRIC NAME
                      </section>
                      <section className="tableColumn-2Icon-1 tableCell tableHeadCell">
                        EXAMPLE
                      </section>
                    </div>
                    {this.props.newTreat
                      ? this.state.matricsArray.map((mat, index) => {
                          return (
                            <div className="tableRow" key={`metric_${mat.id}`}>
                              <section className="tableColumn-2Icon-1 tableCell">
                                <TextInput
                                  type="text"
                                  id="metricInput"
                                  name={'metricName' + index}
                                  noMargin={true}
                                  value={mat.title}
                                  placeholder="Units"
                                  onChange={e =>
                                    this.saveMatricsOption(e, index)
                                  }
                                />
                              </section>
                              <section className="tableColumn-2Icon-1 tableCell">
                                <TextInput
                                  type="text"
                                  id="metricUnit"
                                  name={'metricUnit' + index}
                                  value={mat.placeholder}
                                  noMargin={true}
                                  placeholder="1"
                                  onChange={e =>
                                    this.saveMatricsOption(e, index)
                                  }
                                />
                              </section>
                              <section className="tableColumn-6Icon-1 tableCell">
                                <button
                                  className="icon-cross buttonCircle buttonCircle--card modal__closeButton modal__closeAction"
                                  onClick={() => this.deleteMatrics(index, mat)}
                                />
                              </section>
                            </div>
                          )
                        })
                      : this.state.matricsArray.map(matric => {
                          return (
                            <div className="tableRow" key={matric.id}>
                              <section className="tableColumn-2Icon-1 tableCell">
                                <TextInput
                                  type="text"
                                  id="metricInput"
                                  name={'metricName' + matric.id}
                                  noMargin={true}
                                  placeholder="Units"
                                  defaultValue={matric.title}
                                  onChange={e =>
                                    this.saveMatricsOption(e, matric.id)
                                  }
                                />
                              </section>
                              <section className="tableColumn-2Icon-1 tableCell">
                                <TextInput
                                  type="text"
                                  id="metricUnit"
                                  name={'metricUnit' + matric.is}
                                  noMargin={true}
                                  placeholder="1"
                                  defaultValue={matric.placeholder}
                                  onChange={e =>
                                    this.saveMatricsOption(e, matric.id)
                                  }
                                />
                              </section>
                              <section className="tableColumn-6Icon-1 tableCell">
                                <button
                                  className="icon-cross buttonCircle buttonCircle--card modal__closeButton modal__closeAction"
                                  onClick={() =>
                                    this.deleteMatrics(matric.id, matric)
                                  }
                                />
                              </section>
                            </div>
                          )
                        })}
                    <div className="tableRow tableFooter">
                      <button
                        className="textLink textLink--button"
                        type="button"
                        onClick={this.handleAddMatics}
                      >
                        <span className="icon-add" />
                        New metric
                      </button>
                    </div>
                  </article>
                </section>
                <section className="form__group--stackable">
                  <span className="treatmentItem__section treatmentItem__header">
                    <h4 className="h3">Linked products</h4>
                    <span className="treatmentItem__header__editStatus">
                      &nbsp; - Optional
                    </span>
                  </span>
                  <p className="p">
                    Healthxchange products typically used for this treatment.
                    These populate the ‘Prescription/Order’ stage of patient
                    consultation
                  </p>
                  <article className="table--withMargin">
                    <div className="tableRow tableHeader">
                      <section className="tableColumn-2Icon-1 tableCell tableHeadCell">
                        Product Description
                      </section>
                      <section className="tableColumn-2Icon-1 tableCell tableHeadCell">
                        Sage stock code
                      </section>
                    </div>
                    {this.state.tobeLinkedProducts.map(prod => {
                      return (
                        <div key={prod.product_id} className="tableRow">
                          <section className="col__12-6">
                            <span>
                              {prod.product_description
                                ? prod.product_description
                                : prod.description
                                ? prod.description
                                : null}
                            </span>
                          </section>
                          <section className="col__12-6">
                            <span>
                              {prod.product_sageStock_Code
                                ? prod.product_sageStock_Code
                                : prod.sageStock_Code
                                ? prod.sageStock_Code
                                : null}
                            </span>
                          </section>
                          <section className="tableColumn-6Icon-1 tableCell">
                            <button
                              className="icon-cross buttonCircle buttonCircle--card modal__closeButton modal__closeAction"
                              onClick={() =>
                                this.deleteLinkedProd(prod.product_id, prod)
                              }
                            />
                          </section>
                        </div>
                      )
                    })}
                  </article>
                </section>
                <section className="form__group--stackable primaryMarginBottom">
                  <section className="tableColumn-2Icon-1 tableCell">
                    <TextInput
                      leftIcon="icon-magnifying-glass"
                      label="Add linked product"
                      placeholder="Product name"
                      type="text"
                      id="treatmentName"
                      name="EpharmProdName"
                    />
                  </section>
                  <Button
                    styleType="tertiary"
                    size="small"
                    label="Search Healthxchange Group"
                    clickHandler={this.handleSearchEpharm}
                  />
                </section>

                {this.state.loader ? (
                  <Loader text="searching products" />
                ) : null}

                {/** Search Results over here */

                this.state.searchProductResults &&
                this.state.searchProductResults.length > 0
                  ? this.state.searchProductResults.map(prod => {
                      return (
                        <React.Fragment key={prod.id}>
                          <TreatmentOptionSearchResult
                            clickHandler={this.handleClickLinkEpharm}
                            product={prod}
                          />
                        </React.Fragment>
                      )
                    })
                  : null}
              </section>
            ) : (
              <VDefaultOpt
                title={this.props.treatments.viewingTreatment.title}
                pricingArray={this.state.pricingArray}
                matricsArray={this.state.matricsArray}
                productsArray={this.state.productsArray}
                pricings={this.props.treatments}
                saveDefaultPricing={this.handleSaveDefaultPricing}
                currencyIcon={currentClinic.currencySymbol}
              />
            )}

            <ModalFooter
              rightButtons={this.props.footerButtons}
              leftButtons={this.props.leftFooterButton}
              closeHandler={() => this.handleModalClose()}
              clickHandler={e => this.handleClick(e)}
              onKeyPress={this.onButtonClick}
            >
              <ToolTip
                handleDelete={
                  this.props.isDefaultTreatment
                    ? () => {}
                    : this.props.handleDelete
                }
                handleCancel={this.handleModalClose}
                titleHeader={this.props.toolTipHeader}
                titleBody={this.props.toolTipBody}
              />
            </ModalFooter>
            {this.state.notificationMsg ? (
              <FeedbackNotification
                id="feedbackMessage"
                message={this.state.notificationMsg}
                isWarning={false}
              />
            ) : null}
          </form>
        </article>
        <section
          className="overlay fadeInAnimate"
          style={{ display: 'block' }}
        />
      </React.Fragment>
    )
  }
}

CreateCustomTreatOption.defaultProps = {
  newTreat: true,
  footerButtons: [],
  handleClose: () => {},
  isCustom: false,
  pricingArray: [],
  matricsArray: [],
  productsArray: [],
  handleDelete: () => {},
  toolTipHeader: '',
  toolTipBody: '',
  isDefaultTreatment: false,
  saveTreatmentOptions: () => {},
  leftFooterButton: [],
  viewingOption: { id: 0 },
}

CreateCustomTreatOption.propTypes = {
  actions: PropTypes.object.isRequired,
  newTreat: PropTypes.bool,
  treatments: PropTypes.object.isRequired,
  footerButtons: PropTypes.array,
  handleClose: PropTypes.func,
  isCustom: PropTypes.bool,
  pricingArray: PropTypes.array,
  matricsArray: PropTypes.array,
  productsArray: PropTypes.array,
  handleDelete: PropTypes.func,
  toolTipHeader: PropTypes.string,
  toolTipBody: PropTypes.string,
  isDefaultTreatment: PropTypes.bool,
  saveTreatmentOptions: PropTypes.func,
  leftFooterButton: PropTypes.array,
  viewingOption: PropTypes.object,
  global: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    treatments: state.treatments,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_SetProductSheet,
        A_GetEpharmProdList,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateCustomTreatOption),
)
