import { getCountries } from 'libphonenumber-js'

export const userTypeIds = {
  CLINICIAN: 1,
  NON_CLINICAL: 2,
}
export const userTypes = {
  CLINICIAN: 'Clinical',
  NON_CLINICAL: 'Non-clinical',
}
export const userTypeNames = {
  1: 'Clinical',
  2: 'Non-clinical',
}

export const userRoles = [
  {
    id: 1,
    title: 'Clinical',
  },
  {
    id: 2,
    title: 'Non-clinical',
  },
]

export const userJobsClinical = [
  {
    id: 0,
    title: 'Practitioner',
  },
  {
    id: 1,
    title: 'Nurse',
  },
  {
    id: 2,
    title: 'Aesthetic Practitioner',
  },
  {
    id: 3,
    title: 'Therapist',
  },
  {
    id: 4,
    title: 'Dentist',
  },
  {
    id: 5,
    title: 'Surgeon',
  },
  {
    id: 6,
    title: 'Other',
  },
]

export const userJobsNonClinical = [
  {
    id: 7,
    title: 'Secretary',
  },
  {
    id: 8,
    title: 'Clinic Administrator',
  },
  {
    id: 9,
    title: 'Accountant',
  },
  {
    id: 10,
    title: 'Personal Assistant',
  },
]

export const jobTypeIds = {
  PRACTITIONER: 0,
  NURSE: 1,
  AESTHETIC_PRACTITIONER: 2,
  THERAPIST: 3,
  DENTIST: 4,
  SURGEON: 5,
  OTHER: 6,
  SECRETARY: 7,
  PERSONAL_ASSISTANT: 8,
  ACCOUNTS_JOB: 9,
  ADMIN_JOB: 10,
}
export const jobTypes = {
  PRACTITIONER: 'Practitioner',
  NURSE: 'Nurse',
  AESTHETIC_PRACTITIONER: 'Aesthetic Practitioner',
  THERAPIST: 'Therapist',
  DENTIST: 'Dentist',
  SURGEON: 'Surgeon',
  OTHER: 'Other',
  SECRETARY: 'Secretary',
  PERSONAL_ASSISTANT: 'Personal Assistant',
  ACCOUNTS_JOB: 'Accountant',
  ADMIN_JOB: 'Clinic administrator',
}
export const jobTypeNames = {
  0: 'Practitioner',
  1: 'Nurse',
  2: 'Aesthetic Practitioner',
  3: 'Therapist',
  4: 'Dentist',
  5: 'Surgeon',
  6: 'Other',
  7: 'Secretary',
  8: 'Personal Assistant',
  9: 'Accountant',
  10: 'Clinic administrator',
}

export const defaultClinicHours = [
  { id: '2', day: 2, opens: '09:00:00', closes: '17:00:00', is_open: true },
  { id: '3', day: 3, opens: '09:00:00', closes: '17:00:00', is_open: true },
  { id: '4', day: 4, opens: '09:00:00', closes: '17:00:00', is_open: true },
  { id: '5', day: 5, opens: '09:00:00', closes: '17:00:00', is_open: true },
  { id: '6', day: 6, opens: '09:00:00', closes: '17:00:00', is_open: true },
  { id: '7', day: 7, opens: '09:00:00', closes: '17:00:00', is_open: false },
  { id: '1', day: 1, opens: '09:00:00', closes: '17:00:00', is_open: false },
]

export const appointmentModalTypes = {
  CREATE: 'create',
  EDIT: 'edit',
  VIEW: 'view',
}

export const appointmentReasonIds = {
  APPOINTMENT: 0,
  MEETING: 1,
  PERSONAL: 2,
}

export const appointmentReasonTitles = {
  APPOINTMENT: 'Appointment',
  MEETING: 'Meeting',
  PERSONAL: 'Personal',
}

export const appointmentPurposeIds = {
  NEW: 0,
  REPEAT: 1,
  REVIEW: 2,
}

export const appointmentPurposeTitles = {
  NEW: 'New',
  REPEAT: 'Repeat',
  REVIEW: 'Review',
}

export const appointmentStatusIds = {
  CANCELLED: -2,
  NO_SHOW: -1,
  PENDING: 0,
  PATIENT_ARRIVED: 1,
  READY_FOR_PATIENT: 2,
  ACTIVE: 3,
  COMPLETE: 4,
}

export const appointmentStatusTitles = {
  CANCELLED: 'Cancelled',
  NO_SHOW: 'No Show',
  PENDING: 'Pending',
  PATIENT_ARRIVED: 'Patient Arrived',
  READY_FOR_PATIENT: 'Ready For Patient',
  ACTIVE: 'Active',
  COMPLETE: 'Complete',
}

export const consultationStatusIds = {
  REFUSED_CONSENT: -1,
  IDENTIFIED: 0,
  DEFERRED: 1,
  REFERRED: 2,
}

export const consultationStatusTitles = {
  REFUSED_CONSENT: 'Refused consent',
  IDENTIFIED: 'Completed',
  DEFERRED: 'Deferred',
  REFERRED: 'Referred',
}

export const scheduleOptions = {
  NONE: -1,
  LOCATION: 0,
  HOLIDAY: 1,
  UNAVAILABLE: 2,
}
export const scheduleReasons = {
  NONE: 'None',
  LOCATION: 'Location',
  HOLIDAY: 'Holiday',
  UNAVAILABLE: 'Unavailable',
}

export const scheduleDayIds = {
  MONDAY: 2,
  TUESDAY: 3,
  WEDNESDAY: 4,
  THURSDAY: 5,
  FRIDAY: 6,
  SATURDAY: 7,
  SUNDAY: 1,
}
export const scheduleDaysShort = {
  MONDAY: 'MO',
  TUESDAY: 'TU',
  WEDNESDAY: 'WE',
  THURSDAY: 'TH',
  FRIDAY: 'FR',
  SATURDAY: 'SA',
  SUNDAY: 'SU',
}
export const scheduleDays = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
}

export const scheduleRecurrenceIds = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  HOUR: 3,
  MINUTE: 4,
}
export const scheduleRecurrences = {
  DAY: 'Day(s)',
  WEEK: 'Week(s)',
  MONTH: 'Month(s)',
  HOUR: 'Hour(s)',
  MINUTE: 'Minute(s)',
}

export const monthList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const genderIds = {
  NOT_SPECIFIED: 0,
  MALE: 1,
  FEMALE: 2,
  NON_BINARY: 3,
}

export const genderTitles = {
  NON_BINARY: 'Non-binary',
  NOT_SPECIFIED: 'Not Specified',
  MALE: 'Male',
  FEMALE: 'Female',
}

export const genderFilterIds = {
  SHOW_ALL: '',
  ...genderIds,
}

export const genderFilterTitles = {
  SHOW_ALL: 'Show all',
  ...genderTitles,
}

export const maritalStatusIds = {
  NOT_SPECIFIED: 0,
  MARRIED: 1,
  WIDOWED: 2,
  SEPARATED: 3,
  DIVORCED: 4,
  SINGLE: 5,
}
export const maritalStatusTitles = {
  NOT_SPECIFIED: 'Not Specified',
  MARRIED: 'Married',
  WIDOWED: 'Widowed',
  SEPARATED: 'Separated',
  DIVORCED: 'Divorced',
  SINGLE: 'Single',
}

export const preferredTitleIds = {
  NOT_SPECIFIED: 0,
  MR: 1,
  MISS: 2,
  MRS: 3,
  MS: 4,
  DR: 5,
}
export const preferredTitleTitles = {
  NOT_SPECIFIED: 'Not Specified',
  MR: 'Mr',
  MISS: 'Miss',
  MRS: 'Mrs',
  MS: 'Ms',
  DR: 'Dr',
}

export const contactMethodIds = {
  NO_PERMISSION: 0,
  MOBILE_PHONE: 1,
  HOME_PHONE: 2,
  WORK_PHONE: 3,
  EMAIL: 4,
  POST: 5,
}
export const contactMethodTitles = {
  NO_PERMISSION: 'No permission',
  MOBILE_PHONE: 'Mobile',
  HOME_PHONE: 'Home',
  WORK_PHONE: 'Work',
  EMAIL: 'Email',
  POST: 'Post',
}

export const contactMethodGroupIds = {
  PHONE: 1,
  SMS: 2,
  EMAIL: 3,
}

export const contactMethodGroupTitles = {
  PHONE: 'Phone',
  EMAIL: 'Email',
  SMS: 'SMS',
}

export const howDidYouHearAboutUsIds = {
  NOT_SPECIFIED: 0,
  DR_REFERRAL: 12,
  WEBSITE: 1,
  GOOGLE: 2,
  FACEBOOK: 3,
  INSTAGRAM: 4,
  TWITTER: 5,
  NEWSPAPER: 6,
  MAGAZINE: 7,
  RADIO: 8,
  TV: 9,
  FRIEND_FAMILY: 10,
  WORD_OF_MOUTH: 13,
  OTHER: 11,
}

export const howDidYouHearAboutUsTitles = {
  NOT_SPECIFIED: 'Not Specified',
  DR_REFERRAL: 'Dr Referral',
  WEBSITE: 'Website',
  GOOGLE: 'Google',
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  TWITTER: 'Twitter',
  NEWSPAPER: 'Newspaper Advert',
  MAGAZINE: 'Magazine Advert',
  RADIO: 'Radio Ad',
  TV: 'TV',
  FRIEND_FAMILY: 'Friend/Family',
  WORD_OF_MOUTH: 'Word of Mouth',
  OTHER: 'Other',
}

export const contactMethodGroupValues = {
  PHONE: [1, 2, 3],
  EMAIL: [4],
  POST: [5],
}

export const postcodeRegex = /^([g][i][r][0][a][a])$|^((([a-pr-uwyz]{1}([0]|[1-9]\d?))|([a-pr-uwyz]{1}[a-hk-y]{1}([0]|[1-9]\d?))|([a-pr-uwyz]{1}[1-9][a-hjkps-uw]{1})|([a-pr-uwyz]{1}[a-hk-y]{1}[1-9][a-z]{1}))(\d[abd-hjlnp-uw-z]{2})?)$/i
export const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i //eslint-disable-line
export const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^/!%*;+=:"'-.<>_?,|(){}[\]&])[A-Za-z\d@#$%^/!%*;+=:"'-.<>_?,|(){}[\]&]{6,}$/i
// eslint-disable-next-line no-useless-escape
export const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i
export const postcodeErrorMessage = 'Please enter a valid postcode'
export const postcodeNotFoundMessage =
  'This postcode was not found. Please check it and try again, or enter manually below'
export const emailErrorMessage = 'Please enter a valid email address'
export const phoneErrorMessage = 'Please enter a valid phone number'
export const clinicWeek = [2, 3, 4, 5, 6, 7, 1]
export const appointmentLengthArray = [5, 10, 15, 20, 30, 40, 50, 60]
export const clinicTimeFormatError =
  'Please enter a valid opening and closing time in the format HH:MM'
export const clinicTimeSlotError =
  'Closing time should be later than opening time'
export const shedulerTimeSlotError =
  'End date must be the same as or after the start date'
//// Search nodes ////

export const patientSearchNodes = [
  {
    title: 'PATIENT',
    type: 'data',
    value: 'id',
    priority: 'tertiary',
    width: '8',
    searchField: false,
  },
  {
    title: 'NAME',
    type: 'func',
    value: 'getFullName',
    priority: 'primary',
    width: '23',
    searchField: true,
  },
  {
    title: 'DOB',
    type: 'func',
    value: 'formatFullYearDOB',
    priority: 'secondary',
    width: '10',
    searchField: false,
  },
  {
    title: 'MOBILE',
    priority: 'secondary',
    type: 'data',
    value: 'mobile_phone',
    width: '12',
    searchField: false,
  },
  {
    title: 'HOME PHONE',
    priority: 'secondary',
    type: 'data',
    value: 'home_phone',
    width: '12',
    searchField: false,
  },
  {
    title: 'ADDRESS',
    type: 'func',
    value: 'address',
    priority: 'secondary',
    width: '35',
    searchField: false,
  },
]

//// Staff search node ////

export const staffSearchNodes = [
  {
    title: 'NAME',
    type: 'func',
    value: 'clinicianName',
    priority: 'primary',
    width: '40',
    searchField: true,
  },
  {
    title: 'USER ROLE',
    type: 'func',
    value: 'user_type',
    priority: 'primary',
    width: '20',
    searchField: false,
  },
  {
    title: 'JOB ROLE',
    type: 'func',
    value: 'job_type',
    priority: 'primary',
    width: '25',
    searchField: false,
  },
  {
    title: 'ADMIN',
    type: 'func',
    value: 'is_admin',
    priority: 'secondary',
    width: '15',
    searchField: false,
  },
]

export const patientDocumentSearchNodes = [
  {
    title: 'DATE',
    type: 'func',
    value: 'created_at',
    priority: 'tertiary',
    width: '8',
    searchField: false,
  },
  {
    title: 'DOCUMENT',
    type: 'func',
    value: 'file',
    priority: 'primary',
    width: '30',
    searchField: true,
  },
  {
    title: 'SIZE',
    type: 'func',
    value: 'file_size',
    priority: 'secondary',
    width: '8',
    searchField: false,
  },
  {
    title: 'UPLOADER',
    type: 'func',
    value: 'created_by',
    priority: 'secondary',
    width: '12',
    searchField: false,
  },
  {
    title: 'DOCUMENT TYPE',
    type: 'data',
    value: 'document_type',
    priority: 'secondary',
    width: '12',
    searchField: true,
  },
  {
    title: 'DOCUMENT DESCRIPTION',
    type: 'data',
    value: 'document_description',
    priority: 'secondary',
    width: '30',
    searchField: true,
  },
]

export const staffTableHeader = [
  {
    title: 'STAFF MEMBER',
    width: '40',
    value: 'email',
  },
  {
    title: 'USER ROLE',
    width: '20',
    value: 'role_type',
  },
  {
    title: 'JOB TITLE',
    width: '40',
    value: 'job_type',
  },
]

export const staffEditTableHeader = [
  {
    title: 'STAFF MEMBER',
    width: '40',
    value: 'email',
  },
  {
    title: 'USER ROLE',
    width: '20',
    value: 'role_type',
  },
  {
    title: 'JOB TITLE',
    width: '25',
    value: 'job_type',
  },
  {
    title: 'ADMIN',
    width: '15',
    value: 'is_admin',
  },
]

export const staffClinicViewTableHeader = [
  {
    title: 'STAFF MEMBER',
    width: '40',
    value: 'email',
  },
  {
    title: 'USER ROLE',
    width: '20',
    value: 'role_type',
  },
  {
    title: 'JOB TITLE',
    width: '15',
    value: 'job_type',
  },
  {
    title: 'ADMIN',
    width: '25',
    value: 'is_admin',
  },
]

export const appointmentLengths = [...Array(12).keys()].map(index => {
  const minutes = index * 5
  return { id: minutes, title: `${minutes}mins` }
})
export const appointmentHours = [...Array(9).keys()].map(hours => {
  return { id: hours * 60, title: `${hours}hrs` }
})

export const reportStartHeader = [
  {
    title: 'PATIENT ID',
    width: '90',
    priority: 'tertiary',
    type: 'data',
    value: 'id',
  },
  {
    title: 'PATIENT NAME',
    width: '140',
    priority: 'tertiary',
    type: 'data',
    value: 'patient_name',
  },
  {
    title: 'GENDER',
    width: '110',
    priority: 'tertiary',
    type: 'data',
    value: 'gender',
  },
  {
    title: 'CLINIC',
    width: '160',
    priority: 'primary',
    type: 'data',
    value: 'clinic',
  },
  {
    title: 'STAFF MEMBER',
    width: '140',
    priority: 'primary',
    type: 'data',
    value: 'clinician',
  },
  {
    title: 'DATE',
    width: '100',
    priority: 'secondary',
    type: 'data',
    value: 'date',
  },
  {
    title: 'TIME',
    width: '70',
    priority: 'secondary',
    type: 'data',
    value: 'time',
  },
  {
    title: 'APPOINTMENT TYPE',
    width: '160',
    priority: 'secondary',
    type: 'data',
    value: 'appointment_type',
  },
  {
    title: 'APPOINTMENT STATUS',
    width: '170',
    priority: 'secondary',
    type: 'data',
    value: 'appointment_status',
  },
  {
    title: 'CONCERN',
    width: '100',
    priority: 'secondary',
    type: 'data',
    value: 'concern',
  },
  {
    title: 'ANXIETY',
    width: '80',
    priority: 'secondary',
    type: 'data',
    value: 'anxiety_scale',
  },
  {
    title: 'DEPRESSION',
    width: '110',
    priority: 'secondary',
    type: 'data',
    value: 'depression_scale',
  },
  {
    title: 'BDD',
    width: '50',
    priority: 'secondary',
    type: 'data',
    value: 'bdd_scale',
  },
  {
    title: 'TREATMENT TYPE',
    width: '200',
    priority: 'secondary',
    type: 'data',
    value: 'treatment_type',
  },
  {
    title: 'TREATMENT',
    width: '200',
    priority: 'secondary',
    type: 'data',
    value: 'treatment',
  },
  {
    title: 'TREATMENT OPTION',
    width: '250',
    priority: 'secondary',
    type: 'data',
    value: 'treatment_option',
  },
  {
    title: 'INVOICE ID',
    width: '100',
    priority: 'secondary',
    type: 'data',
    value: 'invoice_id',
  },
  {
    title: 'ITEM SUB TOTAL',
    width: '150',
    priority: 'secondary',
    type: 'func',
    value: 'billLineSubTotalReport',
    footer: 0,
  },
  {
    title: 'ITEM DISCOUNT',
    width: '150',
    priority: 'secondary',
    type: 'func',
    value: 'billLineDiscReport',
    footer: 0,
  },
  {
    title: 'ITEM VAT',
    width: '110',
    priority: 'secondary',
    type: 'func',
    value: 'billLineVATReport',
    footer: 0,
  },
  {
    title: 'ITEM TOTAL',
    width: '120',
    priority: 'secondary',
    type: 'func',
    value: 'billLineTotalReport',
    footer: 0,
  },
]

export const reportAppointmentsStartHeader = [
  {
    title: 'PATIENT ID',
    width: '90',
    priority: 'tertiary',
    type: 'data',
    value: 'id',
  },
  {
    title: 'PATIENT NAME',
    width: '140',
    priority: 'tertiary',
    type: 'data',
    value: 'patient_name',
  },
  {
    title: 'GENDER',
    width: '110',
    priority: 'tertiary',
    type: 'data',
    value: 'gender',
  },
  {
    title: 'CLINIC',
    width: '160',
    priority: 'primary',
    type: 'data',
    value: 'clinic',
  },
  {
    title: 'STAFF MEMBER',
    width: '140',
    priority: 'primary',
    type: 'data',
    value: 'clinician',
  },
  {
    title: 'DATE',
    width: '100',
    priority: 'secondary',
    type: 'data',
    value: 'date',
  },
  {
    title: 'TIME',
    width: '70',
    priority: 'secondary',
    type: 'data',
    value: 'time',
  },
  {
    title: 'APPOINTMENT TYPE',
    width: '160',
    priority: 'secondary',
    type: 'data',
    value: 'appointment_type',
  },
  {
    title: 'APPOINTMENT STATUS',
    width: '170',
    priority: 'secondary',
    type: 'data',
    value: 'appointment_status',
  },
]

export const reportRecallStartHeader = [
  {
    title: 'PATIENT ID',
    width: '90',
    priority: 'tertiary',
    type: 'data',
    value: 'id',
  },
  {
    title: 'PATIENT NAME',
    width: '140',
    priority: 'tertiary',
    type: 'data',
    value: 'full_name',
  },
  {
    title: 'DATE OF BIRTH',
    width: '110',
    priority: 'tertiary',
    type: 'data',
    value: 'date_of_birth',
  },
  {
    title: 'GENDER',
    width: '110',
    priority: 'tertiary',
    type: 'data',
    value: 'gender_value',
  },
  {
    title: 'EMAIL',
    width: '200',
    priority: 'tertiary',
    type: 'data',
    value: 'email',
  },
  {
    title: 'CAN CONTACT VIA EMAIL',
    width: '200',
    priority: 'tertiary',
    type: 'data',
    value: 'can_send_email',
  },
  {
    title: 'MOBILE PHONE',
    width: '150',
    priority: 'tertiary',
    type: 'data',
    value: 'mobile_phone',
  },
  {
    title: 'CAN CONTACT VIA SMS',
    width: '200',
    priority: 'tertiary',
    type: 'data',
    value: 'can_send_sms',
  },
  {
    title: 'CLINIC',
    width: '160',
    priority: 'primary',
    type: 'data',
    value: 'clinic',
  },
  {
    title: 'STAFF MEMBER',
    width: '140',
    priority: 'primary',
    type: 'data',
    value: 'clinician',
  },
  {
    title: 'LAST CONSULTATION DATE',
    width: '100',
    priority: 'secondary',
    type: 'data',
    value: 'last_consultation_date',
  },
  {
    title: 'CONSULTATION TYPE',
    width: '160',
    priority: 'secondary',
    type: 'data',
    value: 'purpose',
  },
  {
    title: 'TREATMENT TYPE',
    width: '200',
    priority: 'secondary',
    type: 'data',
    value: 'treatment_type',
  },
  {
    title: 'TREATMENT',
    width: '200',
    priority: 'secondary',
    type: 'data',
    value: 'treatment',
  },
  {
    title: 'TREATMENT OPTION',
    width: '250',
    priority: 'secondary',
    type: 'data',
    value: 'treatment_option',
  },
  {
    title: 'NEXT APPOINTMENT DATE',
    width: '200',
    priority: 'secondary',
    type: 'data',
    value: 'appointment_date',
  },
  {
    title: 'NEXT APPOINTMENT TREATMENT TYPE',
    width: '300',
    priority: 'secondary',
    type: 'data',
    value: 'appointment_treatment_type',
  },
  {
    title: 'NEXT APPOINTMENT TREATMENT',
    width: '300',
    priority: 'secondary',
    type: 'data',
    value: 'appointment_treatment',
  },
]

export const aGraphColors = [
  'red',
  '#FF8C00',
  '#FFC300',
  '#3CB371',
  '#4169E1',
  '#007bae',
  '#BA55D3',
  '#DCDCDC',
]
export const fGraphColors = ['#3CB371', '#4169E1', '#DCDCDC']

export const productSearchNodes = [
  {
    title: 'Product Option',
    type: 'data',
    value: 'title',
    priority: 'primary',
    width: '40',
    searchField: true,
  },
  {
    title: 'Product',
    type: 'data',
    value: 'productTitle',
    priority: 'primary',
    width: '40',
    searchField: true,
  },
  {
    title: 'Price',
    type: 'data',
    value: 'productPriceTitle',
    priority: 'primary',
    width: '20',
    searchField: false,
  },
]

export const reportListcolumns = [
  {
    title: 'Patient',
    rows: [
      {
        checked: true,
        locked: true,
        title: 'Patient ID',
        heading: 'id',
      },
      {
        checked: true,
        locked: false,
        title: 'Patient name',
        heading: 'patient_name',
      },
      {
        checked: true,
        locked: false,
        title: 'Patient gender',
        heading: 'patient_gender',
      },
    ],
  },
  {
    title: 'User session',
    rows: [
      {
        checked: true,
        locked: false,
        title: 'Clinic',
        heading: 'clinic',
      },
      {
        checked: true,
        locked: false,
        title: 'Staff member',
        heading: 'clinician',
      },
    ],
  },
  {
    title: 'Appointment',
    rows: [
      {
        checked: true,
        locked: true,
        title: 'Date',
        heading: 'date',
      },
      {
        checked: true,
        locked: true,
        title: 'Time',
        heading: 'time',
      },
      {
        checked: true,
        locked: false,
        title: 'Appointment type',
        heading: 'appointment_type',
      },
      {
        checked: true,
        locked: false,
        title: 'Appointment status',
        heading: 'appointment_status',
      },
    ],
  },
  {
    title: 'Consultation',
    rows: [
      {
        checked: true,
        locked: false,
        title: 'Concern',
        heading: 'concern',
      },
      {
        checked: true,
        locked: false,
        title: 'Anxiety scale',
        heading: 'anxiety_scale',
      },
      {
        checked: true,
        locked: false,
        title: 'Depression scale',
        heading: 'depression_scale',
      },
      {
        checked: true,
        locked: false,
        title: 'BDD scale',
        heading: 'bdd_scale',
      },
    ],
  },
  {
    title: 'Treatment',
    rows: [
      {
        checked: true,
        locked: false,
        title: 'Treatment type',
        heading: 'treatment_type',
      },
      {
        checked: true,
        locked: false,
        title: 'Treatment',
        heading: 'treatment',
      },
      {
        checked: true,
        locked: false,
        title: 'Treatment option',
        heading: 'treatment_option',
      },
    ],
  },
  {
    title: 'Invoice',
    rows: [
      {
        checked: true,
        locked: false,
        title: 'Invoice ID',
        heading: 'invoice_id',
      },
    ],
  },
  {
    title: 'Bill Line Items',
    rows: [
      {
        checked: true,
        locked: false,
        title: 'Item discount',
        heading: 'billLineDiscReport',
      },
      {
        checked: true,
        locked: false,
        title: 'Item VAT',
        heading: 'billLineVATReport',
      },
      {
        checked: true,
        locked: false,
        title: 'Item sub total',
        heading: 'billLineSubTotalReport',
      },
      {
        checked: true,
        locked: false,
        title: 'Item total',
        heading: 'billLineTotalReport',
      },
    ],
  },
]

export const reportAppointmentsListcolumns = [
  {
    title: 'Patient',
    rows: [
      {
        checked: true,
        locked: true,
        title: 'Patient ID',
        heading: 'id',
      },
      {
        checked: true,
        locked: false,
        title: 'Patient name',
        heading: 'patient_name',
      },
      {
        checked: true,
        locked: false,
        title: 'Patient gender',
        heading: 'patient_gender',
      },
    ],
  },
  {
    title: 'User session',
    rows: [
      {
        checked: true,
        locked: false,
        title: 'Clinic',
        heading: 'clinic',
      },
      {
        checked: true,
        locked: false,
        title: 'Staff member',
        heading: 'clinician',
      },
    ],
  },
  {
    title: 'Appointment',
    rows: [
      {
        checked: true,
        locked: true,
        title: 'Date',
        heading: 'date',
      },
      {
        checked: true,
        locked: true,
        title: 'Time',
        heading: 'time',
      },
      {
        checked: true,
        locked: false,
        title: 'Appointment type',
        heading: 'appointment_type',
      },
      {
        checked: true,
        locked: false,
        title: 'Appointment status',
        heading: 'appointment_status',
      },
    ],
  },
]

export const dailyTransactionListcolumns = [
  {
    title: 'Patient',
    rows: [
      {
        checked: true,
        locked: false,
        title: 'Patient name',
        heading: 'patient',
      },
    ],
  },
  {
    title: 'Appointment',
    rows: [
      {
        checked: true,
        locked: false,
        title: 'Appointment Purpose',
        heading: 'purpose',
      },
    ],
  },
  {
    title: 'Invoice',
    rows: [
      {
        checked: true,
        locked: false,
        title: 'Treatment',
        heading: 'category',
      },
      {
        checked: true,
        locked: false,
        title: 'Treatment Options',
        heading: 'treatment',
      },
      {
        checked: true,
        locked: false,
        title: 'Inventory Items',
        heading: 'inventory',
      },
    ],
  },
  {
    title: 'Payment',
    rows: [
      {
        checked: true,
        locked: false,
        title: 'Clinic',
        heading: 'clinic',
      },
      {
        checked: true,
        locked: false,
        title: 'Clinician',
        heading: 'clinician',
      },
      {
        checked: true,
        locked: false,
        title: 'Taken By',
        heading: 'taken_by',
      },
      {
        checked: true,
        locked: false,
        title: 'Associated To',
        heading: 'associated_to',
      },
      {
        checked: true,
        locked: false,
        title: 'Payment Taken',
        heading: 'taken',
      },
      {
        checked: true,
        locked: false,
        title: 'Invoice Status',
        heading: 'invoice_status',
      },
      {
        checked: true,
        locked: false,
        title: 'Bill ID',
        heading: 'id',
      },
      {
        checked: true,
        locked: false,
        title: 'Total Treatments',
        heading: 'totalTreatments',
      },
      {
        checked: true,
        locked: false,
        title: 'Total Inventory',
        heading: 'totalInventory',
      },
      {
        checked: true,
        locked: false,
        title: 'Total VAT',
        heading: 'totalVAT',
      },
      {
        checked: true,
        locked: false,
        title: 'Invoice Total',
        heading: 'total',
      },
      {
        checked: true,
        locked: false,
        title: 'Amount Paid',
        heading: 'paid',
      },
      {
        checked: true,
        locked: false,
        title: 'Payment Type',
        heading: 'payment',
      },
      {
        checked: true,
        locked: false,
        title: 'Card Type',
        heading: 'card',
      },
      {
        checked: true,
        locked: false,
        title: 'Total due',
        heading: 'remaining',
      },
      {
        checked: true,
        locked: false,
        title: 'Notes?',
        heading: 'note',
      },
    ],
  },
]

export const reportRecallListcolumns = [
  {
    title: 'Patient',
    rows: [
      {
        checked: true,
        locked: true,
        title: 'Patient ID',
        heading: 'id',
      },
      {
        checked: true,
        locked: false,
        title: 'Patient name',
        heading: 'full_name',
      },
      {
        checked: true,
        locked: false,
        title: 'Date of birth',
        heading: 'dob',
      },
      {
        checked: true,
        locked: false,
        title: 'Patient gender',
        heading: 'gender_value',
      },
      {
        checked: true,
        locked: false,
        title: 'Email',
        heading: 'email',
      },
      {
        checked: true,
        locked: false,
        title: 'Can contact via email',
        heading: 'can_send_email',
      },
      {
        checked: true,
        locked: false,
        title: 'Mobile',
        heading: 'mobile_phone',
      },
      {
        checked: true,
        locked: false,
        title: 'Can contact via SMS',
        heading: 'can_send_sms',
      },
    ],
  },
  {
    title: 'User session',
    rows: [
      {
        checked: true,
        locked: false,
        title: 'Clinic',
        heading: 'clinic',
      },
      {
        checked: true,
        locked: false,
        title: 'Staff member',
        heading: 'clinician',
      },
    ],
  },
  {
    title: 'Last Consultation',
    rows: [
      {
        checked: true,
        locked: true,
        title: 'Date',
        heading: 'consultation_date',
      },
      {
        checked: true,
        locked: false,
        title: 'Consultation type',
        heading: 'purpose',
      },
    ],
  },
  {
    title: 'Treatment',
    rows: [
      {
        checked: true,
        locked: false,
        title: 'Treatment type',
        heading: 'treatment_type',
      },
      {
        checked: true,
        locked: false,
        title: 'Treatment',
        heading: 'treatment',
      },
      {
        checked: true,
        locked: false,
        title: 'Treatment option',
        heading: 'treatment_option',
      },
    ],
  },
  {
    title: 'Next Appointment',
    rows: [
      {
        checked: true,
        locked: true,
        title: 'Date',
        heading: 'appointment_date',
      },
      {
        checked: true,
        locked: false,
        title: 'Treatment type',
        heading: 'appointment_treatment_type',
      },
      {
        checked: true,
        locked: false,
        title: 'Treatment',
        heading: 'appointment_treatment',
      },
    ],
  },
]

export const PaymentType = {
  UNKNOWN: '',
  CASH: 0,
  CARD: 1,
  CHEQUE: 2,
  VOUCHER: 3,
  CREDIT_NOTE: 4,
  INSURANCE: 5,
  REFUND: 6,
  FINANCE: 7,
  DIRECT_DEBIT: 8,
  PAYPAL: 9,
  BANK_TRANSFER: 10,
}

export const paymentTypes = [
  {
    id: PaymentType.UNKNOWN,
    title: 'Please select...',
  },
  {
    id: PaymentType.CASH,
    title: 'Cash',
  },
  {
    id: PaymentType.CARD,
    title: 'Card',
  },
  {
    id: PaymentType.CHEQUE,
    title: 'Cheque',
  },
  {
    id: PaymentType.VOUCHER,
    title: 'Voucher',
  },
  {
    id: PaymentType.CREDIT_NOTE,
    title: 'Credit note',
  },
  {
    id: PaymentType.INSURANCE,
    title: 'Insurance',
  },
  {
    id: PaymentType.REFUND,
    title: 'Refund',
  },
  {
    id: PaymentType.FINANCE,
    title: 'Finance',
  },
  {
    id: PaymentType.DIRECT_DEBIT,
    title: 'Direct Debit',
  },
  {
    id: PaymentType.PAYPAL,
    title: 'Paypal',
  },
  {
    id: PaymentType.BANK_TRANSFER,
    title: 'Bank Transfer',
  },
]

export const CardType = {
  UNKNOWN: '',
  VISA: 0,
  VISA_DEBIT: 1,
  VISA_ELECTRON: 2,
  MASTERCARD: 3,
  AMERICAN_EXPRESS: 4,
}

export const cardTypes = [
  {
    id: CardType.UNKNOWN,
    title: 'Please select...',
  },
  {
    id: CardType.VISA,
    title: 'Visa',
  },
  {
    id: CardType.VISA_DEBIT,
    title: 'Visa debit',
  },
  {
    id: CardType.VISA_ELECTRON,
    title: 'Visa electron',
  },
  {
    id: CardType.MASTERCARD,
    title: 'Mastercard',
  },
  {
    id: CardType.AMERICAN_EXPRESS,
    title: 'American express',
  },
]

export const settingIds = {
  CLINICGROUP: 2,
  SMS: 3,
}

export const clinicSettings = [
  {
    id: settingIds.SMS,
    title: 'SMS Messaging',
    isDisabled: false,
  },
  {
    id: settingIds.CLINICGROUP,
    title: 'Clinic Group Details',
    isDisabled: false,
  },
]

export const InvoiceOption = {
  ACTIONS: 0,
  QUICKBOOKS: 1,
  VIEW_INVOICE: 2,
  LOG_PAYMENT: 3,
  VIEW_PAYMENTS: 4,
  DELETE_INVOICE: 5,
}

export const invoiceOptions = [
  {
    id: InvoiceOption.ACTIONS,
    title: 'Actions',
  },
  {
    id: InvoiceOption.QUICKBOOKS,
    title: 'Open in Quickbooks',
  },
  {
    id: InvoiceOption.VIEW_INVOICE,
    title: 'View invoice',
  },
  {
    id: InvoiceOption.LOG_PAYMENT,
    title: 'Log a payment',
  },
  {
    id: InvoiceOption.VIEW_PAYMENTS,
    title: 'View payments',
  },
  {
    id: InvoiceOption.DELETE_INVOICE,
    title: 'Delete invoice',
  },
]

export const InvoiceStatus = {
  OUTSTANDING: 0,
  FULL_PAID: 1,
  PART_PAID: 2,
  REFUNDED: 3,
}

export const invoiceStatuses = {
  1: 'Paid',
  2: 'Part paid',
  3: 'Refunded',
}

export const testSupportResponse = {
  count: 2,
  next: null,
  previous: null,
  results: [
    {
      id: 1,
      sections: [
        {
          id: 1,
          attachments: [
            {
              id: 1,
              created_at: '2019-12-20T09:15:43Z',
              updated_at: '2019-12-20T09:15:43Z',
              title: 'qweqwe',
              file:
                'https://clever-clinic-dev.s3.amazonaws.com/uploads/support_attachments/f2ff7bdf-82b4-4ad9-97f0-90bfd4ffa5db/Screenshot_2019-12-17_at_14.17.39.png',
              section: 1,
            },
            {
              id: 2,
              created_at: '2019-12-20T09:15:43Z',
              updated_at: '2019-12-20T09:15:43Z',
              title: 'qweqwe',
              file:
                'https://clever-clinic-dev.s3.amazonaws.com/uploads/support_attachments/f2ff7bdf-82b4-4ad9-97f0-90bfd4ffa5db/Screenshot_2019-12-17_at_14.17.39.mov',
              section: 1,
            },
            {
              id: 3,
              created_at: '2019-12-20T09:15:43Z',
              updated_at: '2019-12-20T09:15:43Z',
              title: 'qweqwe',
              file:
                'https://clever-clinic-dev.s3.amazonaws.com/uploads/support_attachments/f2ff7bdf-82b4-4ad9-97f0-90bfd4ffa5db/Screenshot_2019-12-17_at_14.17.39.pdf',
              section: 1,
            },
            {
              id: 4,
              created_at: '2019-12-20T09:15:43Z',
              updated_at: '2019-12-20T09:15:43Z',
              title: 'qweqwe',
              file:
                'https://clever-clinic-dev.s3.amazonaws.com/uploads/support_attachments/f2ff7bdf-82b4-4ad9-97f0-90bfd4ffa5db/Screenshot_2019-12-17_at_14.17.39.pdf',
              section: 1,
            },
            {
              id: 5,
              created_at: '2019-12-20T09:15:43Z',
              updated_at: '2019-12-20T09:15:43Z',
              title: 'qweqwe',
              file:
                'https://clever-clinic-dev.s3.amazonaws.com/uploads/support_attachments/f2ff7bdf-82b4-4ad9-97f0-90bfd4ffa5db/Screenshot_2019-12-17_at_14.17.39.pdf',
              section: 1,
            },
            {
              id: 6,
              created_at: '2019-12-20T09:15:43Z',
              updated_at: '2019-12-20T09:15:43Z',
              title: 'qweqwe',
              file:
                'https://clever-clinic-dev.s3.amazonaws.com/uploads/support_attachments/f2ff7bdf-82b4-4ad9-97f0-90bfd4ffa5db/Screenshot_2019-12-17_at_14.17.39.pdf',
              section: 1,
            },
            {
              id: 7,
              created_at: '2019-12-20T09:15:43Z',
              updated_at: '2019-12-20T09:15:43Z',
              title: 'qweqwe',
              file:
                'https://clever-clinic-dev.s3.amazonaws.com/uploads/support_attachments/f2ff7bdf-82b4-4ad9-97f0-90bfd4ffa5db/Screenshot_2019-12-17_at_14.17.39.pdf',
              section: 1,
            },
            {
              id: 8,
              created_at: '2019-12-20T09:15:43Z',
              updated_at: '2019-12-20T09:15:43Z',
              title: 'qweqwe',
              file:
                'https://clever-clinic-dev.s3.amazonaws.com/uploads/support_attachments/f2ff7bdf-82b4-4ad9-97f0-90bfd4ffa5db/Screenshot_2019-12-17_at_14.17.39.pdf',
              section: 1,
            },
            {
              id: 9,
              created_at: '2019-12-20T09:15:43Z',
              updated_at: '2019-12-20T09:15:43Z',
              title: 'qweqwe',
              file:
                'https://clever-clinic-dev.s3.amazonaws.com/uploads/support_attachments/f2ff7bdf-82b4-4ad9-97f0-90bfd4ffa5db/Screenshot_2019-12-17_at_14.17.39.pdf',
              section: 1,
            },
          ],
          created_at: '2019-12-20T09:15:43Z',
          updated_at: '2020-01-07T13:17:12Z',
          title: 'Section 1',
          is_restricted: true,
          content: 'qweqeqweq\r\nwe\r\nq\r\nwe\r\nqwe\r\n\r\n# asdasdasd',
          section_order: 0,
          page: 1,
        },
        {
          id: 2,
          attachments: [],
          created_at: '2020-01-06T10:59:53Z',
          updated_at: '2020-01-07T13:17:12Z',
          title: 'Section 2',
          is_restricted: true,
          content: 'qweqweqwe',
          section_order: 1,
          page: 1,
        },
      ],
      created_at: '2019-12-20T09:15:43Z',
      updated_at: '2020-01-07T14:20:46Z',
      title: 'My First Page',
      is_restricted: true,
      page_order: 10,
    },
    {
      id: 2,
      sections: [],
      created_at: '2020-01-06T10:33:26Z',
      updated_at: '2020-01-06T13:27:27Z',
      title: 'My Second Page',
      is_restricted: false,
      page_order: 20,
    },
  ],
}

export const invoiceTypes = {
  0: 'Clinic Retail Sale',
  1: 'Purely Cosmetic',
  2: 'Primarily Medical',
}

// Build up telephone extension locals
const preferredCountryCodes = ['GB', 'IE', 'US']

const combinePreferredAndAllLocales = () => {
  let finalCountryCodes = []
  try {
    finalCountryCodes = getCountries()
  } catch (error) {
    console.log(error)
  }

  // Put the preffered country codes at the start of the lookup
  preferredCountryCodes.reverse().forEach(code => {
    finalCountryCodes.splice(finalCountryCodes.indexOf(code), 1)
    finalCountryCodes.unshift(code)
  })

  return finalCountryCodes
}

export const supportedCountryCodes = combinePreferredAndAllLocales()

export const ClinicLocaleTypes = {
  en_GB: 'en_GB',
  en_IE: 'en_IE',
  en_US: 'en_US',
}

export const availableLocaleTypes = [
  ClinicLocaleTypes.en_IE,
  ClinicLocaleTypes.en_GB,
]

export const continentForLocaleType = {
  en_IE: 'Europe',
  en_GB: 'Europe',
  en_US: 'America',
}

export const availableContinents = [
  ...new Set(
    availableLocaleTypes.map(locale => continentForLocaleType[locale]),
  ),
]

export const medicalHistoryAppointmentTypes = {
  PREVIOUS_MEDICAL_HISTORY: 0,
  ALLERGIES_AND_ALERTS: 1,
  COSMETIC_HISTORY: 2,
  DRUG_HISTORY: 3,
  SOCIAL_HISTORY: 4,
  FAMILY_HISTORY: 5,
}

export const noteTypes = {
  MANUAL: 0,
  AUTOMATIC: 1,
}
