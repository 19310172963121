import PropTypes from 'prop-types'

import Avatar from '../../../../../Avatar'
import Button from '../../../../../Buttons'
import TextArea from '../../../../../forms/Textarea'

import Notes from '../Notes'

import {
  getItems,
  getInitials,
  getFullName,
} from '../../../../../../utilities/ReusableFunctions'

import { medicalHistoryAppointmentTypes } from '../../../../../../utilities/ReusableObjects'

export default function Appointment({
  appointmentDetails,
  appointmentAlerts,
  timezone,
  note,
  notes,
  setNote,
  addNote,
}) {
  const {
    clinician,
    purpose,
    room,
    treatment_type,
    treatments,
    patient,
    is_virtual,
  } = appointmentDetails
  const { ALLERGIES_AND_ALERTS } = medicalHistoryAppointmentTypes
  return (
    <>
      <section className="form__group--topStackable form__group--optional">
        <div className="patient_warning--inline">
          {appointmentAlerts.map(({ name, type }) => {
            return (
              <article
                key={name}
                className={`patient_warning_info patient_warning_info--inline ${
                  type === ALLERGIES_AND_ALERTS
                    ? 'patient_warning_info--cosmetic'
                    : ''
                }`}
              >
                <span className="patient_warning_icon icon-warning" />
                <p className="patient_warning_text noMargin">{name}</p>
              </article>
            )
          })}
        </div>
      </section>
      <section className="form__group--topStackable">
        <article className="form__group--halfWidth">
          <div className="form__group--halfWidth__panel form__group--halfWidth__panel--small">
            <label className="form__label" htmlFor="clinician">
              Clinician
            </label>
            <div className="practitioner_detail practitioner_detail_avatar practitioner_detail--disabled-form">
              <Avatar
                type="formImage"
                src={clinician ? clinician.signed_avatar : ''}
                initials={getInitials(clinician)}
              />
              <p className="disabledFormEntry disabledFormEntry--linkSpace">
                {clinician ? (
                  <>
                    {getFullName(clinician)}
                    <span className="text-light">
                      {getItems('job_type')[clinician.job_type]
                        ? ` - ${getItems('job_type')[clinician.job_type].title}`
                        : '-'}
                    </span>
                  </>
                ) : (
                  '-'
                )}
              </p>
            </div>
          </div>
          <div className="form__group--halfWidth__panel form__group--halfWidth__panel--small">
            <label className="form__label" htmlFor="appointmentType">
              Appointment type
            </label>
            <p className="disabledFormEntry">
              {getItems('purpose')[purpose]
                ? getItems('purpose')[purpose].title
                : '-'}
            </p>
          </div>
        </article>
      </section>
      <section className="form__group--stackable">
        <article className="form__group--halfWidth">
          <div className="form__group--halfWidth__panel form__group--halfWidth__panel--small">
            <label className="form__label" htmlFor="room">
              Room
            </label>
            <p className="disabledFormEntry">
              {room ? room.name : is_virtual ? '' : '-'}
              {room && is_virtual && ' - '}
              {is_virtual && 'Virtual'}
            </p>
          </div>
          <div className="form__group--halfWidth__panel form__group--halfWidth__panel--small">
            <label className="form__label" htmlFor="room">
              Treatment type
            </label>
            <p className="disabledFormEntry">
              {treatment_type ? treatment_type.title : '-'}
            </p>
          </div>
        </article>
      </section>
      <section className="form__group--stackable">
        <label className="form__label" htmlFor="treatments">
          Treatment(s)
        </label>
        <p className="disabledFormEntry">
          {treatments && treatments.length
            ? treatments.map(treatment => treatment.title).join(', ')
            : '-'}
        </p>
      </section>
      <hr className="legacy" />
      <section className="form__group--topStackable">
        {patient.patient_notes && patient.patient_notes.length > 0 ? (
          <div
            style={{
              backgroundColor: '#F0F8FD',
              borderRadius: '8px',
              padding: '24px',
              marginBottom: '24px',
            }}
          >
            <label className="form__label" htmlFor="room">
              Patient Notes
            </label>
            <p className="disabledFormEntry" style={{ marginBottom: '0' }}>
              {patient.patient_notes}
            </p>
          </div>
        ) : (
          ''
        )}
        <label className="form__label" htmlFor="notes">
          Appointment Notes
        </label>
        <Notes notes={notes} timezone={timezone} />
        <fieldset className="form__group--modal-last">
          <TextArea
            label="New note"
            placeholder="What would you like to post?"
            value={note}
            onChange={({ target }) => setNote(target.value)}
          />
          <Button
            styleType="secondary"
            size="small"
            label="Post"
            isDisabled={!note.length}
            clickHandler={() => addNote(note)}
          />
        </fieldset>
      </section>
    </>
  )
}

Appointment.defaultProps = {
  notes: [],
}

Appointment.propTypes = {
  appointmentDetails: PropTypes.object.isRequired,
  appointmentAlerts: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  notes: PropTypes.array,
  setNote: PropTypes.func.isRequired,
  addNote: PropTypes.func.isRequired,
}
