import PropTypes from 'prop-types'

import { getAppointmentStatusForModal } from '../../../../../../../utilities/ReusableFunctions'
import { appointmentStatusIds } from '../../../../../../../utilities/ReusableObjects'

export default function AppointmentStatus({ status }) {
  const { NO_SHOW, CANCELLED, PENDING } = appointmentStatusIds
  const alertClass =
    status === NO_SHOW || status === CANCELLED
      ? 'modal__notification--alert'
      : 'modal__notification--notify'
  const animation = status === PENDING ? '' : 'slideDownAnimate'

  const statusModal = Object.keys(appointmentStatusIds).find(statusID => {
    return appointmentStatusIds[statusID] === status
  })

  return (
    <section className="modal__notification_container modal__item--flex-fixed">
      <article className={`modal__notification ${alertClass} ${animation}`}>
        <p className="modal__notification--message noMargin">
          {getAppointmentStatusForModal(statusModal)}
        </p>
      </article>
    </section>
  )
}

AppointmentStatus.propTypes = {
  status: PropTypes.number.isRequired,
}
