import PropTypes from 'prop-types'

const Slot = ({ slot, activeSlot, setActiveSlot }) => (
  <li
    className="radioButton radioButton--fullWidth"
    onClick={() => setActiveSlot(slot)}
    onKeyDown={() => setActiveSlot(slot)}
    role="menuitem"
  >
    <input
      className="form__radio"
      name="appointmentSlot"
      type="radio"
      checked={slot.id === activeSlot.id}
      id={slot.id}
    />
    <label
      className={`roundedToggleButton ${
        slot.id === activeSlot.id
          ? 'roundedToggleButton--selected'
          : 'roundedToggleButton--stacked'
      }`}
    >
      {slot.slot}
    </label>
  </li>
)

Slot.defaultProps = {
  activeSlot: {},
}

Slot.propTypes = {
  activeSlot: PropTypes.object,
  slot: PropTypes.object.isRequired,
  setActiveSlot: PropTypes.func.isRequired,
}

export default Slot
