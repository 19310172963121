import { useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

import ReactPlayer from 'react-player'
import SupportIcon from '../../../assets/support_cross_icon.svg?react'

export default function SupportModal({
  attachment,
  toggleModal,
  resetAttachment,
}) {
  const attachmentRef = useRef(null)
  const iconRef = useRef(null)
  const [width, setWidth] = useState(null)

  setTimeout(() => {
    setWidth(
      attachment.type === 'Image'
        ? attachmentRef.current.width
        : attachment.type === 'Video'
        ? attachmentRef.current.props.width
        : null,
    )
    iconRef.current.style.display = 'block'
  }, 60)

  const renderMedia = ({ signed_file, type }) =>
    ({
      Image: (
        <img
          className="support--modal-attachment"
          src={signed_file}
          alt={signed_file}
          ref={attachmentRef}
        />
      ),
      Video: (
        <ReactPlayer
          className="support--modal-attachment"
          playing
          controls
          url={signed_file}
          ref={attachmentRef}
        />
      ),
    }[type])

  return createPortal(
    <div className="fadeInFlyAnimate support--modal-overlay">
      <section className="support--modal-container" style={{ width: width }}>
        <SupportIcon
          className="support--modal-icon"
          onClick={() => {
            toggleModal(false)
            resetAttachment({})
          }}
          ref={iconRef}
        />
        {renderMedia(attachment)}
      </section>
    </div>,
    document.getElementById('root-modal'),
  )
}

SupportModal.propTypes = {
  attachment: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  resetAttachment: PropTypes.func.isRequired,
}
