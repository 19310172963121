import React from 'react'
import PropTypes from 'prop-types'
import MainButton from '../../Buttons'
import UploadDocumentButton from '../../Library/DocumentUpload/UploadDocumentButton'
import * as func from '../../../utilities/ReusableFunctions'
import { appointmentPurposeIds } from '../../../utilities/ReusableObjects'
import moment from 'moment'

class PatientInteractionCard extends React.Component {
  constructor(props) {
    super(props)
    const { idRef } = this.props
    this[idRef] = React.createRef()
    this.state = {
      hoverVisible: false,
    }
    this.getInteraction = this.getInteraction.bind(this)
    this.onFocusIn = this.onFocusIn.bind(this)
    this.onFocusOut = this.onFocusOut.bind(this)
    this.onMouseOver = this.onMouseOver.bind(this)
    this.onMouseOut = this.onMouseOut.bind(this)
    this.getAppointmentInteraction = this.getAppointmentInteraction.bind(this)
    this.getTreatmentListItems = this.getTreatmentListItems.bind(this)
    this.getConcernsListItems = this.getConcernsListItems.bind(this)
    this.getCorrespondenceInteraction = this.getCorrespondenceInteraction.bind(
      this,
    )
    this.openMedicalRecord = this.openMedicalRecord.bind(this)
    this.openPrescription = this.openPrescription.bind(this)
    this.bookFollowUpAppointment = this.bookFollowUpAppointment.bind(this)
  }

  getInteraction() {
    const { type, interactionData } = this.props
    let interaction = []
    interaction.push(
      type === 'correspondence'
        ? this.getCorrespondenceInteraction(interactionData)
        : this.getAppointmentInteraction(interactionData),
    )
    return interaction
  }

  onFocusIn() {
    this.setState(() => ({
      hoverVisible: true,
    }))
  }

  onFocusOut(event) {
    const { idRef } = this.props
    let target = event.relatedTarget
    if (target === null) {
      target = document.activeElement
    }
    if (document.getElementById(idRef).contains(target)) {
      return
    }
    this.setState(() => ({ //eslint-disable-line
      hoverVisible: false,
    }))
  }

  onMouseOver(event) { //eslint-disable-line
    this.setState((prevState, props) => ({ //eslint-disable-line
      hoverVisible: true,
    }))
  }

  onMouseOut(event) { //eslint-disable-line
    this.setState((prevState, props) => ({ //eslint-disable-line
      hoverVisible: false,
    }))
  }

  getAppointmentInteraction(appointmentData) {
    const { consultIndex, view, onClick, patient, idRef, type } = this.props
    const appDate = moment.tz(appointmentData.start_date, 'UTC')
    const consultation = appointmentData.consultations[consultIndex] || {}
    let prescriptionDocument = null
    let medicalRecordDocument = null
    if (appointmentData.consultations && consultation) {
      if (
        consultation.wizard_document &&
        consultation.wizard_document.signed_file
      ) {
        medicalRecordDocument = consultation.wizard_document.signed_file
      }
      if (
        consultation.prescription_document &&
        consultation.prescription_document.signed_file
      ) {
        prescriptionDocument = consultation.prescription_document.signed_file
      }
    }

    let tabs =
      view === 'consultation'
        ? {
            tabIndex: 0,
            onFocus: event => {
              this.onFocusIn(event)
            },
            onBlur: event => {
              this.onFocusOut(event)
            },
          }
        : type === 'appointmentPast' || type === 'appointmentFuture'
        ? {
            tabIndex: 0,
            onClick: event => {
              onClick(event)
            },
          }
        : {}
    let appointment = []

    let concerns = []

    if (view === 'consultation') {
      switch (consultation.purpose) {
        case 2:
          concerns = consultation.reviews.flatMap(review => {
            return review.treatment_plan.examination_concerns
          })
          break
        default:
          concerns = consultation.examination_concerns
      }

      if (
        concerns.flatMap(({ concerned_areas }) => {
          return concerned_areas
        }).length === 0
      ) {
        return appointment
      }
    }

    appointment.push(
      <div
        id={idRef}
        className="interaction interaction--hover"
        key={idRef}
        {...tabs}
      >
        {view === 'consultation' ? (
          <div
            className={`interaction_hover ${
              this.state.hoverVisible === true
                ? 'interaction_hover--visible'
                : ''
            }`}
          >
            <div className="interaction_hover_buttons">
              <MainButton
                type="button"
                label="Medical record"
                styleType="primary"
                size="small"
                customClasses="interaction_hover_button"
                onClick={() => {
                  this.openMedicalRecord(medicalRecordDocument)
                }}
                onBlur={this.onFocusOut}
              />
              {prescriptionDocument ? (
                <MainButton
                  type="button"
                  label="Prescription"
                  styleType="primary"
                  size="small"
                  customClasses="interaction_hover_button"
                  onClick={() => {
                    this.openPrescription(prescriptionDocument)
                  }}
                  onBlur={this.onFocusOut}
                />
              ) : (
                ''
              )}
              <MainButton
                type="button"
                label="Book review"
                styleType="primary"
                size="small"
                customClasses="interaction_hover_button"
                onClick={() => {
                  this.bookFollowUpAppointment(appointmentPurposeIds.REVIEW)
                }}
                onBlur={this.onFocusOut}
              />
              <MainButton
                type="button"
                label="Book repeat"
                styleType="primary"
                size="small"
                customClasses="interaction_hover_button"
                onClick={() => {
                  this.bookFollowUpAppointment(appointmentPurposeIds.REPEAT)
                }}
                onBlur={this.onFocusOut}
              />
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="interaction_header">
          <div className="interaction_item interaction_header_date flex--shrink">
            {appDate.format('DD.MM.YY')}
          </div>

          <div className="interaction_item interaction_header_patient searchResult__field--secondary flex--shrink">
            {func.getFullName(patient) /* eslint-disable-line */}
          </div>
          <div className="interaction_item interaction_header_clinic searchResult__field--secondary flex--shrink">
            {appointmentData.clinic.name}
          </div>
          <div className="interaction_item interaction_header_purpose">
            {view === 'consultation'
              ? func.getItemValue(consultation, 'purpose')
              : func.getItemValue(appointmentData, 'purpose')}
          </div>
          <div className="interaction_item interaction_header_time searchResult__field--secondary flex--shrink">
            {appDate.format('HH:mm')}
          </div>
          <div className="interaction_item interaction_header_clinician searchResult__field--secondary flex--noshrink">
            {func.getFullName(appointmentData.clinician)}
          </div>

          <div className="interaction_item interaction_header_status searchResult__field--secondary flex--shrink">
            <div
              className={`notifyStatus notifyStatus--${
                func.getAppointmentStatus(appointmentData.status).style
              }`}
            >
              {func.getAppointmentStatus(appointmentData.status).type}
            </div>
          </div>
        </div>
        <div className="interaction_content">
          {view === 'consultation'
            ? this.getConcernsListItems(concerns)
            : this.getTreatmentListItems(appointmentData.treatments)}
        </div>
      </div>,
    )
    return appointment
  }

  getTreatmentListItems(treatments) {
    const { searchValue } = this.props
    let items = treatments.map((treatment, index) => {
      return (
        <div
          key={`treatment_${index}`} //eslint-disable-line
          className={`interaction_content_item ${
            index % 2 ? 'interaction_content_item--odd' : ''
          }`}
        >
          <div className="interaction_content_row">
            <span className="interaction_item bold">
              {func.highlightSearchResult(treatment.title, searchValue)}
            </span>
          </div>
        </div>
      )
    })
    return items
  }

  getConcernsListItems(concerns) {
    let { searchValue } = this.props

    return concerns
      .flatMap(examination_concern => {
        return examination_concern.concerned_areas.flatMap(area => {
          if (examination_concern.treatments.length === 0) {
            return {
              treatment: '-',
              options: '-',
              examination_concern: examination_concern,
              area: area,
            }
          } else {
            return examination_concern.treatments.map(
              ({ treatment, treatment_options }) => {
                return {
                  treatment: treatment.title,
                  options: treatment_options
                    .map(({ title }) => {
                      return title
                    })
                    .join(', '),
                  examination_concern: examination_concern,
                  area: area,
                }
              },
            )
          }
        })
      })
      .map(({ treatment, options, examination_concern, area }, index) => {
        let title = `${treatment} / ${options}`

        return (
        <React.Fragment /* eslint-disable-line */ key={`concern_${index}`}>
            <div
              className={`interaction_content_item ${
                index % 2 ? 'interaction_content_item--odd' : ''
              }`}
            >
              <div className="interaction_content_row">
                <span className="interaction_item interaction_item_treatment bold">
                  {func.highlightSearchResult(title, searchValue)}
                </span>
                <span className="interaction_item interaction_item_concern searchResult__field--secondary">
                  {func.highlightSearchResult(
                    examination_concern.concern.title,
                    searchValue,
                  )}
                </span>
                <span className="interaction_item interaction_item_status searchResult__field--secondary">
                  {func.getItemValue(examination_concern, 'status')}
                </span>
              </div>
              <div className="interaction_content_row">
                <span className="interaction_item searchResult__field--secondary">
                  {area.title}
                </span>
              </div>
            </div>
          </React.Fragment>
        )
      })
  }

  getCorrespondenceInteraction(correspondenceData) {
    let correspondence = []
    const { idRef, patient, searchValue } = this.props
    const corrDate = moment(correspondenceData.created_at)
    correspondence.push(
      <div className="interaction" key={idRef}>
        <div className="interaction_header">
          <div className="interaction_item interaction_header_date flex--shrink">
            {corrDate.format('DD.MM.YY')}
          </div>
          <div className="interaction_item interaction_header_patient searchResult__field--secondary flex--shrink">
            {func.getFullName(patient) /* eslint-disable-line */}
          </div>
          <div className="interaction_item interaction_header_clinic searchResult__field--secondary flex--shrink">
            {correspondenceData.created_by &&
            correspondenceData.created_by.primary_clinic
              ? correspondenceData.created_by.primary_clinic.name
              : ''}
          </div>
          <div className="interaction_item interaction_header_time searchResult__field--secondary flex--shrink">
            {corrDate.format('HH:mm')}
          </div>
          <div className="interaction_item interaction_header_clinician searchResult__field--secondary flex--shrink">
            {correspondenceData.created_by
              ? func.getFullName(correspondenceData.created_by)
              : ''}
          </div>
        </div>
        <div className="interaction_title">
          <p className="bold noMargin">
            {func.highlightSearchResult(correspondenceData.title, searchValue)}
          </p>
        </div>
        {correspondenceData.information &&
        correspondenceData.information.trim().length > 0 ? (
          <div className="interaction_title">
            <p className="regular text-light noMargin">
              {func.highlightSearchResult(
                correspondenceData.information,
                searchValue,
              )}
            </p>
          </div>
        ) : (
          ''
        )}
        <div
          className={`interaction_content ${
            correspondenceData.documents &&
            correspondenceData.documents.length > 0
              ? 'interaction_content--buttons'
              : ''
          }`}
        >
          {correspondenceData.documents &&
          correspondenceData.documents.length > 0
            ? correspondenceData.documents.map(document => {
                return (
                  <UploadDocumentButton
                    key={document.id}
                    id={document.id}
                    title={`${func.file(document)} ${func.file_size(document)}`}
                    type={func.getCorrespondenceType(document)}
                    canDelete={false}
                    onOpen={() => {
                      window.open(document.signed_file, '_blank')
                    }}
                    size="auto"
                    customClasses="interaction_content_button"
                  />
                )
              })
            : ''}
        </div>
      </div>,
    )
    return correspondence
  }

  openMedicalRecord(medicalRecordDocument) {
    if (medicalRecordDocument) {
      window.open(medicalRecordDocument, '_blank')
    }
  }

  openPrescription(prescriptionDocument) {
    if (prescriptionDocument) {
      window.open(prescriptionDocument, '_blank')
    }
  }

  formatAppointmentDetails({
    clinician,
    purpose,
    treatment_type,
    treatments,
  }) {
    return {
      clinician,
      purpose,
      treatment_type,
      treatments,
    }
  }

  bookFollowUpAppointment(purpose) {
    const { bookAppointment, interactionData } = this.props

    bookAppointment(
      this.formatAppointmentDetails({ ...interactionData, purpose: purpose }),
    )
  }

  componentDidMount() {
    const { idRef } = this.props
    let idRefVal = idRef
    let object = this[idRefVal]

    if (object) {
      object.current.addEventListener('mouseover', this.onMouseOver)
      object.current.addEventListener('mouseout', this.onMouseOut)
    }
  }

  componentWillUnmount() {
    const { idRef } = this.props
    let idRefVal = idRef
    let object = this[idRefVal]

    if (object) {
      object.current.removeEventListener('mouseover', this.onMouseOver)
      object.current.removeEventListener('mouseout', this.onMouseOut)
    }
  }

  render() {
    const { idRef } = this.props
    let idRefVal = idRef
    return (
      <React.Fragment>
        <article
          ref={this[idRefVal]}
          className="generalInfo__card interaction__card"
        >
          {this.getInteraction()}
        </article>
      </React.Fragment>
    )
  }
}

PatientInteractionCard.defaultProps = {
  idRef: null,
  type: 'appointmentFuture',
  view: 'treatment',
  consultIndex: 0,
  interactionData: null,
  searchValue: '',
  bookAppointment: () => {},
  onClick: () => {},
}

PatientInteractionCard.propTypes = {
  idRef: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf([
    'appointmentPast',
    'appointmentFuture',
    'correspondence',
  ]),
  view: PropTypes.oneOf(['consultation', 'treatment', 'correspondence']),
  consultIndex: PropTypes.number,
  interactionData: PropTypes.object,
  searchValue: PropTypes.string,
  bookAppointment: PropTypes.func,
  onClick: PropTypes.func,
  patient: PropTypes.object.isRequired,
}

export default PatientInteractionCard
