import PropTypes from 'prop-types'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import IdleTimer from 'react-idle-timer'
import * as auth from './services/OnBoarding/auth'
import RemoteAuthRedirect from './components/RemoteAuthRedirect'
import ClinicsPage from './containers/ClinicSetup/ClinicsPage'

import { A_ClearToken } from './actions'

import { REDIRECT_TO_V2_APP } from './api_urls'
import { Fragment } from 'react'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const forceLogout = () => {
    localStorage.clear()
    sessionStorage.clear()
    window.location.reload()
  }

  return (
    <Route
      {...rest}
      render={props =>
        auth.isLoggedIn() ? (
          <Fragment>
            <IdleTimer onIdle={forceLogout} timeout={1000 * 60 * 60} />
            <Component {...props} />
          </Fragment>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}

PrivateRoute.defaultProps = {
  location: {},
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object,
}

export default function Routes() {
  const location = useLocation()

  if (location.pathname === '/' && window.location.hostname !== 'localhost') {
    window.location.replace(REDIRECT_TO_V2_APP)
  }

  return (
    <Switch>
      <Route exact path="/remoteAuthRedirect" component={RemoteAuthRedirect} />
      <Route
        path="/clinics/invite/accept"
        render={() => {
          localStorage.clear()
          A_ClearToken()
          return <Redirect to="/" />
        }}
      />
      <PrivateRoute path="/clinics" component={ClinicsPage} />
    </Switch>
  )
}
