import * as appointment from '../services/ClinicAppointments/ClinicAppointmentService'
import * as booking from '../services/ClinicAppointments/ViewAppointmentDetailService'
import * as clinic from '../services/ClinicAppointments/Clinic_Setup/ClinicDashboard_01Service'
import types from './types'
import debounce from 'lodash.debounce'

export const A_FetchClinicTreatmentList = clinicID => dispatch =>
  appointment
    .fetchClinicTreatmentList(clinicID)
    .then(response => {
      dispatch({ type: types.FETCH_TREATMENT_LIST.OK, list: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.FETCH_TREATMENT_LIST.FAIL, error })
      return Promise.reject(error)
    })

export const A_FetchConsultationClinicTreatmentList = (
  clinicID,
  treatmentTypeId,
) => dispatch =>
  appointment
    .fetchConsultationClinicTreatmentList(clinicID, treatmentTypeId)
    .then(response => {
      dispatch({
        type: types.FETCH_CONSULTATION_TREATMENT_LIST.OK,
        list: response,
      })
      return response
    })
    .catch(error => {
      dispatch({ type: types.FETCH_CONSULTATION_TREATMENT_LIST.FAIL, error })
      return Promise.reject(error)
    })

export const A_ClearConsultationClinicTreatmentList = () => dispatch =>
  dispatch({ type: types.FETCH_CONSULTATION_TREATMENT_LIST.CLEAR })

export const A_FetchTreatmentTypeList = (
  clinic,
  consultationType,
) => dispatch =>
  appointment
    .fetchTreatmentTypeList(clinic, consultationType)
    .then(response => {
      dispatch({ type: types.FETCH_TREATMENT_TYPE_LIST.OK, list: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.FETCH_TREATMENT_TYPE_LIST.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetClinicAppointmentDetail = id => dispatch =>
  appointment
    .getClinicAppointmentDetail(id)
    .then(response => {
      dispatch({ type: types.GET_CLINIC_APPOINTMENT_DETAIL.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_CLINIC_APPOINTMENT_DETAIL.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetClinicPatientsList = id => dispatch =>
  appointment
    .getClinicPatientsList(id)
    .then(response => {
      dispatch({ type: types.GET_CLINIC_PATIENTS_LIST.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_CLINIC_PATIENTS_LIST.FAIL, error })
      return Promise.reject(error)
    })

const debounceSearchPatients = debounce(
  (dispatch, data) =>
    appointment
      .getFilteredPatientsList(data)
      .then(response => {
        dispatch({ type: types.GET_FILTERED_PATIENTS_LIST.OK, data: response })
      })
      .catch(error => {
        dispatch({ type: types.GET_FILTERED_PATIENTS_LIST.FAIL, error })
      }),
  300,
)

export const A_GetFilteredPatientsList = data => dispatch => {
  dispatch({ type: types.GET_FILTERED_PATIENTS_LIST.REQ, data })
  debounceSearchPatients(dispatch, data)
}

export const A_ClearFilteredPatientList = () => dispatch =>
  dispatch({ type: types.GET_FILTERED_PATIENTS_LIST.CLEAR })

export const A_StoreInitialStateValues = state => ({
  type: types.STORE_INITIAL_STATE_VALUE,
  state,
})

export const A_GetBasicDetails = id => dispatch =>
  clinic
    .getclinicDetails(id)
    .then(response => {
      dispatch({ type: types.GET_BASIC_DETAILS.OK, data: response })
      dispatch({ type: types.VIEWING_CLINIC, clinic: response.id })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_BASIC_DETAILS.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetAvaliableSlotList = (
  data,
  ignoreOpeningHours,
) => dispatch => {
  dispatch({
    type: types.STORE_SLOT_LENGTH,
    minutes: data.appointmentLength,
  })

  return appointment
    .getAvaliableSlotList(data, ignoreOpeningHours)
    .then(response => {
      dispatch({
        type: types.GET_AVALIABLE_SLOTS_LIST.OK,
        list: response,
        timezone: data.timezone,
        minutes: data.appointmentLength,
      })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_AVALIABLE_SLOTS_LIST.FAIL, error })
      return Promise.reject(error)
    })
}

export const A_ClearAvailableSlotList = () => dispatch =>
  dispatch({ type: types.GET_AVALIABLE_SLOTS_LIST.CLEAR })


export const A_FetchBookedAppointmentDetails = appointmentID => dispatch =>
  booking
    .fetchBookedAppointmentDetails(appointmentID)
    .then(response => {
      dispatch({
        type: types.FETCH_BOOKED_APPOINTMENT_DETAILS.OK,
        data: response,
      })
      return response
    })
    .catch(error => {
      dispatch({ type: types.FETCH_BOOKED_APPOINTMENT_DETAILS.FAIL, error })
      return error
    })

export const A_ClearBookedAppointmentDetails = () => dispatch =>
  dispatch({ type: types.FETCH_BOOKED_APPOINTMENT_DETAILS.CLEAR })

export const A_PostNewClinicNotes = appointmentNotes => dispatch =>
  booking
    .postNewClinicNotes(appointmentNotes)
    .then(response => {
      dispatch({ type: types.POST_NEW_CLINIC_NOTES.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.POST_NEW_CLINIC_NOTES.FAIL, error })
      return Promise.reject(error)
    })

export const A_UpdateBookedAppointment = appointmentData => dispatch =>
  booking
    .updateBookedAppointment(appointmentData)
    .then(response => {
      dispatch({ type: types.UPDATE_BOOKED_APPOINTMENT.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.UPDATE_BOOKED_APPOINTMENT.FAIL, error })
      return Promise.reject(error)
    })
