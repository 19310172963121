import React from 'react'
import PropTypes from 'prop-types'
import {
  errorSpan,
  requiredSpan,
  listError,
} from '../../../containers/OnBoarding/FormErrorValidations'

import { supportedCountryCodes } from '../../../utilities/ReusableObjects'
import { phoneExtentionChoices } from '../../../utilities/ReusableFunctions'

import { getCountryCallingCode, parseNumber } from 'libphonenumber-js'

class PhoneNumberInput extends React.Component {
  constructor(props) {
    super(props)

    let index = supportedCountryCodes[0]
    let value = ''

    if (this.props.value !== null && this.props.value !== '') {
      const parsedNumber = parseNumber(this.props.value)

      if (parsedNumber.country) {
        index = parsedNumber.country
      }

      value = parsedNumber.phone !== undefined ? parsedNumber.phone : ''
    }

    this.state = {
      value: value,
      extension: index,
    }
  }

  formatForReturning = (number, code) => {
    if (number === undefined) return ''

    try {
      return `+${getCountryCallingCode(code)}${number.replace(/^0/, '')}`
    } catch (error) {
      return ''
    }
  }

  onChangedNumber = (value, extension) => {
    if (this.props.onChange) {
      if (value === '' || value === undefined) {
        this.props.onChange('')
      } else {
        const formatted = this.formatForReturning(value, extension)
        this.props.onChange(formatted)
      }
    }
  }

  onChangeText = value => {
    this.onChangedNumber(value, this.state.extension)
    this.setState({ value: value })
  }

  onChangeSelect = extension => {
    this.onChangedNumber(this.state.value, extension)
    this.setState({ extension: extension })
  }

  renderInner = () => {
    const { id, label, error, isLabelInline, isRequired, ...props } = this.props

    const labelEl =
      label.length > 0 ? (
        <label
          className={`form__label ${
            isLabelInline ? 'form__label--inline' : ''
          }`}
          htmlFor={id}
        >
          {error && error.length > 0
            ? error === 'Error'
              ? errorSpan()
              : error === 'Required'
              ? requiredSpan()
              : error === 'List'
              ? listError()
              : ''
            : ''}
          {label}
          {!isRequired ? (
            <span className="form__label--optional"> - optional</span>
          ) : (
            ''
          )}
        </label>
      ) : (
        ''
      )
    const errorClass =
      this.props.error && this.props.error.length > 0
        ? 'form__textField--error'
        : ''

    const errorChevronClass =
      this.props.error && this.props.error.length > 0
        ? 'form__select--error'
        : ''

    return (
      <React.Fragment>
        {labelEl}
        <div className="phonenumber_wrapper">
          <span className="form__selectWrapper">
            <select
              {...props}
              className={`form__textField form__selectField phoneCountrySelector ${
                this.props.isInline ? 'form__selectField--inline' : ''
              }
                ${
                  this.props.isSmallMargin
                    ? 'form__selectField--smallMargin'
                    : ''
                } ${this.props.hasImage ? 'form__textField--hasImage' : ''}
                ${errorClass} ${
                this.props.isColumnCell ? 'patientImport__row' : ''
              }
                ${this.props.hasTopMargin ? 'form__select--labeless' : ''} ${
                this.props.customClasses
              }`}
              id={this.props.id}
              name={this.props.name}
              value={this.state.extension}
              onChange={({ target: { value } }) => {
                this.onChangeSelect(value)
              }}
              onBlur={({ target: { value } }) => {
                this.onChangeSelect(value)
              }}
            >
              {this.props.options.map(option => {
                return (
                  <option
                    disabled={option.isDisabled}
                    value={option.code}
                    key={option.title}
                  >
                    {option.title}
                  </option>
                )
              })}
            </select>
            <span
              className={`icon-chevron-down form__select ${errorChevronClass} ${
                this.props.label.length > 0 ? '' : 'form__select--labeless'
              }  ${this.props.isLabelInline ? 'form__select--labeless' : ''}`}
            />
          </span>
          <input
            className={`form__textField form__phoneField ${errorClass} ${this.props.customClasses}`}
            type="text"
            defaultValue={this.state.value}
            placeholder={this.props.placeholder}
            onChange={({ target: { value } }) => {
              this.onChangeText(value)
            }}
          />
        </div>
      </React.Fragment>
    )
  }

  renderErrorCard = () => {
    return (
      <React.Fragment>
        <section className="tooltipWrapper">
          <aside className={`tooltip tooltip--error ${this.tooltipClasses}`}>
            <section className="tooltip__tip icon-alert">
              <span className="tooltip__tipText">
                {this.props.errorMessage}
              </span>
            </section>
            <span className="tooltip__pointerStretch">
              <span className="tooltip__pointer tooltip--error__pointer" />
            </span>
          </aside>
        </section>
      </React.Fragment>
    )
  }

  render = () => {
    return (
      <React.Fragment>
        {this.props.hasOwnWrapper ? (
          this.renderInner()
        ) : (
          <section className="createAppointment--right">
            {this.renderInner()}
          </section>
        )}
        {this.props.error === 'card' ? this.renderErrorCard() : ''}
      </React.Fragment>
    )
  }
}

PhoneNumberInput.defaultProps = {
  id: '',
  label: '',
  error: '',
  placeholder: '',
  customClasses: '',
  options: phoneExtentionChoices(),
  isInline: false,
  isLabelInline: false,
  hasTopMargin: false,
  hasImage: false,
  isSmallMargin: false,
  isColumnCell: false,
  hasOwnWrapper: false,
  errorMessage: 'Required',
  tooltipClasses: 'tooltip--patientImport',
  name: '',
  value: '',
  key: null,
  onChange: null,
  isRequired: true,
}

PhoneNumberInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  customClasses: PropTypes.string,
  options: PropTypes.array,
  isInline: PropTypes.bool,
  isLabelInline: PropTypes.bool,
  hasTopMargin: PropTypes.bool,
  hasImage: PropTypes.bool,
  isSmallMargin: PropTypes.bool,
  isColumnCell: PropTypes.bool,
  hasOwnWrapper: PropTypes.bool,
  errorMessage: PropTypes.string,
  tooltipClasses: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  key: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
}

export default PhoneNumberInput
