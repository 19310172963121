import React from 'react'
import PropTypes from 'prop-types'
import {
  errorSpan,
  requiredSpan,
  listError,
} from '../../../containers/OnBoarding/FormErrorValidations'

const TextAreaInput = ({
  id,
  label,
  rows,
  name,
  placeholder,
  error,
  noMargin,
  fullHeight,
  isRequired,
  errorMessage,
  showAsterisk,
  tooltipClasses,
  ...props
}) => {
  const labelEl =
    label.length > 0 ? (
      <label className="form__label" htmlFor={id}>
        {error && error.length > 0
          ? error === 'Error'
            ? errorSpan()
            : error === 'Required'
            ? requiredSpan()
            : error === 'List'
            ? listError()
            : ''
          : ''}
        {label}{' '}
        {!isRequired && !showAsterisk ? (
          <span className="form__label--optional"> - optional</span>
        ) : isRequired && showAsterisk ? (
          <span className="patientImport__red">*</span>
        ) : (
          ''
        )}
      </label>
    ) : (
      ''
    )

  const renderErrorCard = () => {
    return (
      <React.Fragment>
        <section className="tooltipWrapper">
          <aside className={`tooltip tooltip--error ${tooltipClasses}`}>
            <section className="tooltip__tip icon-alert">
              <span className="tooltip__tipText">{errorMessage}</span>
            </section>
            <span className="tooltip__pointerStretch">
              <span className="tooltip__pointer tooltip--error__pointer" />
            </span>
          </aside>
        </section>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {labelEl}
      <textarea
        className={`form__textField ${noMargin ? 'noMargin' : ''} ${
          fullHeight ? 'form__textArea--fullHeight' : ''
        } ${error && error.length > 0 ? 'form__textField--error' : ''}`}
        id={id}
        rows={rows}
        placeholder={placeholder}
        name={name}
        {...props}
      />
      {error === 'card' ? renderErrorCard() : ''}
    </React.Fragment>
  )
}

TextAreaInput.defaultProps = {
  id: '',
  name: '',
  label: '',
  rows: '4',
  placeholder: '',
  error: '',
  noMargin: false,
  fullHeight: false,
  isRequired: true,
  showAsterisk: false,
  errorMessage: '',
  tooltipClasses: '',
}

TextAreaInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  rows: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  noMargin: PropTypes.bool,
  fullHeight: PropTypes.bool,
  isRequired: PropTypes.bool,
  showAsterisk: PropTypes.bool,
  errorMessage: PropTypes.string,
  tooltipClasses: PropTypes.string,
}

export default TextAreaInput
