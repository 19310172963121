import { types } from "./types";

export const openAppointmentModal = (appointment) => {
  return {
    type: types.OPEN_APPOINTMENT_MODAL,
    payload: appointment,
  };
};

export const closeAppointmentModal = () => {
  return {
    type: types.CLOSE_APPOINTMENT_MODAL,
  };
};

export const createdAppointment = (appointment) => {
  return {
    type: types.CREATED_APPOINTMENT,
    payload: appointment,
  };
};

export const updatedAppointment = (appointment) => {
  return {
    type: types.UPDATED_APPOINTMENT,
    payload: appointment,
  };
};

export const deletedAppointment = (appointment) => {
  return {
    type: types.DELETED_APPOINTMENT,
    payload: appointment,
  };
};

export const handleDateChange = (date) => {
  return {
    type: types.DATE_CHANGE,
    payload: date,
  };
};
